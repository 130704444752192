import React from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setFeed, setFeedType } from "../../../redux/feed/feed.actions"
import moment from "moment"

import { FaEdit } from "react-icons/fa"

import { ACCOUNT_PLANS, MAIN_ROUTES } from "../../../constants/routes"
import CountAssetsInFilter from "../../commons/CountAssetsInFilter/CountAssetsInFilter"
import Icon from "../../commons/Icon"
import { BulkEditReset } from "../../../redux/bulk-edit/bulkEdit.actions"
import { clearCampaign } from "../../../redux/campaign/campaign.actions"
import {
  clearFilter,
  setActiveFilter,
  setFilterParameters,
  setShowSaveFilter,
} from "../../../redux/filter/filter.actions"
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip"
import AuthBasedComponent from "../../authentication/AuthBasedComponent"

const FilterRow = ({ filter }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSelectFilter = () => {
    dispatch(BulkEditReset())
    dispatch(clearCampaign())
    dispatch(setFeed(null))
    dispatch(setFeedType(null))
    dispatch(clearFilter())
    dispatch(setActiveFilter(filter))
    dispatch(setFilterParameters(filter?.filterParameters))

    history.push(MAIN_ROUTES.content.path)
  }

  return (
    <tr className="icon-btn" onClick={() => handleSelectFilter()}>
      <td className="align-middle" style={{ maxWidth: "300px" }}>
        <ReelerTooltip text={filter?.name}>
          <div className="text-truncate">{filter?.name}</div>
        </ReelerTooltip>
      </td>
      <td className="align-middle">
        <ReelerTooltip text={filter?.description}>
          <div className="text-truncate">{filter?.description}</div>
        </ReelerTooltip>
      </td>

      <td className="text-center">
        <div className="d-flex flex-row justify-content-end algign-items-center">
          <span
            className="d-flex justify-content-center align-items-center mr-2"
            style={{ width: "30px" }}
          >
            <CountAssetsInFilter filter={filter?.filterParameters} />
          </span>
          <AuthBasedComponent
            plans={[
              ACCOUNT_PLANS.basic,
              ACCOUNT_PLANS.pro,
              ACCOUNT_PLANS.premium,
            ]}
          >
            <span
              className="d-flex justify-content-center align-items-center mr-2"
              style={{ width: "30px" }}
            >
              <Icon tooltip="Edit filter">
                <FaEdit />
              </Icon>
            </span>
          </AuthBasedComponent>
        </div>
      </td>
    </tr>
  )
}

export default FilterRow
