import React, { useState } from "react"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { database } from "../../../../../firebase"
import { setDefaultAssets } from "../../../../../redux/feed/feed.actions"
import {
  selectFeed,
  selectFeedType,
  selectFeedTags,
  selectIsFeedDirty,
  selectAssets,
  selectIsAssetsDirty,
  selectIsValidToSave,
  selectValidationMsg,
  selectGridSettings,
  selectCarouselSettings,
  selectSocialWallSettings,
  selectFeedLayoutSettings,
} from "../../../../../redux/feed/feed.selectors"
import withoutProperty from "../../../../../utils/withoutProperty"

// 3rd-party components
import { Row } from "react-bootstrap"

// Reeler components
import ReelerButton from "../../../../commons/ReelerButton"
import FadeAlert from "../../../../commons/FadeAlert/FadeAlert"
import StaticAlert from "../../../../commons/StaticAlert/StaticAlert"
import { SubmitSectionContainer } from "../../../styles"
import useUserFeedbackMessage from "../../../../../hooks/useUserFeedbackMessage"

const SaveButton = () => {
  // states
  const [msg, setMsg] = useState()
  const [saving, setSaving] = useState(false)
  const isValidToSave = useSelector(selectIsValidToSave)

  const { setUserFeedbackMessage } = useUserFeedbackMessage()

  const feed = useSelector(selectFeed)

  const handleSubmit = () => {
    setMsg(null)
    setSaving(true)

    let feedWithoutId = withoutProperty(feed, "id")

    database.feeds
      .doc(feed.id)
      .update(feedWithoutId)
      .then(doc => {
        // if position on any asset has changed then update all assets with new index
        setUserFeedbackMessage("Saved!", "success")

        setSaving(false)
      })
      .catch(err => {
        setUserFeedbackMessage(
          "Something went wrong! Your changes could not be saved",
          "danger",
          null
        )
        console.log(err)
        setSaving(false)
      })
  }

  return (
    <ReelerButton
      loading={saving}
      disabled={!isValidToSave || saving}
      dispatch={() => handleSubmit()}
      text="Save"
      className="mr-3"
      styleClass="btn-main"
      spinnerClass="ml-1"
    />
  )
}

export default SaveButton
