import React, { useState } from "react"
import { Tabs, Tab, Card } from "react-bootstrap"

import UserTable from "./UserTable"
import UserInvite from "./UserInvite"
import "../../App.css"
import Section from "../../../layouts/core/Section"
import { Header } from "../../../layouts/core/Header"
import Title from "../../../layouts/core/Title"
import Body from "../../../layouts/core/Body"
import BackButton from "../../commons/BackButton"
import InviteUserTable from "./InviteUserTable"
import AuthBasedComponent from "../../authentication/AuthBasedComponent"
import { ACCOUNT_PLANS, USER_ROLES } from "../../../constants/routes"
const AccountUsers = () => {
  const [key, setKey] = useState("users")
  return (
    <Section>
      <Header>
        <Title>Users</Title>
        <div className="ml-auto">
          <BackButton />
        </div>
      </Header>
      <Body>
        <p>
          Create new users, customize user permissions, and remove users from
          your account.
        </p>

        <div className="d-flex flex-row justify-content-end mb-3">
          <AuthBasedComponent
            userRoles={[USER_ROLES.owner]}
            plans={[
              ACCOUNT_PLANS.basic,
              ACCOUNT_PLANS.pro,
              ACCOUNT_PLANS.premium,
            ]}
          >
            <UserInvite />
          </AuthBasedComponent>
        </div>

        <Tabs activeKey={key} onSelect={k => setKey(k)}>
          <Tab tabClassName="link" eventKey="users" title="Users">
            <Card style={{ borderTop: "0px" }}>
              <Card.Body>
                <UserTable />
              </Card.Body>
            </Card>
          </Tab>
          <Tab tabClassName="link" eventKey="invites" title="Invitations">
            <Card style={{ borderTop: "0px" }}>
              <Card.Body>
                <InviteUserTable />
              </Card.Body>
            </Card>
          </Tab>
        </Tabs>
      </Body>
    </Section>
  )
}

export default AccountUsers
