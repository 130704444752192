import React, { useState, useEffect } from "react"
import {
  FaRegEnvelope,
  FaHashtag,
  FaHeading,
  FaAlignLeft,
  FaImage,
  FaGripLines,
  FaFileSignature,
  FaWhatsapp,
} from "react-icons/fa"
import { MdAlternateEmail } from "react-icons/md"
import { FiUpload, FiLogIn } from "react-icons/fi"
import {
  MdDateRange,
  MdNotes,
  MdOutlineAlternateEmail,
  MdPhone,
  MdShortText,
  MdArrowDropDownCircle,
  MdCheckBox,
  MdRadioButtonChecked,
} from "react-icons/md"

import { IoMdGlobe } from "react-icons/io"
import { BsBoxArrowInLeft } from "react-icons/bs"
import { ReactComponent as RlNumbers } from "../assets/icons/RlNumbers.svg"

export const STATUS = [
  { label: "Approved by uploader", status: "approved" },
  { label: "Approved by third party", status: "approved-third-party-approve" },
  { label: "Pending by uploader", status: "pending" },
  { label: "Pending by third party", status: "pending-third-party-approve" },
  { label: "Not approved by uploader", status: "not-approved" },
  { label: "Not approved by third party", status: "not-approved-third-party" },
]

export const ASSET_STATUS = {
  new: "new",
  approved: "approved",
  pending: "pending",
  notApproved: "not-approved",
}

export const ASSET_RIGHT_STATUS = {
  created: "created",
  pending: "pending",
  approved: "approved",
  notApproved: "not-approved",
}

export const CAMPAIGN_STATUS = {
  active: "active",
  inactive: "inactive",
  deleting: "deleting",
}

export const ASSET_STATE = {
  unreviewed: "unreviewed",
  pending: "pending",
  approved: "approved",
  rejected: "rejected",
  deleted: "deleted",
}

export const CAMPAIGN_TYPE = {
  upload: {
    type: "upload_2",
    icon: (
      <FiUpload
        style={{
          strokeWidth: "3px",
        }}
      />
    ),
  },
  igHashtag: {
    type: "hashtag",
    icon: <FaHashtag />,
  },
  igMentions: {
    type: "mentions",
    icon: <MdAlternateEmail />,
  },
  whatsapp: {
    type: "whatsapp",
    icon: <FaWhatsapp />,
  },
  email: {
    type: "email",
    icon: <FaRegEnvelope />,
  },
  landingpage: "landingpage",
  import: {
    type: "import",
    icon: (
      <FiLogIn
        style={{
          strokeWidth: "3px",
          transform: "scale(-1,1)",
        }}
      />
    ),
  },
}

export const MEDIA_TYPE = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  AUDIO: "AUDIO",
  TEXT: "TEXT",
}

export const MEDIA_ORIENTATION = {
  PORTRAIT: "PORTRAIT",
  SQUARE: "SQUARE",
  LANDSCAPE: "LANDSCAPE",
}

export const IMG_SIZES = {
  thumb: 200,
  preView: 500,
}

export const DOWNLOAD_FILE_STATES = {
  created: "created",
  processing: "processing",
  finished: "finished",
  error: "error",
}
export const PUBLISHING_JOB_TYPES = {
  instagram: "instagram",
  facebook: "facebook",
  youtube: "youtube",
}

export const PUBLISHING_JOB_STATUS = {
  inProgress: "IN_PROGRESS",
  published: "PUBLISHED",
  expired: "EXPIRED",
  error: "ERROR",
}

export const FEED_TYPES = {
  web: "web",
  email: "email",
}

export const FEED_EMAIL_LAYOUTS = {
  single: "single",
  row: "row",
}

export const FEED_PUBLISH_OPTIONS = {
  manually: "manually",
  automatically: "automatically",
}

export const RIGHT_REQUEST_STATUS = {
  created: "CREATED",
  error: "ERROR",
  completed: "COMPLETED",
}

export const RIGHTS_REQUEST_TYPE = {
  creator: "CREATOR",
  thirdParty: "THIRD_PARTY",
}

export const APPROVAL_CHANNEL = {
  approvalPage: "APPROVAL_PAGE",
  consentHashtag: "CONSENT_HASHTAG",
}

export const VERIFICATION_METHOD = {
  none: "NONE",
  instagram: "INSTAGRAM",
}

export const RIGHTS_REQUEST_APPROVAL_STATUS = {
  new: "new",
  pending: "pending",
  approved: "approved",
  notApproved: "not-approved",
}

export const RIGHTS_EMAIL_CODES = {
  link: "{{approval_link}}",
  approverFirstName: "{{approver_first_name}}",
  approverLastName: "{{approver_last_name}}",
  confirmationLink: "{{confirmation_link}}",
}

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  DEACTIVATED: "DEACTIVATED",
}
