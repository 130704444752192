import React, { useState } from "react"
import { Col, Form, Row, Tabs, Tab, InputGroup } from "react-bootstrap"
import { FaLink, FaRegCopy, FaShareSquare } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { saveCampaign } from "../../../../../../redux/campaign/campaign.actions"
import { TabCard, TabCardBody } from "./styles"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"
import CopyToClipboard from "../../../../../commons/CopyToClipboard"
import { selectCampaign } from "../../../../../../redux/campaign/campaign.selectors"
import ReelerButton from "../../../../../commons/ReelerButton"
import IconLink from "../../../../../commons/IconLink/IconLink"
import useUserFeedbackMessage from "../../../../../../hooks/useUserFeedbackMessage"

const PublishTab = () => {
  const [key, setKey] = useState("embed")
  const [copying, setCopying] = useState(false)
  const [showIframeCode, setShowIframeCode] = useState(false)
  const campaign = useSelector(selectCampaign)
  const dispatch = useDispatch()
  const { setSuccessMessage } = useUserFeedbackMessage()

  const formLink =
    process.env.REACT_APP_FIREBASE_WIDGET_URL + "/form/v2/" + campaign.id

  const handleMetaTags = (target, value) => {
    dispatch(
      saveCampaign({
        ...campaign,
        metaTags: {
          ...campaign.metaTags,
          [target]: value,
        },
      })
    )
  }

  const copyUrl = URL => {
    navigator.clipboard.writeText(URL)
    setSuccessMessage("Copied!")
  }

  const copyDiv = divId => {
    var r = document.createRange()
    r.selectNode(document.getElementById(divId))
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(r)
    document.execCommand("copy")
    window.getSelection().removeAllRanges()
    setSuccessMessage("Copied!")
  }

  const divCode = `<div id="reeler-widget" data-campaign="${campaign?.id}"></div>`
  const scriptCode = `<script src="${process.env.REACT_APP_FIREBASE_WIDGET_URL}/ReelerWidgets.js"></script>`
  const iframeCode = `<iframe
  width="100%"
  height="1500px"
  scrolling="yes"
  style="border: none"
  src="${process.env.REACT_APP_FIREBASE_WIDGET_URL}/iframe/v3/${campaign?.id}"
></iframe>`

  return (
    campaign && (
      <Tabs
        id="share-form"
        className="mt-3"
        activeKey={key}
        onSelect={k => setKey(k)}
      >
        <Tab eventKey="embed" title="Embed form">
          <div className="d-flex flex-column justify-content-center align-items-center p-3">
            <Form.Group className="mt-3">
              <Form.Text className="text-center mb-3">
                To embed this form, copy and paste the code below into the HTML
                code on your website.
              </Form.Text>
              <Form.Control
                id="script"
                disabled={true}
                as="textarea"
                style={{ fontSize: "12px" }}
                rows={4}
                value={divCode + scriptCode}
                aria-label="Embed code"
              />
              <ReelerButton
                text="Copy"
                styleClass="btn-main mt-2"
                dispatch={() => copyDiv("script")}
              />
            </Form.Group>
            <Form.Group className="w-100">
              <Form.Text className="text-center mb-3">
                or embed with{" "}
                <span
                  className="notification-link icon-btn"
                  onClick={() => setShowIframeCode(!showIframeCode)}
                >
                  iframe code
                </span>
              </Form.Text>
              {showIframeCode ? (
                <>
                  <Form.Control
                    id="iframe"
                    disabled={true}
                    as="textarea"
                    style={{ fontSize: "12px" }}
                    rows={7}
                    value={iframeCode}
                    aria-label="Embed code"
                  />
                  <ReelerButton
                    text="Copy iframe code"
                    styleClass="btn-main mt-2"
                    dispatch={() => copyDiv("iframe")}
                  />
                </>
              ) : null}
            </Form.Group>
          </div>
        </Tab>
        <Tab eventKey="link" title="Reeler-hosted page">
          <div className="d-flex flex-column justify-content-center align-items-center p-3">
            <Form.Group classname="mb-3">
              <Form.Text className="text-center mb-3">
                Begin sharing your form using the web address below.
              </Form.Text>
              <InputGroup className="mb-3">
                <Form.Control
                  disabled={true}
                  value={formLink}
                  aria-label="Form link"
                  style={{ fontSize: "12px" }}
                />
                <ReelerButton
                  text="Copy"
                  styleClass="btn-main"
                  dispatch={() => copyUrl(formLink)}
                  loading={copying}
                />
              </InputGroup>
            </Form.Group>

            <div className="d-flex flex-row align-items-center">
              <a className="link" href={formLink} target="_blank">
                <span>Open form in a new tab</span>
                <FaShareSquare className="ml-3 icon-color" />
              </a>
            </div>
          </div>
          {/* <Row>
            <Col>
              <Form.Group controlId="meta-title" className="mt-3">
                <Form.Label>Meta title</Form.Label>
                <Form.Control
                  type="text"
                  value={campaign?.metaTags?.title}
                  onChange={e => handleMetaTags("title", e.target.value)}
                />
                <Form.Text muted>Set title meta attribute</Form.Text>
              </Form.Group>
            </Col>
            <Col xs={7}>
              <Form.Group controlId="meta-description" className="mt-3">
                <Form.Label>Meta description</Form.Label>
                <Form.Control
                  as="textarea"
                  value={campaign?.metaTags?.description}
                  onChange={e => handleMetaTags("description", e.target.value)}
                />
                <Form.Text muted>Set description meta attribute</Form.Text>
              </Form.Group>
            </Col>
          </Row> */}
        </Tab>
      </Tabs>
    )
  )
}

export default PublishTab
