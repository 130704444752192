import React, { useEffect, useState } from "react"

// 3rd-party
import { Form } from "react-bootstrap"
import moment from "moment"
// Firebase
import { database, functions } from "../../../../../../firebase"

// Redux
import { useSelector } from "react-redux"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"

// Reeler components
import ReelerButton from "../../../../../commons/ReelerButton"
import * as S from "../../styles"
import AssetChangeStatusManually from "./AssetChangeStatusManually"

import RightRequests from "./rights/RightRequests"
import CreateRightRequest from "./rights/CreateRightRequest"

export default function AssetRights() {
  const asset = useSelector(selectActiveAsset)
  const [approval, setApproval] = useState(null)
  const [subject, setSubject] = useState(null)

  const [manuallySendingEmail, setManuallySendingEmail] = useState(false)

  useEffect(() => {
    // Get third party approval doc
    if (asset.thirdPartyApprovals && asset.thirdPartyApprovals.approvalId) {
      database.thirdPartyApprovals
        .doc(asset.thirdPartyApprovals.approvalId)
        .get()
        .then(doc => {
          if (doc.exists) {
            setApproval(database.formatDoc(doc))
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleManuallySendThirdPartyApprovalEmail = approvalId => {
    setManuallySendingEmail(true)
    manuallySendThirdPartyApprovalEmail(approvalId, subject)
      .then(res => {
        setManuallySendingEmail(false)
      })
      .catch(err => {
        console.log(err)
        setManuallySendingEmail(false)
      })
  }

  const manuallySendThirdPartyApprovalEmail = async (approvalId, subject) => {
    var manuallySendThirdPartyApprovalEmail = functions.httpsCallable(
      "manuallySendThirdPartyApprovalEmail"
    )

    try {
      const { data } = await manuallySendThirdPartyApprovalEmail({
        approvalId,
        subject,
      })
      return data
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <CreateRightRequest />

      <S.ModalTabContainer>
        <RightRequests />
        <div className="d-flex h-100 flex-column justify-content-between">
          {asset?.thirdPartyApprovals ? (
            <S.GreyContainer>
              <S.ModalTabHeading2>Third Party Approval</S.ModalTabHeading2>
              <S.FieldContainer $direction="row">
                <S.FieldTitle>Status:</S.FieldTitle>
                {asset?.thirdPartyApprovals?.status === "approved" ? (
                  <S.FieldValue>
                    {asset?.thirdPartyApprovals?.status}
                  </S.FieldValue>
                ) : approval ? (
                  <S.FieldValue>
                    {approval.status}{" "}
                    {approval.updatedAt
                      ? `(${moment(approval.updatedAt).format(
                          "YYYY-MM-DD hh:ss"
                        )})`
                      : null}
                  </S.FieldValue>
                ) : null}
              </S.FieldContainer>
              {asset?.thirdPartyApprovals?.third_party_email ? (
                <S.FieldContainer $direction="row">
                  <S.FieldTitle>Email:</S.FieldTitle>
                  <S.FieldValue>
                    {asset?.thirdPartyApprovals?.third_party_email}
                  </S.FieldValue>
                </S.FieldContainer>
              ) : null}
              {asset?.thirdPartyApprovals?.approvedBy ? (
                <S.FieldContainer $direction="row">
                  <S.FieldTitle>Approved at:</S.FieldTitle>
                  <S.FieldValue>
                    {moment(asset?.thirdPartyApprovals?.approvedAt).format(
                      "YYYY-MM-DD hh:ss"
                    )}
                  </S.FieldValue>
                </S.FieldContainer>
              ) : null}
              <S.FieldContainer $direction="row">
                <S.FieldTitle>Created:</S.FieldTitle>
                <S.FieldValue>
                  {moment(
                    asset?.thirdPartyApprovals?.createdAt.toDate()
                  ).format("YYYY-MM-DD hh:ss")}
                </S.FieldValue>
              </S.FieldContainer>
              {asset?.thirdPartyApprovals?.terms1 ? (
                <S.FieldContainer>
                  <S.FieldTitle>Terms 1:</S.FieldTitle>
                  <S.FieldValue>
                    {asset?.thirdPartyApprovals?.terms1}
                  </S.FieldValue>
                </S.FieldContainer>
              ) : null}
              {asset?.thirdPartyApprovals?.terms2 ? (
                <S.FieldContainer>
                  <S.FieldTitle>Terms 2:</S.FieldTitle>
                  <S.FieldValue>
                    {asset?.thirdPartyApprovals?.terms2}
                  </S.FieldValue>
                </S.FieldContainer>
              ) : null}

              <S.FieldContainer $direction="row">
                <a
                  href={`https://app.reelertech.com/approval/${asset?.thirdPartyApprovals?.approvalId}`}
                  target="_blank"
                  className="link"
                >
                  View link
                </a>
              </S.FieldContainer>
              <S.FieldContainer $direction="column">
                <Form.Control
                  type="text"
                  className="mb-3"
                  size="sm"
                  placeholder="Optional: Add a new email subject"
                  onChange={e => setSubject(e.target.value)}
                />
                <ReelerButton
                  text="Re-send approval"
                  styleClass="btn-secondary ml-5"
                  size="sm"
                  dispatch={() =>
                    handleManuallySendThirdPartyApprovalEmail(
                      asset?.thirdPartyApprovals?.approvalId
                    )
                  }
                  loading={manuallySendingEmail}
                  disabled={manuallySendingEmail}
                />
              </S.FieldContainer>
            </S.GreyContainer>
          ) : null}
          <AssetChangeStatusManually />
        </div>
      </S.ModalTabContainer>
    </>
  )
}
