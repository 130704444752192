import React, { useState } from "react"

import { useSelector, useDispatch } from "react-redux"

// Reeler components

import ShowCampaingAssets from "../../campaigns/components/ShowCampaingAssets"
import Section from "../../../layouts/core/Section"
import {
  Header,
  HeaderGroup,
  HeaderGroupCenter,
  HeaderGroupLeft,
  HeaderGroupRight,
} from "../../../layouts/core/Header"
import Body from "../../../layouts/core/Body"
import SaveCampaign from "./form-generator/components/SaveCampaign"
import { MAIN_ROUTES } from "../../../constants/routes"
import CampaignName from "./form-generator/components/campaignInfo/CampaignName/CampaignName"
import { FaChevronLeft } from "react-icons/fa"
import IconLink from "../../commons/IconLink/IconLink"
import {
  SubHeader,
  SubHeaderGroup,
  SubHeaderGroupLeft,
  SubHeaderGroupCenter,
  SubHeaderGroupRight,
} from "../../../layouts/core/SubHeader"
import { Nav } from "react-bootstrap"
import EditForm from "./EditForm"
import StyleAndDesignForm from "./form-generator/components/StyleAndDesignForm"
import EditWidget from "./components/EditWidget"
import {
  selectCampaign,
  selectFormSchema,
} from "../../../redux/campaign/campaign.selectors"
import { DragDropContext } from "react-beautiful-dnd"
import {
  addNewFormField,
  changeFieldPosition,
} from "../../../redux/campaign/campaign.actions"
import { FORM_FIELDS } from "./Fields"
import FormOptions from "./components/FormOptions"
import FormAutomation from "./form-generator/components/campaignInfo/FormAutomation"
import ShareForm from "./components/ShareForm"
import FormActions from "./components/FormActions"
import CampaignToggleStatus from "../../campaigns/components/CampaignToggleStatus"

export default function () {
  const [key, setKey] = useState("form")
  const formSchema = useSelector(selectFormSchema)
  const campaign = useSelector(selectCampaign)
  const dispatch = useDispatch()

  const handleDragEnd = result => {
    const { destination, source, draggableId } = result
    // If dropped unnokwn destination
    if (!destination) return

    // Can only drop within "formFields"
    if (destination.droppableId !== "formFields") return

    // If dest and source is within formFields, reorder
    if (destination.droppableId === source.droppableId) {
      let order = formSchema.order
      const fromIndex = source.index
      const toIndex = destination.index
      const element = order.splice(fromIndex, 1)[0]
      order.splice(toIndex, 0, element)
      dispatch(changeFieldPosition(order))
      return
    }

    // if dest and source is different, add new form field

    const { icon, label, type, ...field } = FORM_FIELDS[draggableId]

    let previousFieldId
    if (destination.index === 0) {
      previousFieldId = 0
    } else if (destination.index === formSchema.order.length) {
      previousFieldId = formSchema.order[destination.index]
    } else {
      previousFieldId = formSchema.order[destination.index - 1]
    }
    dispatch(addNewFormField(field, previousFieldId))
  }

  return (
    <Section>
      <Header>
        <HeaderGroup>
          <HeaderGroupLeft>
            <IconLink
              url={MAIN_ROUTES.campaigns.path}
              text="Back to all sources"
              icon={<FaChevronLeft />}
            />
          </HeaderGroupLeft>
          <HeaderGroupCenter>
            <CampaignName />
          </HeaderGroupCenter>
          <HeaderGroupRight>
            {campaign && (
              <div className="mr-3">
                <CampaignToggleStatus
                  campaignId={campaign?.id}
                  status={campaign?.status}
                />
              </div>
            )}

            <SaveCampaign />
          </HeaderGroupRight>
        </HeaderGroup>
      </Header>
      <SubHeader>
        <SubHeaderGroup>
          <SubHeaderGroupLeft>
            <ShowCampaingAssets />
          </SubHeaderGroupLeft>
          <SubHeaderGroupCenter>
            <Nav activeKey={key} onSelect={selectedKey => setKey(selectedKey)}>
              <Nav.Item>
                <Nav.Link eventKey="form">Edit form</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="style">Style form</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="options">Options</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="automation">Automation</Nav.Link>
              </Nav.Item>
            </Nav>
          </SubHeaderGroupCenter>
          <SubHeaderGroupRight>
            <div className="d-flex flex-row">
              <ShareForm />
              <FormActions />
            </div>
          </SubHeaderGroupRight>
        </SubHeaderGroup>
      </SubHeader>

      <Body>
        <EditWidget />
        <DragDropContext
          onDragEnd={handleDragEnd}
          className="dragdrop-container"
        >
          {(() => {
            switch (key) {
              case "form":
                return <EditForm />
              case "style":
                return <StyleAndDesignForm />
              case "options":
                return <FormOptions />
              case "automation":
                return <FormAutomation />
            }
          })()}
        </DragDropContext>
      </Body>
    </Section>
  )
}
