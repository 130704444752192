import React, { useRef, useState } from "react"
import firebase from "firebase/app"

import { useSelector, useDispatch } from "react-redux"
import { selectAccounts } from "../../../../redux/account/account.selectors"
import { updateAccounts } from "../../../../redux/account/account.actions"
import { storage, database } from "../../../../firebase"
import { Form, Row, Col, Container, ProgressBar } from "react-bootstrap"
import SpinnerComponent from "../../../commons/SpinnerComponent"
import FadeAlert from "../../../commons/FadeAlert/FadeAlert"
import { v4 as uuidv4 } from "uuid"
import ReelerButton from "../../../commons/ReelerButton"

import { useEditor } from "../../../../hooks/useEditor"

const defaultSettingsValues = {
  link_name: "",
  logo_url: "",
  rightsRequestText: "",
  privacyPolicy: "",
  terms: "",
}

export default function ApprovalPageTemplate() {
  const dispatch = useDispatch()
  const account = useSelector(selectAccounts)
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [settings, setSettings] = useState(null)
  const [index, setIndex] = useState(null)
  const fileRef = useRef()
  const { ReelerEditor, getHTML, setDefaultState } = useEditor()

  const [fileMessage, setFileMessage] = useState()
  const [uploadError, setUploadError] = useState(false)
  const [progress, setProgress] = useState(0)
  const [image, setImage] = useState()
  const [msg, setMsg] = useState()

  const handleUploadImage = e => {
    const file = e.target.files[0]
    setImage(null)

    if (file == null) return

    let format = file.name.split(".").pop().toLowerCase()
    let allowedFormats = ["svg", "png", "jpg", "jpeg"]

    if (!allowedFormats.includes(format)) {
      setFileMessage("File format can only be svg, png, jpg or jpeg")
      return
    }

    if (file.size > 5000000) {
      setFileMessage("File size is too big. File size limit is 5MB")
      return
    }

    setSettings({ ...settings, logo_url: URL.createObjectURL(file) })
    setImage(file)
  }

  const handleSubmit = () => {
    setSaving(true)
    let docToBeSaved = settings

    docToBeSaved["rightsRequestText"] = getHTML()

    // Check if there is a new file to upload
    if (image) {
      // Generate an id for filename
      const filename = uuidv4()
      let format = image.name.split(".").pop().toLowerCase()

      // Upload image to Storage
      setProgress(0)
      const uploadTask = storage
        .ref(
          `/${account.id}/right_request_templates/approval_pages/${
            filename + "." + format
          }`
        )
        .put(image)

      uploadTask.on(
        "state_changed",
        snapshot => {
          setProgress(snapshot.bytesTransferred / snapshot.totalBytes)
        },
        err => {
          setUploadError(true)
          setSaving(false)
        },
        async () => {
          uploadTask.snapshot.ref.getDownloadURL().then(url => {
            docToBeSaved["logo_url"] = url
            // save to firestore and update redux
            // Update account

            saveAccount(docToBeSaved)
            setProgress(0)
          })
        }
      )
    } else {
      saveAccount(docToBeSaved)
    }
  }

  const deleteLinkTemplate = () => {
    setIsDeleting(true)
    let approvalPages = account?.right_request_templates?.approvalPages
    approvalPages.splice(index, 1)

    database.accounts
      .doc(account.id)
      .update({
        "right_request_templates.approvalPages": approvalPages,
      })
      .then(() => {
        //Update accounts in redux
        dispatch(
          updateAccounts({
            right_request_templates: {
              ...account.right_request_templates,
              approvalPages: approvalPages,
            },
          })
        )
        setIndex(null)
        setSettings(null)
        setIsDeleting(false)
      })
      .catch(err => {
        console.log(err)
        setIsDeleting(false)
      })
  }

  const saveAccount = docToBeSaved => {
    console.log(docToBeSaved)
    console.log("Index: " + index)

    let approvalPages
    if (index) {
      approvalPages = account?.right_request_templates?.approvalPages.map(
        (approvalPage, i) =>
          i === parseInt(index) ? docToBeSaved : approvalPage
      )
    } else {
      if (account?.right_request_templates?.approvalPages) {
        approvalPages = [
          ...account?.right_request_templates?.approvalPages,
          docToBeSaved,
        ]
        setIndex(approvalPages.length - 1)
      } else {
        approvalPages = [docToBeSaved]
        setIndex(0)
      }
    }

    database.accounts
      .doc(account.id)
      .update({
        "right_request_templates.approvalPages": approvalPages,
      })
      .then(() => {
        //Update accounts in redux
        dispatch(
          updateAccounts({
            right_request_templates: {
              ...account.right_request_templates,
              approvalPages: approvalPages,
            },
          })
        )
        setMsg({ success: true, text: "Saved!" })
        setSaving(false)
        fileRef.current.value = null
      })
      .catch(err => {
        console.log(err)
        setSaving(false)
      })
  }

  const handleSelectLinkTemplate = value => {
    const appovalPage = account?.right_request_templates?.approvalPages[value]
    setSettings(appovalPage)
    setDefaultState(appovalPage?.rightsRequestText)
    setIndex(value)
  }

  const handleCreateNewTemplate = () => {
    setIndex(null)
    setSettings(defaultSettingsValues)
  }

  return !account ? (
    <SpinnerComponent size="sm" />
  ) : (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={10}>
          <h6 className="mr-3">Approval page templates</h6>
          <Form.Group>
            <Form.Text>
              Design one or several templates for “approval pages” where
              end-users grant you permission for the use of content. For
              instance, you could create one type of approval page for content
              creators (granting permission to use their work) and another type
              of approval page for 3rd parties (granting permission for the use
              of images where they appear).
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-center my-3">
        <Col
          md={10}
          className="d-flex flex-row justify-content-center align-items-center"
        >
          <ReelerButton
            dispatch={() => handleCreateNewTemplate()}
            text="Create new"
            styleClass="btn-main"
          />
          <span className="mx-3">or</span>

          <Form.Control
            as="select"
            name="link_templates"
            onChange={e => handleSelectLinkTemplate(e.target.value)}
            style={{ width: "250px" }}
          >
            <option>Edit templates</option>

            {account?.right_request_templates?.approvalPages?.map(
              (link, index) => (
                <option key={index} value={index}>
                  {`${index + 1}: ${link.link_name}`}
                </option>
              )
            )}
          </Form.Control>
        </Col>
      </Row>
      <Row className="justify-content-center mt-3">
        <Col md={8}>
          {settings ? (
            <>
              <Form.Group className="mb-3">
                <Form.Label>1. Template name</Form.Label>
                <Form.Control
                  type="text"
                  value={settings?.link_name}
                  onChange={e =>
                    setSettings({
                      ...settings,
                      link_name: e.target.value,
                    })
                  }
                />
                <Form.Text className="text-muted">
                  Give this template an internal name.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>2. Upload brand logo</Form.Label>

                {settings?.logo_url ? (
                  <div className="d-flex my-3">
                    <img
                      alt="Brand logo"
                      style={{
                        objectFit: "contain",
                        maxWidth: "200px",
                        maxHeight: "80px",
                      }}
                      src={settings?.logo_url}
                    />
                  </div>
                ) : null}
                <Form.Control
                  ref={fileRef}
                  type="file"
                  onChange={handleUploadImage}
                />

                <Form.Text className="text-muted">
                  Upload a brand logo to increase approval rates. Valid formats
                  are jpg, jpeg, png.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>3. Approval page introduction text</Form.Label>

                <ReelerEditor />

                <Form.Text className="text-muted">
                  Write a friendly introduction text, where you ask the content
                  creator (or 3rd party) for permission to use the content asset
                  in your communication. Give some praise for their great
                  photographic work! You may want to include a brief mention of
                  how you will use the content, but for the full terms &
                  conditions (T&C), you can include a URL below. At the end,
                  write that by clicking “approve” the end-user accepts your T&C
                  and your privacy policy.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  4. Add links to your T&C and privacy policy
                </Form.Label>
                <Form.Group>
                  <Form.Label>Terms & conditions URL</Form.Label>
                  <Form.Control
                    type="text"
                    value={settings?.terms}
                    onChange={e =>
                      setSettings({
                        ...settings,
                        terms: e.target.value,
                      })
                    }
                  />
                  <Form.Text className="text-muted">
                    Insert a URL link to your company’s terms & conditions (or
                    content licensing terms), where you specify what the content
                    creator or 3rd party should agree to. Typically, the T&C
                    would specify how you, as a brand, are allowed to use the
                    content and for what duration. Often, the T&C also spell out
                    whether there will be any compensation for your use of the
                    content or if you will receive a content license without any
                    monetary compensation.
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Privacy policy URL</Form.Label>
                  <Form.Control
                    type="text"
                    value={settings?.privacyPolicy}
                    onChange={e =>
                      setSettings({
                        ...settings,
                        privacyPolicy: e.target.value,
                      })
                    }
                  />
                  <Form.Text className="text-muted">
                    Insert a URL link to your privacy policy, where you specify
                    how you will process personal data. The end-user should
                    approve both your T&C and your privacy policy by clicking
                    "approve".
                  </Form.Text>
                </Form.Group>
              </Form.Group>

              <Row>
                <Col>
                  {progress > 0 ? (
                    <ProgressBar
                      className="mt-3 mb-3"
                      animated={!uploadError}
                      variant={uploadError ? "danger" : "primary"}
                      now={uploadError ? 100 : progress * 100}
                      label={
                        uploadError ? "Error" : `${Math.round(progress * 100)}%`
                      }
                    />
                  ) : null}
                  {msg && <FadeAlert msg={msg} />}
                  <Form.Group className="d-flex justify-content-end">
                    <ReelerButton
                      loading={isDeleting}
                      disabled={!index}
                      dispatch={() => deleteLinkTemplate()}
                      text="Delete"
                      styleClass="btn-danger mr-3"
                      spinnerClass="ml-1"
                    />
                    <ReelerButton
                      loading={saving}
                      disabled={saving}
                      dispatch={e => handleSubmit()}
                      text="Save"
                      styleClass="btn-main"
                      spinnerClass="ml-1"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          ) : null}
        </Col>
      </Row>
    </Container>
  )
}
