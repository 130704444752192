import { database } from "../firebase"

export const saveCampaign = async campaign => {
  if (!campaign) return

  console.log("saving campaign to db", campaign)

  let { id: value, ...campaignWithoutId } = campaign

  await database.campaigns
    .doc(campaign.id)
    .set(campaignWithoutId, { merge: true })
  console.log("saved campaign")
}
