import React, { useEffect, useState } from "react"

// 3rd-party
import { v4 as uuidV4 } from "uuid"
import { Col, Form } from "react-bootstrap"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  addFormField,
  addNewFormField,
  saveFormField,
  setShowEditModal,
} from "../../../../redux/campaign/campaign.actions"
import {
  selectedField,
  selectFormSchema,
} from "../../../../redux/campaign/campaign.selectors"

// Reeler components
import ColorPicker from "../../../commons/ColorPicker"
import ReelerButton from "../../../commons/ReelerButton"

const SubmitButtonField = ({ previousFieldId, closeModal }) => {
  const dispatch = useDispatch()
  const formSchema = useSelector(selectFormSchema)
  const fieldId = useSelector(selectedField)

  const [settings, setSettings] = useState({
    widget: "submitButton",
    btnText: "Submit",
    submitMessage: null,
    redirectUrl: null,
  })

  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (fieldId && formSchema) {
      const defaultValues = formSchema.properties[fieldId]
      setSettings(defaultValues)
    }
  }, [fieldId])

  const handleSubmit = () => {
    setSaving(true)
    const field = settings
    //fieldId is existing if we are updating the field, then we should only update the field and not the fieldId and order.
    if (fieldId) {
      dispatch(saveFormField(fieldId, field))
      setSaving(false)

      //dispatch(setShowEditModal(false))
    } else {
      dispatch(addNewFormField(field, previousFieldId))

      setSaving(false)
      closeModal()
    }
  }
  return (
    <Form>
      {/* <Form.Group controlId="submitMessage">
        <Form.Label className="mb-0">Submit message</Form.Label>
        <Form.Control
          as="textarea"
          type="text"
          value={settings?.submitMessage}
          onChange={e =>
            setSettings({ ...settings, submitMessage: e.target.value })
          }
        />
        <Form.Text muted>
          This will be displayed when the form is submitted
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="redirectUrl">
        <Form.Label className="mb-0">Redirect URL</Form.Label>
        <Form.Control
          type="text"
          value={settings?.redirectUrl}
          onChange={e =>
            setSettings({ ...settings, redirectUrl: e.target.value })
          }
        />
        <Form.Text muted>
          The user will be redirected to this URL after submitting the form.
        </Form.Text>
      </Form.Group> */}

      <Form.Group controlId="btnText">
        <Col md={6} className="mx-n3">
          <Form.Label>Button text</Form.Label>
          <Form.Control
            type="text"
            value={settings?.btnText}
            onChange={e =>
              setSettings({ ...settings, btnText: e.target.value })
            }
          />
          <Form.Text muted>
            (Optional) Customize the text on the upload button
          </Form.Text>
        </Col>
      </Form.Group>
      <Form.Group>
        <ReelerButton
          loading={saving}
          disabled={saving}
          dispatch={e => handleSubmit()}
          text="Save"
          className="mr-3"
          styleClass="btn-main"
          spinnerClass="ml-1"
        />
      </Form.Group>
    </Form>
  )
}

export default SubmitButtonField
