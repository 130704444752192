import React, { useState } from "react"

// 3rd-party
import { FaDesktop, FaMobileAlt } from "react-icons/fa"

// Redux
import { useSelector } from "react-redux"
import { selectFormSchema } from "../../../../redux/campaign/campaign.selectors"

// Reeler components
import FormGenerator from "../form-generator/FormGenerator"

import * as S from "../styles"

export default function () {
  const [mobileView, setMobileView] = useState(false)

  const formSchema = useSelector(selectFormSchema)

  return (
    <div style={{ position: "sticky", top: "120px" }}>
      <S.DeviceSwitchContainer>
        {/* IMPROVEMENT: use ReelerIconButton? */}
        <strong>Preview</strong>
        <div>
          <FaDesktop
            size={20}
            className={
              !mobileView ? "mr-2 icon-btn icon-reeler" : "mr-2 icon-btn"
            }
            onClick={() => setMobileView(false)}
          />
          <FaMobileAlt
            size={20}
            className={
              mobileView ? "mr-2 icon-btn icon-reeler" : "mr-2 icon-btn"
            }
            onClick={() => setMobileView(true)}
          />
        </div>
      </S.DeviceSwitchContainer>
      <S.FormContainer
        mobileView={mobileView}
        formBackground={formSchema?.design?.formBackground}
        fontColor={formSchema?.design?.fontColor}
      >
        <FormGenerator />
      </S.FormContainer>
    </div>
  )
}
