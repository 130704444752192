import React, { useState } from "react"
import { Modal, ModalBody } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { clearFilter } from "../../../redux/filter/filter.actions"
import ReelerButton from "../../commons/ReelerButton"
import { database } from "../../../firebase"
import { selectAccountId } from "../../../redux/account/account.selectors"
import { selectActiveFilter } from "../../../redux/filter/filter.selectors"

export default function DeleteFilter({ btn }) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const accountId = useSelector(selectAccountId)
  const activeFilter = useSelector(selectActiveFilter)

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  const handleDeleteFilter = async () => {
    if (activeFilter?.id) {
      setLoading(true)
      const filterRef = database.accounts
        .doc(`${accountId}`)
        .collection("filters")
        .doc(activeFilter.id)
      try {
        await filterRef.delete()

        dispatch(clearFilter())
        setLoading(false)
        closeModal()
      } catch (error) {
        closeModal()
        console.log(error)
        setLoading(false)
      }
    }
  }

  return (
    <>
      <div onClick={openModal} className="d-flex align-items-center">
        {btn}
      </div>

      <Modal centered show={open} onHide={closeModal}>
        <ModalBody>
          <Modal.Title>
            Are you sure you want to delete this filter?
          </Modal.Title>
          <Modal.Body>
            If you delete this filter you cannot recreate it.
          </Modal.Body>
          <Modal.Footer>
            <div
              className="mr-4"
              variant="secondary"
              onClick={closeModal}
              style={{ cursor: "pointer" }}
            >
              Close
            </div>
            <ReelerButton
              loading={loading}
              dispatch={() => handleDeleteFilter()}
              text="Delete filter"
              styleClass="btn-delete"
            />
          </Modal.Footer>
        </ModalBody>
      </Modal>
    </>
  )
}
