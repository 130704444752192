import React, { useState, useEffect } from "react"
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar"
import { Link, useLocation } from "react-router-dom"
import "./Sidebar.css"
import { database } from "../../firebase"
import { ReactComponent as SourcesIcon } from "../../assets/icons/source_arrow_bl.svg"
import {
  FaImages,
  FaExclamationCircle,
  FaChevronLeft,
  FaChevronRight,
  FaCog,
  FaUsers,
  FaUserCircle,
  FaEdit,
  FaBell,
  FaLock,
  FaCogs,
  FaPlus,
  FaDatabase,
  FaFilter,
  FaQuestion,
  FaFileSignature,
  FaTags,
  FaBuilding,
} from "react-icons/fa"
import { BsBoxFill } from "react-icons/bs"
import { MdViewCarousel, MdFilterAlt } from "react-icons/md"
import { FiLogOut } from "react-icons/fi"
import ReelerTooltip from "../commons/ReelerTooltip/ReelerTooltip"
import * as S from "./MainSidebar-styles"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentUser } from "../../redux/user/user.selectors"
import { signOutStart } from "../../redux/user/user.actions"
import { selectCampaign } from "../../redux/campaign/campaign.selectors"
import {
  loadCampaign,
  clearCampaign,
} from "../../redux/campaign/campaign.actions"
import { selectFeed } from "../../redux/feed/feed.selectors"
import {
  initiateFeed,
  setFeed,
  setFeedType,
} from "../../redux/feed/feed.actions"
import {
  selectActiveFilter,
  selectFilterParameters,
} from "../../redux/filter/filter.selectors"
import {
  setActiveFilter,
  setActiveAccountFilter,
  clearFilter,
  setShowFilter,
  setShowSaveFilter,
  setFilterParameters,
} from "../../redux/filter/filter.actions"

import { BulkEditReset } from "../../redux/bulk-edit/bulkEdit.actions"
import {
  selectIsCollapsed,
  selectSubmenuStates,
} from "../../redux/navbar/navbar.selectors"
import {
  setIsCollapsed,
  updateSubmenuState,
} from "../../redux/navbar/navbar.actions"
import {
  selectAccounts,
  selectAccountMediaFilter,
} from "../../redux/account/account.selectors"
import { resetImport } from "../../redux/import-assets/import.actions"
import { ACCOUNT_PLANS, MAIN_ROUTES, USER_ROLES } from "../../constants/routes"
import CreateFilterCollection from "../media-library/components/CreateFilterCollection/CreateFilterCollection"
import ReelerButton from "../commons/ReelerButton"
import CampaignNumberOfAssets from "../campaigns/components/CampaignNumberOfAssets"
import CreateFeed from "../feeds/components/header/CreateFeed"
import CreateCampaign from "../campaigns/components/CreateCampaign"
import { CAMPAIGN_STATUS, CAMPAIGN_TYPE } from "../../constants"
import CountAssetsInFilter from "../commons/CountAssetsInFilter/CountAssetsInFilter"
import Submenu from "./components/Submenu"
import AuthBasedComponent from "../authentication/AuthBasedComponent"

export default function MainSidebar() {
  const isCollapsed = useSelector(selectIsCollapsed)
  const subMenuStates = useSelector(selectSubmenuStates)
  const currentUser = useSelector(selectCurrentUser)
  const account = useSelector(selectAccounts)
  const activeFilter = useSelector(selectActiveFilter)
  const filterParameters = useSelector(selectFilterParameters)
  const selectedCampaign = useSelector(selectCampaign)
  const selectedFeed = useSelector(selectFeed)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [feeds, setFeeds] = useState(null)
  const [campaigns, setCampaigns] = useState(null)
  const [filters, setFilters] = useState(null)

  const handleLogout = () => {
    dispatch(signOutStart())
  }

  const toggleIsCollapsed = () => {
    dispatch(setIsCollapsed(!isCollapsed))
  }

  const handleSubmenuStateChange = (key, state) => {
    dispatch(updateSubmenuState({ key, state }))
  }

  const AuthBasedMenuItem = ({
    userRoles = [USER_ROLES.owner, USER_ROLES.editor],
    plans = [ACCOUNT_PLANS.basic],
    children,
    ...rest
  }) => {
    return (
      <AuthBasedComponent userRoles={userRoles} plans={plans}>
        <MenuItem {...rest}>{children}</MenuItem>
      </AuthBasedComponent>
    )
  }

  const AuthBasedSubMenu = ({
    userRoles = [USER_ROLES.owner, USER_ROLES.editor],
    plans = [ACCOUNT_PLANS.basic],
    children,
    ...rest
  }) => {
    return (
      <AuthBasedComponent userRoles={userRoles} plans={plans}>
        <SubMenu {...rest}>{children}</SubMenu>
      </AuthBasedComponent>
    )
  }

  const handleSelectFilter = filter => {
    dispatch(BulkEditReset())
    dispatch(clearCampaign())
    dispatch(setFeed(null))
    dispatch(setFeedType(null))
    dispatch(clearFilter())
    dispatch(setActiveFilter(filter))
    dispatch(setFilterParameters(filter?.filterParameters))

    history.push(MAIN_ROUTES.content.path)
  }
  const handleCreateNewFilter = () => {
    clearAllParameters()
    dispatch(setShowSaveFilter(true))
  }
  const handleEditFilter = filter => {
    dispatch(BulkEditReset())
    dispatch(clearCampaign())
    dispatch(setFeed(null))
    dispatch(setFeedType(null))
    dispatch(clearFilter())
    dispatch(setActiveFilter(filter))
    dispatch(setFilterParameters(filter?.filterParameters))
    dispatch(setShowSaveFilter(true))
  }

  const clearAllParameters = () => {
    dispatch(BulkEditReset())
    dispatch(clearFilter())
    dispatch(setFeed(null))
    dispatch(setFeedType(null))
    dispatch(clearCampaign())
  }

  const handleShowAllContent = () => {
    clearAllParameters()
    //dispatch(setShowFilter(true))
    history.push(MAIN_ROUTES.content.path)
  }

  const showCampaign = campaign => {
    dispatch(clearFilter())
    dispatch(BulkEditReset())
    dispatch(setFeed(null))
    dispatch(setFeedType(null))
    dispatch(setFilterParameters({ campaigns: [campaign.id] }))
    dispatch(loadCampaign(campaign))
    history.push(MAIN_ROUTES.content.path)
  }

  // const showFeed = feed => {
  //   dispatch(clearFilter())
  //   dispatch(clearCampaign())
  //   dispatch(BulkEditReset())

  //   if (feed?.tags && Object.keys(feed?.tags)?.length > 0) {
  //     dispatch(setActiveFilter({ tags: Object.keys(feed?.tags).join(" ") }))
  //   }
  //   dispatch(initiateFeed())
  //   dispatch(setFeed(feed))
  //   dispatch(setFeedType(feed.feedType))
  //   history.push(MAIN_ROUTES.content.path)
  // }

  const handleShowAllSources = () => {
    clearAllParameters()
    history.push(MAIN_ROUTES.campaigns.path)
  }
  const handleShowAllFeeds = () => {
    clearAllParameters()
    history.push(MAIN_ROUTES.feeds.path)
  }
  const handleShowAllFilters = () => {
    clearAllParameters()
    history.push(MAIN_ROUTES.filters.path)
  }

  useEffect(() => {
    if (account?.id) {
      database.feeds
        .where("accountId", "==", account.id)
        .orderBy("feedName", "asc")
        .onSnapshot(snap => {
          const data = snap.docs.map(doc => database.formatDoc(doc))
          setFeeds(data)
        })
    }
  }, [account])

  useEffect(() => {
    if (account?.id) {
      database.campaigns
        .where("accountId", "==", account.id)
        .orderBy("campaignName", "asc")
        .onSnapshot(snap => {
          const data = snap.docs.map(doc => database.formatDoc(doc))
          setCampaigns(data)
        })
    }
  }, [account])

  useEffect(() => {
    if (account?.id) {
      let q = database.accounts.doc(account?.id).collection("filters")

      if (currentUser?.role === USER_ROLES.viewer) {
        q = q.where("userIds", "array-contains", currentUser?.id)
      }

      q.orderBy("name", "asc").onSnapshot(snap => {
        const data = snap.docs.map(doc => database.formatDoc(doc))
        setFilters(data)
      })
    }
  }, [account])

  const RenderIcon = ({ icon, label }) => {
    if (isCollapsed) {
      return (
        <ReelerTooltip text={label}>
          <S.MenuItem>
            {icon}
            <S.MenuItemText>{label}</S.MenuItemText>
          </S.MenuItem>
        </ReelerTooltip>
      )
    } else {
      return icon
    }
  }

  const menuItemStyles = {
    root: {
      height: "100%",
    },
    label: ({ level, active, disabled }) => {
      // only apply styles on first level elements of the tree
      if (level === 0) {
        return {
          color: "var(--reeler-black)",
        }
      } else {
        return {
          color: "var(--reeler-icon)",
        }
      }
    },
    button: ({ level, active, disabled }) => {
      // only apply styles on first level elements of the tree
      if (level === 0) {
        return {
          fontSize: "0.75rem",
          paddingRight: "10px",
          color: active ? "var(--reeler-darkest-green)" : "var(--reeler-icon)",
          backgroundColor: active ? "var(--reeler-super-light)" : undefined,
          "&:hover": {
            backgroundColor: active
              ? "var(--reeler-super-light)"
              : "var(--reeler-grey)",
          },
        }
      }
      if (level === 1) {
        return {
          height: "30px",
          paddingRight: "10px",
          fontSize: "0.75rem",
          color: active ? "var(--reeler-darkest-green)" : "var(--reeler-icon)",
          backgroundColor: active ? "var(--reeler-super-light)" : undefined,
          "&:hover": {
            backgroundColor: active
              ? "var(--reeler-super-light)"
              : "var(--reeler-grey)",
          },
        }
      } else {
        return {
          height: "30px",
          paddingRight: "10px",
          fontSize: "0.75rem",
          color: active ? "var(--reeler-darkest-green)" : "var(--reeler-icon)",
          backgroundColor: active ? "var(--reeler-super-light)" : undefined,
          "&:hover": {
            backgroundColor: active
              ? "var(--reeler-super-light)"
              : "var(--reeler-grey)",
          },
        }
      }
    },
    icon: ({ level, active, disabled }) => {
      // only apply styles on first level elements of the tree
      if (level === 0) {
        return {
          marginRight: "20px",
          color: active ? "var(--reeler-darkest-green)" : "var(--reeler-icon)",
        }
      } else {
        return {
          marginRight: "0px",
          color: active ? "var(--reeler-darkest-green)" : "var(--reeler-icon)",
        }
      }
    },
    SubMenuExpandIcon: {
      alignItems: "center",
    },
    subMenuContent: {
      borderRadius: "0px",
      fontSize: "14px",
      width: isCollapsed ? "350px" : "100%",
      maxHeight: "100vh",
      overflowY: "scroll",
      paddingBottom: "5px",
    },
  }

  const renderSourceIcon = campaign_type => {
    switch (campaign_type) {
      case CAMPAIGN_TYPE.igHashtag.type:
        return CAMPAIGN_TYPE.igHashtag.icon
      case CAMPAIGN_TYPE.upload.type:
        return CAMPAIGN_TYPE.upload.icon
      case CAMPAIGN_TYPE.email.type:
        return CAMPAIGN_TYPE.email.icon
      case CAMPAIGN_TYPE.igMentions.type:
        return CAMPAIGN_TYPE.igMentions.icon
      case CAMPAIGN_TYPE.import.type:
        return CAMPAIGN_TYPE.import.icon
      case CAMPAIGN_TYPE.whatsapp.type:
        return CAMPAIGN_TYPE.whatsapp.icon
    }
  }

  const editSource = campaign => {
    dispatch(clearFilter())
    dispatch(setShowFilter(false))
    dispatch(setFeed(null))
    dispatch(setFeedType(null))
    dispatch(loadCampaign(campaign))
    switch (campaign.campaignType) {
      case "upload":
        history.push(MAIN_ROUTES.campaignsEditCampaign.path)
        break
      case CAMPAIGN_TYPE.upload.type:
        history.push(MAIN_ROUTES.campaignsFormBuilder.path)
        break
      case CAMPAIGN_TYPE.igHashtag.type:
        history.push(MAIN_ROUTES.campaignsEditHashtag.path)
        break
      case CAMPAIGN_TYPE.landingpage.type:
        history.push(MAIN_ROUTES.campaignsEditCampaign.path)
        break
      case CAMPAIGN_TYPE.email.type:
        history.push(MAIN_ROUTES.campaignsEditEmail.path)
        break
      case CAMPAIGN_TYPE.igMentions.type:
        history.push(MAIN_ROUTES.campaignsEditMention.path)
        break
      case CAMPAIGN_TYPE.whatsapp.type:
        history.push(MAIN_ROUTES.campaignsEditWhatsApp.path)
        break
      default:
        history.push(MAIN_ROUTES.campaignsEditCampaign.path)
        break
    }
  }

  const editFeed = feed => {
    dispatch(clearFilter())
    dispatch(clearCampaign())

    dispatch(setShowFilter(false))
    // if (feed?.tags && Object.keys(feed?.tags)?.length > 0) {
    //   dispatch(setFilterParameters({ tags: Object.keys(feed?.tags).join(" ") }))
    // }
    dispatch(initiateFeed())
    dispatch(setFeed(feed))
    dispatch(setFeedType(feed.feedType))
    history.push(MAIN_ROUTES.feedsEditFeed.path)
  }

  return (
    currentUser &&
    account && (
      <Sidebar
        width="300px"
        backgroundColor="#FFF"
        collapsed={isCollapsed}
        rootStyles={{
          height: "100%",
          borderRight: "1px solid var(--reeler-grey-dark)",
        }}
      >
        <S.Header>
          {isCollapsed ? (
            <div
              className="d-flex flex-row position-relative w-100"
              style={{ cursor: "pointer", alignItems: "center" }}
              onClick={() => toggleIsCollapsed()}
            >
              <div className="w-100">
                <S.Logo style={{ fontSize: "16px" }}>Reeler</S.Logo>
              </div>

              <span style={{ position: "absolute", right: "0px" }}>
                <FaChevronRight size="0.5rem" />
              </span>
            </div>
          ) : (
            <div
              className="d-flex flex-row position-relative w-100"
              style={{ cursor: "pointer", alignItems: "center" }}
              onClick={() => toggleIsCollapsed()}
            >
              <div className="w-100">
                <S.Logo>Reeler</S.Logo>
              </div>

              <span style={{ position: "absolute", right: "10px" }}>
                <FaChevronLeft size="0.5rem" />
              </span>
            </div>
          )}
        </S.Header>
        <div
          className="d-flex flex-column"
          style={{ height: "calc(100vh - 71px)" }}
        >
          <S.MenuSection style={{ height: "100%", overflowY: "scroll" }}>
            <Menu menuItemStyles={menuItemStyles}>
              <AuthBasedMenuItem
                active={
                  location.pathname.includes(MAIN_ROUTES.content.path) &&
                  !activeFilter &&
                  !selectedCampaign
                }
                icon={
                  <RenderIcon
                    icon={<FaImages size="1.1rem" />}
                    label="Content"
                  />
                }
                plans={MAIN_ROUTES.content.plans}
                onClick={() => handleShowAllContent()}
              >
                All content
              </AuthBasedMenuItem>
              <AuthBasedSubMenu
                active={
                  location.pathname.includes(MAIN_ROUTES.content.path) &&
                  activeFilter
                }
                open={subMenuStates?.filters ? subMenuStates?.filters : false}
                onOpenChange={openState => {
                  handleSubmenuStateChange("filters", openState)
                }}
                icon={
                  activeFilter ? (
                    <RenderIcon
                      icon={<FaFilter size="1rem" />}
                      label="Filters"
                    />
                  ) : (
                    <RenderIcon
                      icon={<FaFilter size="1rem" />}
                      label="Filters"
                    />
                  )
                }
                plans={MAIN_ROUTES.content.plans}
                userRoles={[
                  USER_ROLES.editor,
                  USER_ROLES.owner,
                  USER_ROLES.viewer,
                ]}
                label="Content filters"
              >
                <AuthBasedMenuItem
                  icon={<FaPlus size="0.75rem" />}
                  plans={MAIN_ROUTES.content.plans}
                  onClick={() => handleCreateNewFilter()}
                >
                  <ReelerTooltip text="Save new filter based on active filter settings.">
                    <span>New content filter</span>
                  </ReelerTooltip>
                </AuthBasedMenuItem>
                <AuthBasedMenuItem
                  active={
                    location.pathname.includes(MAIN_ROUTES.filters.path) &&
                    !activeFilter
                  }
                  plans={MAIN_ROUTES.filters.plans}
                  userRoles={MAIN_ROUTES.filters.userRoles}
                  icon={<MdFilterAlt size="1rem" />}
                  onClick={() => handleShowAllFilters()}
                >
                  <strong>All content filters</strong>
                </AuthBasedMenuItem>
                {filters &&
                  filters.map((filter, index) => (
                    <AuthBasedMenuItem
                      key={index}
                      icon={<FaChevronRight size="0.75rem" />}
                      active={activeFilter?.id === filter.id}
                      plans={MAIN_ROUTES.content.plans}
                      userRoles={[
                        USER_ROLES.editor,
                        USER_ROLES.owner,
                        USER_ROLES.viewer,
                      ]}
                      onClick={() => handleSelectFilter(filter)}
                    >
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <ReelerTooltip text={`Show filter content`}>
                          <span className="text-truncate">{filter.name}</span>
                        </ReelerTooltip>
                        <div className="d-flex flex-row align-items-center">
                          <CountAssetsInFilter
                            filter={filter?.filterParameters}
                          />
                          <AuthBasedComponent plans={MAIN_ROUTES.content.plans}>
                            <ReelerTooltip text="Edit saved filter">
                              <div className="d-flex ml-2">
                                <FaEdit
                                  className="icon-color icon-btn"
                                  onClick={() => handleEditFilter(filter)}
                                />
                              </div>
                            </ReelerTooltip>
                          </AuthBasedComponent>
                        </div>
                      </div>
                    </AuthBasedMenuItem>
                  ))}
              </AuthBasedSubMenu>
              <AuthBasedSubMenu
                active={
                  (location.pathname.includes(MAIN_ROUTES.content.path) &&
                    selectedCampaign?.id &&
                    filterParameters?.campaigns?.includes(
                      selectedCampaign?.id
                    )) ||
                  (location.pathname.includes(MAIN_ROUTES.campaigns.path) &&
                    !selectedCampaign) ||
                  (location.pathname.includes(MAIN_ROUTES.campaigns.path) &&
                    selectedCampaign?.id)
                }
                open={subMenuStates?.sources ? subMenuStates?.sources : false}
                onOpenChange={openState => {
                  handleSubmenuStateChange("sources", openState)
                }}
                icon={
                  selectedCampaign ? (
                    <RenderIcon
                      icon={
                        <SourcesIcon
                          width="1.2rem"
                          height="1.2rem"
                          fill="#707070"
                        />
                      }
                      label="Sources"
                    />
                  ) : (
                    <RenderIcon
                      icon={
                        <SourcesIcon
                          width="1.2rem"
                          height="1.2rem"
                          fill="#707070"
                        />
                      }
                      label="Sources"
                    />
                  )
                }
                plans={MAIN_ROUTES.campaigns.plans}
                label="Sources"
              >
                <AuthBasedMenuItem
                  icon={<FaPlus size="0.75rem" />}
                  plans={MAIN_ROUTES.campaigns.plans}
                >
                  <CreateCampaign
                    btn={
                      <ReelerTooltip text="Add new source">
                        <span>New source</span>
                      </ReelerTooltip>
                    }
                  />
                </AuthBasedMenuItem>
                <AuthBasedMenuItem
                  active={
                    location.pathname.includes(MAIN_ROUTES.campaigns.path) &&
                    !selectedCampaign
                  }
                  plans={MAIN_ROUTES.campaigns.plans}
                  icon={
                    <SourcesIcon
                      width="0.75rem"
                      height="0.75rem"
                      fill="#707070"
                    />
                  }
                  onClick={() => handleShowAllSources()}
                >
                  <strong>All sources</strong>
                </AuthBasedMenuItem>
                {campaigns
                  ?.filter(c => c.status === CAMPAIGN_STATUS.active)
                  ?.sort((a, b) => {
                    if (
                      a.campaignName.toLowerCase() <
                      b.campaignName.toLowerCase()
                    ) {
                      return -1
                    }
                    if (
                      a.campaignName.toLowerCase() >
                      b.campaignName.toLowerCase()
                    ) {
                      return 1
                    }
                    return 0
                  })
                  ?.map((campaign, index) => (
                    <AuthBasedMenuItem
                      key={index}
                      active={
                        (location.pathname.includes(MAIN_ROUTES.content.path) &&
                          selectedCampaign?.id === campaign?.id &&
                          filterParameters?.campaigns?.includes(
                            selectedCampaign?.id
                          )) ||
                        (location.pathname.includes(
                          MAIN_ROUTES.campaigns.path
                        ) &&
                          selectedCampaign?.id === campaign?.id)
                      }
                      icon={renderSourceIcon(campaign.campaignType)}
                      plans={MAIN_ROUTES.campaigns.plans}
                    >
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <ReelerTooltip text={`Show source content`}>
                          <span
                            className="text-truncate"
                            onClick={() => showCampaign(campaign)}
                          >
                            {campaign?.campaignName}
                          </span>
                        </ReelerTooltip>
                        <div className="d-flex flex-row align-items-center">
                          {campaign.campaignType ===
                            CAMPAIGN_TYPE.igHashtag.type &&
                          (campaign?.hashtag === undefined ||
                            !campaign?.access_token) ? (
                            <ReelerTooltip text="Action needed! Edit the source and verify the hashtag with Instagram.">
                              <FaExclamationCircle
                                style={{ color: "var(--reeler-danger)" }}
                              />
                            </ReelerTooltip>
                          ) : (
                            <CampaignNumberOfAssets campaignId={campaign.id} />
                          )}
                          <ReelerTooltip text="Edit source">
                            <FaEdit
                              onClick={() => editSource(campaign)}
                              className="icon-color icon-btn ml-2"
                            />
                          </ReelerTooltip>
                        </div>
                      </div>
                    </AuthBasedMenuItem>
                  ))}
              </AuthBasedSubMenu>
              <AuthBasedSubMenu
                active={
                  (location.pathname.includes(MAIN_ROUTES.feedsEditFeed.path) &&
                    selectedFeed?.id) ||
                  (location.pathname.includes(MAIN_ROUTES.feeds.path) &&
                    !selectedFeed)
                }
                open={subMenuStates?.feeds ? subMenuStates?.feeds : false}
                onOpenChange={openState => {
                  handleSubmenuStateChange("feeds", openState)
                }}
                icon={
                  selectedFeed ? (
                    <RenderIcon
                      icon={<MdViewCarousel size="1.3rem" />}
                      label="Feeds"
                    />
                  ) : (
                    <RenderIcon
                      icon={<MdViewCarousel size="1.3rem" />}
                      label="Feeds"
                    />
                  )
                }
                plans={MAIN_ROUTES.feeds.plans}
                label="Feeds"
              >
                <AuthBasedMenuItem
                  icon={<FaPlus size="0.75rem" />}
                  plans={MAIN_ROUTES.feeds.plans}
                >
                  <CreateFeed
                    btn={
                      <ReelerTooltip text="Add new feed">
                        <span>New feed</span>
                      </ReelerTooltip>
                    }
                  />
                </AuthBasedMenuItem>
                <AuthBasedMenuItem
                  active={
                    location.pathname.includes(MAIN_ROUTES.feeds.path) &&
                    !selectedFeed
                  }
                  plans={MAIN_ROUTES.feeds.plans}
                  icon={<MdViewCarousel size="1rem" />}
                  onClick={() => handleShowAllFeeds()}
                >
                  <strong>All feeds</strong>
                </AuthBasedMenuItem>
                {feeds?.map((feed, index) => (
                  <AuthBasedMenuItem
                    key={index}
                    active={
                      location.pathname.includes(
                        MAIN_ROUTES.feedsEditFeed.path
                      ) && selectedFeed?.id === feed.id
                    }
                    icon={
                      selectedFeed?.id === feed.id ? (
                        <FaChevronRight size="0.75rem" />
                      ) : (
                        <FaChevronRight size="0.75rem" />
                      )
                    }
                    plans={MAIN_ROUTES.feeds.plans}
                  >
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <ReelerTooltip text="Show feed">
                        <span
                          className="text-truncate"
                          onClick={() => editFeed(feed)}
                        >
                          {feed?.feedName}
                        </span>
                      </ReelerTooltip>
                      <div className="d-flex flex-row align-items-center">
                        <CountAssetsInFilter
                          filter={
                            feed?.tags && Object.keys(feed?.tags)?.length > 0
                              ? { tags: Object.keys(feed?.tags) }
                              : null
                          }
                        />
                        <ReelerTooltip text="Edit feed">
                          <FaEdit
                            onClick={() => editFeed(feed)}
                            className="icon-color icon-btn ml-2"
                          />
                        </ReelerTooltip>
                      </div>
                    </div>
                  </AuthBasedMenuItem>
                ))}
              </AuthBasedSubMenu>

              <AuthBasedMenuItem
                active={location.pathname.includes(MAIN_ROUTES.products.path)}
                plans={MAIN_ROUTES.products.plans}
                icon={
                  <RenderIcon
                    icon={<BsBoxFill size="1rem" />}
                    label="Products"
                  />
                }
                component={<Link to={MAIN_ROUTES.products.path} />}
              >
                Products
              </AuthBasedMenuItem>

              <AuthBasedSubMenu
                active={location.pathname.includes("/rights")}
                plans={MAIN_ROUTES.rightsApprovalPages.plans}
                icon={
                  <RenderIcon
                    icon={<FaFileSignature size="1rem" />}
                    label="Rights"
                  />
                }
                label="Rights"
                open={subMenuStates?.rights ? subMenuStates?.rights : false}
                onOpenChange={openState => {
                  handleSubmenuStateChange("rights", openState)
                }}
              >
                <AuthBasedMenuItem
                  active={location.pathname.includes(
                    MAIN_ROUTES.rightsApprovalPageTemplates.path
                  )}
                  plans={MAIN_ROUTES.rightsApprovalPageTemplates.plans}
                  component={
                    <Link to={MAIN_ROUTES.rightsApprovalPageTemplates.path} />
                  }
                >
                  Approval page templates
                </AuthBasedMenuItem>
                <AuthBasedMenuItem
                  active={location.pathname.includes(
                    MAIN_ROUTES.rightsMessageTemplates.path
                  )}
                  plans={MAIN_ROUTES.rightsMessageTemplates.plans}
                  component={
                    <Link to={MAIN_ROUTES.rightsMessageTemplates.path} />
                  }
                >
                  Instagram message templates
                </AuthBasedMenuItem>
                <AuthBasedMenuItem
                  active={location.pathname.includes(
                    MAIN_ROUTES.rightsEmailTemplates.path
                  )}
                  plans={MAIN_ROUTES.rightsEmailTemplates.plans}
                  component={
                    <Link to={MAIN_ROUTES.rightsEmailTemplates.path} />
                  }
                >
                  Email templates
                </AuthBasedMenuItem>

                <AuthBasedMenuItem
                  active={location.pathname.includes(
                    MAIN_ROUTES.rightsConsentHashtags.path
                  )}
                  plans={MAIN_ROUTES.rightsConsentHashtags.plans}
                  component={
                    <Link to={MAIN_ROUTES.rightsConsentHashtags.path} />
                  }
                >
                  Consent hashtags
                </AuthBasedMenuItem>
              </AuthBasedSubMenu>
            </Menu>
          </S.MenuSection>

          <S.MenuSection
            style={{
              height: "150px",
              borderTop: "1px solid var(--reeler-grey-dark)",
            }}
          >
            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                active={location.pathname.includes(MAIN_ROUTES.help.path)}
                icon={
                  <RenderIcon icon={<FaQuestion size="1rem" />} label="Help" />
                }
                component={<Link to={MAIN_ROUTES.help.path} />}
              >
                Help
              </MenuItem>
              <Submenu
                isCollapsed={isCollapsed}
                component={
                  <AuthBasedMenuItem
                    active={location.pathname.includes("/settings")}
                    icon={
                      <RenderIcon
                        icon={<FaCog size="1rem" />}
                        label="Settings"
                      />
                    }
                    plans={MAIN_ROUTES.settingsProfile.plans}
                  >
                    Settings
                  </AuthBasedMenuItem>
                }
              >
                <AuthBasedMenuItem
                  active={location.pathname.includes(
                    MAIN_ROUTES.settingsAccount.path
                  )}
                  icon={
                    <ReelerTooltip text="User details">
                      <FaBuilding size="1rem" />
                    </ReelerTooltip>
                  }
                  plans={MAIN_ROUTES.settingsAccount.plans}
                  component={<Link to={MAIN_ROUTES.settingsAccount.path} />}
                >
                  Account
                </AuthBasedMenuItem>
                <AuthBasedMenuItem
                  active={location.pathname.includes(
                    MAIN_ROUTES.settingsAccountUsers.path
                  )}
                  icon={
                    <ReelerTooltip text="User details">
                      <FaUsers size="1rem" />
                    </ReelerTooltip>
                  }
                  plans={MAIN_ROUTES.settingsAccountUsers.plans}
                  component={
                    <Link to={MAIN_ROUTES.settingsAccountUsers.path} />
                  }
                >
                  Users
                </AuthBasedMenuItem>
                <AuthBasedMenuItem
                  active={location.pathname.includes(
                    MAIN_ROUTES.settingsIntegration.path
                  )}
                  icon={
                    <ReelerTooltip text="User details">
                      <FaCogs size="1rem" />
                    </ReelerTooltip>
                  }
                  plans={MAIN_ROUTES.settingsIntegration.plans}
                  component={<Link to={MAIN_ROUTES.settingsIntegration.path} />}
                >
                  Integrations
                </AuthBasedMenuItem>
                <AuthBasedMenuItem
                  active={location.pathname.includes(
                    MAIN_ROUTES.settingsAccountTags.path
                  )}
                  icon={
                    <ReelerTooltip text="Account labels">
                      <FaTags size="1rem" />
                    </ReelerTooltip>
                  }
                  plans={MAIN_ROUTES.settingsAccountTags.plans}
                  component={<Link to={MAIN_ROUTES.settingsAccountTags.path} />}
                >
                  Account labels
                </AuthBasedMenuItem>
                <AuthBasedMenuItem
                  active={location.pathname.includes(
                    MAIN_ROUTES.settingsAccountTags.path
                  )}
                  icon={
                    <ReelerTooltip text="Account labels">
                      <FaTags size="1rem" />
                    </ReelerTooltip>
                  }
                  plans={MAIN_ROUTES.settingsAccountTags.plans}
                  component={<Link to={MAIN_ROUTES.settingsAccountTags.path} />}
                >
                  Account labels
                </AuthBasedMenuItem>
              </Submenu>
              <Submenu
                isCollapsed={isCollapsed}
                component={
                  <MenuItem
                    active={location.pathname.includes("/user/")}
                    icon={
                      <RenderIcon
                        icon={<FaUserCircle size="1rem" />}
                        label="User"
                      />
                    }
                  >
                    User
                  </MenuItem>
                }
              >
                <MenuItem
                  active={location.pathname.includes(
                    MAIN_ROUTES.settingsProfile.path
                  )}
                  icon={
                    <ReelerTooltip text="User details">
                      <FaEdit size="1rem" />
                    </ReelerTooltip>
                  }
                  component={<Link to={MAIN_ROUTES.settingsProfile.path} />}
                >
                  User details
                </MenuItem>
                <MenuItem
                  active={location.pathname.includes(
                    MAIN_ROUTES.settingsEmailNotification.path
                  )}
                  icon={
                    <ReelerTooltip text="Email notifications">
                      <FaBell size="1rem" />
                    </ReelerTooltip>
                  }
                  component={
                    <Link to={MAIN_ROUTES.settingsEmailNotification.path} />
                  }
                >
                  Notifications
                </MenuItem>
                <MenuItem
                  active={location.pathname.includes(
                    MAIN_ROUTES.settingsProfileSecurity.path
                  )}
                  icon={
                    <ReelerTooltip text="Profile security">
                      <FaLock size="1rem" />
                    </ReelerTooltip>
                  }
                  component={
                    <Link to={MAIN_ROUTES.settingsProfileSecurity.path} />
                  }
                >
                  Security
                </MenuItem>
                <MenuItem
                  icon={
                    <ReelerTooltip text="Log out">
                      <FiLogOut size="1rem" />
                    </ReelerTooltip>
                  }
                  onClick={() => handleLogout()}
                >
                  Log out
                </MenuItem>
              </Submenu>
            </Menu>
          </S.MenuSection>
        </div>
      </Sidebar>
    )
  )
}
