import React, { useState, useEffect } from "react"
import { Row, Col, Form } from "react-bootstrap"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectCampaign } from "../../../redux/campaign/campaign.selectors"
import {
  selectAccounts,
  selectAccountId,
} from "../../../redux/account/account.selectors"
import { saveCampaign } from "../../../redux/campaign/campaign.actions"
import { useHistory } from "react-router-dom"
import { database } from "../../../firebase"
import SpinnerComponent from "../../commons/SpinnerComponent"
import { FaRegCopy } from "react-icons/fa"
import CampaignTagsRedux from "../edit-campaign/components/CampaignTagsRedux"
import FadeAlert from "../../commons/FadeAlert/FadeAlert"
import DeleteCampaign from "../components/DeleteCampaign"
import ShowCampaigAssets from "../components/ShowCampaingAssets"
import Section from "../../../layouts/core/Section"
import { Header } from "../../../layouts/core/Header"
import Title from "../../../layouts/core/Title"
import Body from "../../../layouts/core/Body"
import SaveCampaign from "../../campaign/form-builder/form-generator/components/SaveCampaign"
import ReelerButton from "../../commons/ReelerButton"
import { MAIN_ROUTES } from "../../../constants/routes"
import SaveEmailCampaign from "./SaveEmailCampaign"

export default function EditEmailCampaignView() {
  const history = useHistory()
  const dispatch = useDispatch()
  const campaign = useSelector(selectCampaign)
  const [saving, setSaving] = useState(false)
  const [users, setUsers] = useState(null)
  const [deleting, setDeleting] = useState(false)
  const [loading] = useState(false)
  const [msg, setMsg] = useState()
  const account = useSelector(selectAccounts)
  const accountId = useSelector(selectAccountId)

  useEffect(() => {
    if (accountId) {
      database.users
        .where("accounts", "array-contains", accountId)
        .get()
        .then(querySnapshot => {
          let userArray = []
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            userArray.push(database.formatDoc(doc))
          })
          setUsers(userArray)
        })
    }
  }, [accountId])

  const copyUrl = () => {
    var r = document.createRange()
    r.selectNode(document.getElementById("campaign-link"))
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(r)
    document.execCommand("copy")
    window.getSelection().removeAllRanges()
  }

  if (loading) {
    return <SpinnerComponent size="lg" />
  }

  return (
    <Section>
      <Header>
        <Title>
          Edit {campaign.campaignName ? campaign.campaignName : null}
        </Title>
        <div className="d-flex align-items-center ml-auto">
          <div className="mr-2">
            <ReelerButton
              text="All sources"
              styleClass="btn-secondary"
              dispatch={() => history.push(MAIN_ROUTES.campaigns.path)}
            />
          </div>
          <div className="mr-2">
            <ShowCampaigAssets />
          </div>
          <SaveEmailCampaign />
        </div>
      </Header>
      <Body>
        <Form.Group controlId="campaign-name">
          <Row>
            <Col sm={4}>
              <Form.Label className="mb-0">Content Source name</Form.Label>
            </Col>
            <Col sm={8}>
              <Form.Control
                type="text"
                value={campaign.campaignName ? campaign.campaignName : ""}
                onChange={e => {
                  dispatch(
                    saveCampaign({
                      ...campaign,
                      campaignName: e.target.value,
                    })
                  )
                }}
                required
              />
              <Form.Text muted>For internal use only</Form.Text>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="campaign-email">
          <Row>
            <Col sm={4}>
              <Form.Label>Content source email</Form.Label>
            </Col>
            <Col sm={8}>
              <div className="d-flex flex-row align-items-center">
                <strong id="campaign-link">
                  {campaign.id}@campaign.reelertech.com
                </strong>

                <FaRegCopy
                  style={{ strokeWidth: "3" }}
                  className="icon-color ml-3 icon-btn"
                  onClick={() => copyUrl()}
                />
              </div>

              <Form.Text muted>
                Content sent to this email address will be saved in your Reeler
                account. The total message size limit, including the message
                itself and any number of attachments, is 30MB. Either
                communicate this Reeler email to your end-users as it is, or
                create your own email address that forwards to this Reeler
                address.
              </Form.Text>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="userId">
          <Row>
            <Col sm={4}>
              <Form.Label className="mb-0">Set source owner</Form.Label>
            </Col>
            <Col sm={8}>
              <Form.Control
                as="select"
                name="userId"
                value={campaign?.userId}
                onChange={e =>
                  dispatch(
                    saveCampaign({
                      ...campaign,
                      userId: e.target.value,
                    })
                  )
                }
              >
                {users?.map((user, key) => (
                  <option key={key} value={user.id}>
                    {user.email}
                  </option>
                ))}
              </Form.Control>
              <Form.Text muted>
                Set “Source Owner” to be able to easily find your content, if
                you are many people or many teams working in the same Reeler
                account.
              </Form.Text>
            </Col>
          </Row>
        </Form.Group>

        <h5 className="sub-title">Automatic email response</h5>
        <Form.Group controlId="email-response-switch">
          <Row>
            <Col sm={4}>
              <Form.Label htmlFor="custom-switch">
                Send automatic email response
              </Form.Label>
            </Col>
            <Col sm={8}>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Switch to turn on auto-response email"
                onChange={() =>
                  dispatch(
                    saveCampaign({
                      ...campaign,
                      sendAutomaticEmail: !campaign.sendAutomaticEmail,
                    })
                  )
                }
                checked={campaign.sendAutomaticEmail}
              />
              <Form.Text muted>
                When you recieve content from your users you can send an
                automatic response message.
              </Form.Text>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group controlId="success-message">
          <Row>
            <Col sm={4}>
              <Form.Label>Success message</Form.Label>
            </Col>
            <Col sm={8}>
              <Form.Control
                as="textarea"
                value={campaign.successMessage ? campaign.successMessage : ""}
                onChange={e => {
                  dispatch(
                    saveCampaign({
                      ...campaign,
                      successMessage: e.target.value,
                    })
                  )
                }}
                rows={3}
              />
              <Form.Text muted>
                Please write the text you want to appear in the auto-reply
                confirmation email. Be sure to include your organization name,
                and any contact information you’d like to include, since the
                auto-reply will be sent from noreply@reelertech.com
              </Form.Text>
            </Col>
          </Row>
        </Form.Group>

        <CampaignTagsRedux campaign={campaign} />

        <>
          {msg && <FadeAlert msg={msg} />}
          <div className="d-flex flex-row justify-content-end py-3 mb-5">
            <div className="mr-2">
              <DeleteCampaign />
            </div>
            <SaveEmailCampaign />
          </div>
        </>
      </Body>
    </Section>
  )
}
