import React, { useEffect, useState } from "react"
import { Row, Col, Form } from "react-bootstrap"
import ShortUniqueId from "short-unique-id"
import { database, functions } from "../../../../../../../firebase"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  selectActiveAsset,
  selectAssetRightsRequest,
} from "../../../../../../../redux/asset/asset.selectors"
import { selectAccounts } from "../../../../../../../redux/account/account.selectors"
import {
  clearAssetRightsRequest,
  setAssetRightsRequest,
  setCloseAssetRightsRequestModal,
  updateAsset,
} from "../../../../../../../redux/asset/asset.actions"

import {
  ASSET_RIGHT_STATUS,
  RIGHTS_EMAIL_CODES,
  RIGHTS_REQUEST_TYPE,
  RIGHT_REQUEST_STATUS,
} from "../../../../../../../constants"

import ReelerAccordation from "../../../../../../commons/ReelerAccordation"
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa"
import ReelerTooltip from "../../../../../../commons/ReelerTooltip/ReelerTooltip"
import ReelerIconButton from "../../../../../../commons/reelerIconButton/ReelerIconButton"
import CopyToClipboard from "../../../../../../commons/CopyToClipboard"
import SaveRightRequest from "./SaveRightRequest"
import ReelerButton from "../../../../../../commons/ReelerButton"
import useUserFeedbackMessage from "../../../../../../../hooks/useUserFeedbackMessage"
import withoutProperty from "../../../../../../../utils/withoutProperty"

const { randomUUID } = new ShortUniqueId({ length: 6 })

export default function NewThridPartyRightsRequest() {
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)
  const [isSendingEmail, setIsSendingEmail] = useState(false)
  const asset = useSelector(selectActiveAsset)
  const rightsRequest = useSelector(selectAssetRightsRequest)
  const account = useSelector(selectAccounts)
  const { setUserFeedbackMessage } = useUserFeedbackMessage()

  useEffect(() => {
    if (rightsRequest?.contactDetails?.email) {
      dispatch(
        setAssetRightsRequest({
          ...rightsRequest,
          recipients: [
            {
              email: rightsRequest?.contactDetails?.email,
            },
          ],
        })
      )
    }
  }, [rightsRequest?.contactDetails])

  const addRecipient = () => {
    let recipients
    if (rightsRequest.recipients) {
      recipients = rightsRequest.recipients
    } else recipients = []

    recipients.push({
      name: "",
      lastName: "",
      email: "",
    })

    dispatch(
      setAssetRightsRequest({
        ...rightsRequest,
        recipients,
      })
    )
  }

  const removeRecipient = index => {
    let recipients = rightsRequest?.recipients.filter((r, i) => i !== index)
    dispatch(
      setAssetRightsRequest({
        ...rightsRequest,
        recipients,
      })
    )
  }

  const handleChangeEmailTemplate = index => {
    console.log(index)
    let template = {
      ...account?.right_request_templates?.emailTemplates[index],
    }
    dispatch(
      setAssetRightsRequest({
        ...rightsRequest,
        emailTemplate: template,
      })
    )
  }

  const handleSendEmail = async email => {
    var handleSendEmail = functions.httpsCallable("handleSendEmail")

    const { data } = await handleSendEmail({
      email,
    })
    return data
  }

  const handleCreateNewRightsRequests = async sendEmail => {
    setSaving(true)

    // check if it is a third party rights request
    // if so, loop through rightsRequest.recipients
    // Create new rights request
    // If there is an email template, send email

    // third party rights request

    let promises = rightsRequest.recipients.map(recipient => {
      return new Promise(async (resolve, reject) => {
        const shortId = randomUUID()

        let rightsRequestDoc = {
          ...rightsRequest,
          contactDetails: recipient,
          approvalPage: rightsRequest?.approvalPage,
          accountId: account.id,
          assetId: asset.id,
          shortId,
          linkStatus: RIGHT_REQUEST_STATUS.created,
          approvalStatus: ASSET_RIGHT_STATUS.created,
          createdAt: database.getCurrentTimestamp(),
          asset,
          approvalStatusLog: [
            {
              status: ASSET_RIGHT_STATUS.created,
              note: "Third party request created",
              createdAt: Date.now(),
            },
          ],
          link:
            (rightsRequest?.approvalPage?.domain
              ? rightsRequest.approvalPage.domain
              : process.env.REACT_APP_FIREBASE_CREATOR_APP_URL) +
            "/" +
            shortId,
        }

        try {
          const doc = await database.rightRequests.add(rightsRequestDoc)

          let newDoc = { ...rightsRequestDoc, id: doc.id }

          if (sendEmail && rightsRequest?.emailTemplate) {
            const updatedDoc = await sendRightsRequestWithEmail(newDoc)
            return resolve(updatedDoc)
          } else {
            return resolve(newDoc)
          }
        } catch (err) {
          console.log(err)
          setSaving(false)
          reject()
        }
      })
    })

    Promise.all(promises)
      .then(result => {
        console.log(result)
        if (result.length > 1) {
          dispatch(setCloseAssetRightsRequestModal())

          setUserFeedbackMessage(
            `${result.length} new third party rights requests has been successfully created!`
          )
        } else {
          dispatch(setCloseAssetRightsRequestModal())
          setUserFeedbackMessage(
            `New third party rights request has been successfully created!`
          )
          setSaving(false)
        }
      })
      .catch(err => {
        console.log(err)
        setUserFeedbackMessage(
          "Something went wrong and we could not save the rights requests",
          "danger"
        )
        setSaving(false)
      })
  }

  const sendRightsRequestWithEmail = rightsRequest => {
    return new Promise(async (resolve, reject) => {
      let { emailTemplate, contactDetails, link, assetId } = rightsRequest
      let body = emailTemplate?.body

      let regex = new RegExp(RIGHTS_EMAIL_CODES.link, "g")
      body = body.replace(regex, link)

      let regexName = new RegExp(RIGHTS_EMAIL_CODES.approverFirstName, "g")
      if (contactDetails?.name) {
        body = body.replace(regexName, contactDetails?.name)
      } else {
        body = body.replace(regexName, "")
      }

      body = body.replace(/\r\n|\r|\n/g, "<br />")

      const email = {
        to: contactDetails?.email,
        from: {
          email: "noreply@reelertech.com",
          name: emailTemplate?.senderAlias
            ? emailTemplate?.senderAlias
            : "Reeler",
        },
        subject: emailTemplate?.subject,
        text: body,
        html: body,
      }

      try {
        await handleSendEmail(email)

        const approvalStatusLog = {
          approvalStatus: ASSET_RIGHT_STATUS.pending,
          approvalStatusLog: rightsRequest?.approvalStatusLog
            ? [
                ...rightsRequest?.approvalStatusLog,
                {
                  status: ASSET_RIGHT_STATUS.pending,
                  note: "Sent email through Reeler",
                  createdAt: Date.now(),
                },
              ]
            : [
                {
                  status: ASSET_RIGHT_STATUS.pending,
                  note: "Sent email through Reeler",
                  createdAt: Date.now(),
                },
              ],
        }

        database.rightRequests
          .doc(rightsRequest.id)
          .update(approvalStatusLog)
          .then(() => {
            const assetRef = database.assets.doc(assetId)
            if (rightsRequest.requestType === RIGHTS_REQUEST_TYPE.thirdParty) {
              assetRef
                .update({
                  thirdPartyApprovalStatus: ASSET_RIGHT_STATUS.pending,
                })
                .then(() => {
                  dispatch(
                    updateAsset({
                      ...asset,
                      thirdPartyApprovalStatus: ASSET_RIGHT_STATUS.pending,
                    })
                  )
                  setSaving(false)
                  resolve({
                    ...rightsRequest,
                    ...approvalStatusLog,
                  })
                })
            } else {
              assetRef
                .update({
                  status: ASSET_RIGHT_STATUS.pending,
                })
                .then(() => {
                  dispatch(
                    updateAsset({
                      ...asset,
                      status: ASSET_RIGHT_STATUS.pending,
                    })
                  )
                  setSaving(false)
                  resolve({
                    ...rightsRequest,
                    ...approvalStatusLog,
                  })
                })
            }
          })
          .catch(err => {
            console.log(err)
            setSaving(false)
            setUserFeedbackMessage(
              "Something went wrong when sending the rights request",
              "danger"
            )

            reject()
          })
      } catch (err) {
        console.log(err)
        setSaving(false)
        reject()
      }
    })
  }

  const handleSendEmailToRecipient = async () => {
    setIsSendingEmail(true)

    try {
      const updatedDoc = await sendRightsRequestWithEmail(rightsRequest)
      setUserFeedbackMessage(`Rights request email sent successfully!`)
      setIsSendingEmail(false)
    } catch (err) {
      console.log(err)
      setUserFeedbackMessage(
        "Something went wrong and we could not send rights request",
        "danger"
      )
      setIsSendingEmail(false)
    }
  }

  const handleSave = () => {
    if (rightsRequest?.id) {
      setSaving(true)
      // save existing
      let rightsRequestWithoutId = withoutProperty(rightsRequest, "id")

      database.rightRequests
        .doc(rightsRequest?.id)
        .update(rightsRequestWithoutId)
        .then(() => {
          setUserFeedbackMessage("Saved!")
          setSaving(false)
        })
        .catch(err => {
          console.log(err)
          setUserFeedbackMessage("Could not save rights request", "danger")
          setSaving(false)
        })
    }
  }

  const ContactDetails = () => {
    const [settings, setSettings] = useState(rightsRequest?.contactDetails)

    const handleUpdateContactDetails = (target, value) => {
      dispatch(
        setAssetRightsRequest({
          ...rightsRequest,
          contactDetails: {
            ...rightsRequest?.contactDetails,
            [target]: value,
          },
        })
      )
    }

    return (
      <Row style={{ marginBottom: "0.5rem" }}>
        <Col md={5} className="pr-1">
          <Form.Control
            type="email"
            size="sm"
            placeholder="Email"
            value={settings?.email}
            onChange={e => setSettings({ ...settings, email: e.target.value })}
            onBlur={e => handleUpdateContactDetails("email", e.target.value)}
          />
        </Col>
        <Col className="px-1">
          <Form.Control
            type="text"
            size="sm"
            placeholder="First name"
            value={settings?.name}
            onChange={e => setSettings({ ...settings, name: e.target.value })}
            onBlur={e => handleUpdateContactDetails("name", e.target.value)}
          />
        </Col>
        <Col className="pl-1">
          <Form.Control
            type="text"
            size="sm"
            className="mr-1"
            placeholder="Last Name"
            value={settings?.lastName}
            onChange={e =>
              setSettings({ ...settings, lastName: e.target.value })
            }
            onBlur={e => handleUpdateContactDetails("lastName", e.target.value)}
          />
        </Col>
      </Row>
    )
  }

  const Recipients = () => (
    <>
      <Row className="mb-3">
        <Col>
          {rightsRequest?.recipients?.map((recipient, index) => (
            <Recipient key={index} index={index} recipient={recipient} />
          ))}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="d-flex justify-content-center">
          <ReelerTooltip text="Add new recipient">
            <ReelerIconButton
              small={true}
              text="Add more recipients"
              icon={<FaPlusCircle className="icon-color" />}
              dispatch={() => addRecipient()}
            />
          </ReelerTooltip>
        </Col>
      </Row>
    </>
  )

  const Recipient = ({ index, recipient }) => {
    const [settings, setSettings] = useState(recipient)

    const handleChange = (index, field, value) => {
      let recipients = rightsRequest.recipients.map((recipient, i) => {
        if (i === index) {
          return { ...recipient, [field]: value }
        } else {
          return recipient
        }
      })
      dispatch(
        setAssetRightsRequest({
          ...rightsRequest,
          recipients: recipients,
        })
      )
    }
    return (
      <Row style={{ marginBottom: "0.5rem" }}>
        <Col md={5} className="pr-1">
          <Form.Control
            type="email"
            size="sm"
            placeholder="Email"
            value={settings?.email}
            onChange={e => setSettings({ ...settings, email: e.target.value })}
            onBlur={e => handleChange(index, "email", e.target.value)}
          />
        </Col>
        <Col className="px-1">
          <Form.Control
            type="text"
            size="sm"
            placeholder="First name"
            value={settings?.name}
            onChange={e => setSettings({ ...settings, name: e.target.value })}
            onBlur={e => handleChange(index, "name", e.target.value)}
          />
        </Col>
        <Col className="pl-1">
          <Form.Control
            type="text"
            size="sm"
            className="mr-1"
            placeholder="Last Name"
            value={settings?.lastName}
            onChange={e =>
              setSettings({ ...settings, lastName: e.target.value })
            }
            onBlur={e => handleChange(index, "lastName", e.target.value)}
          />
        </Col>

        <Col
          md={1}
          className="d-flex justify-content-center align-items-center pl-1 pr-4"
        >
          {!rightsRequest?.id ? (
            <ReelerTooltip text={`Clear/Remove recipient`}>
              <FaTrashAlt
                onClick={() => removeRecipient(index)}
                className="icon-color icon-btn"
              />
            </ReelerTooltip>
          ) : null}
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col>
              <Form.Label>
                Register third party information (optional and internal)
              </Form.Label>
            </Col>
          </Row>

          {rightsRequest?.id ? <ContactDetails /> : <Recipients />}

          <Row className="mb-3">
            <Col>
              <div
                style={{
                  margin: "0 0 0 1rem",
                  padding: "0rem 1rem",
                  border: "1px solid var(--reeler-grey-dark)",
                }}
              >
                <ReelerAccordation
                  visible={false}
                  title={
                    <span
                      style={{
                        fontSize: "1rem",
                        color: "var(--reeler-title)",
                      }}
                    >
                      Add internal note (optional)
                    </span>
                  }
                >
                  <Form.Group className="mb-3">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={rightsRequest?.note}
                      onChange={e =>
                        dispatch(
                          setAssetRightsRequest({
                            ...rightsRequest,
                            note: e.target.value,
                          })
                        )
                      }
                    />

                    <Form.Text className="text-muted">
                      Add an internal note.
                    </Form.Text>
                  </Form.Group>
                </ReelerAccordation>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col className="pb-3">
          <div
            style={{
              margin: "0 0 0 1rem",
              padding: "0rem 1rem",
              border: "1px solid var(--reeler-grey-dark)",
            }}
          >
            <ReelerAccordation
              visible={false}
              title={
                <span
                  style={{
                    fontSize: "1rem",
                    color: "var(--reeler-title)",
                  }}
                >
                  Send email to recipient/s (optional)
                </span>
              }
            >
              <Form.Group>
                <Form.Label>Select email template (optional)</Form.Label>
                <Form.Control
                  as="select"
                  name="emailTemplate"
                  onChange={e => handleChangeEmailTemplate(e.target.value)}
                >
                  <option>-</option>
                  {account?.right_request_templates?.emailTemplates?.map(
                    (emailTemplate, index) => (
                      <option key={index} value={index}>
                        {`${index + 1}: ${emailTemplate.emailTemplateName}`}
                      </option>
                    )
                  )}
                </Form.Control>
              </Form.Group>
              <>
                {/* <Form.Group>
                    <Form.Label>Recipients email</Form.Label>

                    <Form.Control
                      type="email"
                      value={rightsRequest?.recipients
                        ?.map(recipient => recipient.email)
                        .toString()}
                    />
                    <Form.Text muted>
                      Add or remove email by managing recipients
                    </Form.Text>
                  </Form.Group> */}

                <Form.Group>
                  <Form.Label>Email sender alias</Form.Label>

                  <Form.Control
                    type="text"
                    value={rightsRequest?.emailTemplate?.senderAlias}
                    onChange={e =>
                      dispatch(
                        setAssetRightsRequest({
                          ...rightsRequest,
                          emailTemplate: {
                            ...rightsRequest.emailTemplate,
                            senderAlias: e.target.value,
                          },
                        })
                      )
                    }
                  />
                  <Form.Text muted>
                    Add an email sender alias that will be shown in the email
                    client
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email subject</Form.Label>
                  <Form.Control
                    type="text"
                    value={rightsRequest?.emailTemplate?.subject}
                    onChange={e =>
                      dispatch(
                        setAssetRightsRequest({
                          ...rightsRequest,
                          emailTemplate: {
                            ...rightsRequest.emailTemplate,
                            subject: e.target.value,
                          },
                        })
                      )
                    }
                  />
                  <Form.Text muted>What should the email subject be?</Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email body</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={rightsRequest?.emailTemplate?.body}
                    onChange={e =>
                      dispatch(
                        setAssetRightsRequest({
                          ...rightsRequest,
                          emailTemplate: {
                            ...rightsRequest.emailTemplate,
                            body: e.target.value,
                          },
                        })
                      )
                    }
                  />

                  <Form.Text muted>
                    Choose one of your email templates, then tweak or
                    personalize your text as you’d like for this individual.
                    Alternatively, you can write a completely new email. Ensure
                    that your final email text includes the element{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {RIGHTS_EMAIL_CODES.link}
                    </span>{" "}
                    <CopyToClipboard
                      link={RIGHTS_EMAIL_CODES.link}
                      tooltipText="Copy link code and past in the email body."
                    />{" "}
                    this is where the link to the recipient’s personal approval
                    page will be automatically inserted when you send the email.
                    If you want to personalize the email include{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {RIGHTS_EMAIL_CODES.approverFirstName}
                    </span>{" "}
                    <CopyToClipboard
                      link={RIGHTS_EMAIL_CODES.approverFirstName}
                      tooltipText="Copy name code and past in the email body."
                    />{" "}
                    to use the recipients first name in the contact details.
                  </Form.Text>
                </Form.Group>
              </>
            </ReelerAccordation>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="d-flex">
            {!rightsRequest?.id ? (
              <Row>
                <Col>
                  <ReelerButton
                    loading={saving}
                    disabled={!rightsRequest?.approvalPage || saving}
                    dispatch={() => handleCreateNewRightsRequests(false)}
                    text="Save without sending email"
                    styleClass="btn-main mr-3"
                    spinnerClass="ml-1"
                  />
                  <ReelerButton
                    loading={saving}
                    disabled={
                      !rightsRequest?.approvalPage ||
                      !rightsRequest?.emailTemplate ||
                      !(rightsRequest?.recipients?.length > 0) ||
                      saving
                    }
                    dispatch={() => handleCreateNewRightsRequests(true)}
                    text="Save and send email"
                    styleClass="btn-main"
                    spinnerClass="ml-1"
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <ReelerButton
                    loading={saving}
                    disabled={saving}
                    dispatch={() => handleSave()}
                    text="Save"
                    styleClass="btn-main mr-3"
                  />
                  <ReelerButton
                    loading={isSendingEmail}
                    disabled={
                      !rightsRequest?.approvalPage ||
                      !rightsRequest?.emailTemplate ||
                      !(rightsRequest?.recipients?.length > 0) ||
                      isSendingEmail
                    }
                    dispatch={() => handleSendEmailToRecipient()}
                    text="Send email"
                    styleClass="btn-main mr-3"
                    spinnerClass="ml-1"
                  />
                </Col>
              </Row>
            )}
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}
