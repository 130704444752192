import React, { useState, useEffect } from "react"

// 3rd-party libraries
import { BiCameraOff } from "react-icons/bi"
import { MEDIA_TYPE } from "../../constants"

import { checkImage } from "../../utils/checkImage"
import Skeleton from "../commons/skeleton/Skeleton"

export default function MediaType({
  asset,
  br,
  imgSize,
  showVideoControls = true,
  background = "var(--reeler-white)",
  objectFit = "contain",
  aspectRatio = "auto",
}) {
  const [loading, setLoading] = useState(true)
  const [url, setUrl] = useState(asset.url)
  const [type, setType] = useState(asset?.type ? asset?.type : null)

  useEffect(() => {
    // if we find a main version, then set its values, otherwise use old values in asset.
    if (asset) {
      if (asset?.assetVersions?.length > 0) {
        let mainVersion = asset?.assetVersions.filter(
          version => version.main === true
        )
        if (mainVersion.length > 0) {
          setType(mainVersion[0].type)

          if (
            mainVersion[0].access_token &&
            mainVersion[0].file_path &&
            mainVersion[0].file_name &&
            asset.media_type === MEDIA_TYPE.IMAGE &&
            imgSize
          ) {
            let tempUrl =
              process.env.REACT_APP_FIREBASE_STORAGE_BASE_URL +
              encodeURIComponent(
                mainVersion[0].file_path + "image@" + imgSize + "_"
              ) +
              mainVersion[0].file_name +
              "?alt=media&token=" +
              mainVersion[0].access_token

            // Check if imgage exists and is done with its transformation in the backend
            checkImage(tempUrl).then(result => {
              if (result.status === "ok") {
                setUrl(tempUrl)
                setLoading(false)
              } else {
                setUrl(mainVersion[0].url)
                setLoading(false)
              }
            })
          } else {
            setUrl(mainVersion[0].url)
            setLoading(false)
          }
        }
      } else {
        setUrl(asset?.url)
        setLoading(false)
      }
    }
  }, [asset])

  // Fetch main version

  const Unsupported = () => (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: background,
        borderRadius: br,
      }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <BiCameraOff size={25} />
      <span style={{ margin: "0 0.5rem", textAlign: "center" }}>
        {asset.media_type === MEDIA_TYPE.TEXT
          ? "Text only"
          : "Unsupported Format"}
      </span>
    </div>
  )

  const renderMedia = type => {
    switch (type) {
      case "video/mp4":
        return (
          <video
            width="100%"
            height="100%"
            src={url ? url : url}
            type="video/mp4"
            controls={showVideoControls}
            style={{
              background: background,
              borderRadius: br,
            }}
          ></video>
        )
        break
      case "video/quicktime":
        return (
          <video
            width="100%"
            height="100%"
            src={asset.cloudinary ? asset.cloudinary.eager[0].secure_url : url}
            type="video/mp4"
            controls={showVideoControls}
            style={{
              background: background,
              borderRadius: br,
            }}
          ></video>
        )
        break
      case "image/jpeg":
      case "image/jpg":
      case "image/png":
        return (
          <img
            width="100%"
            height="100%"
            src={url}
            style={{
              objectFit: objectFit,
              background: background,
              borderRadius: br,
              display: "block",
              aspectRatio: aspectRatio,
              overflow: "hidden",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            alt=""
          />
        )

        break
      case "audio/mpeg":
      case "audio/ogg":
      case "audio/wav":
      case "audio/x-m4a":
        return (
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <audio controls>
              <source src={url} type={type} />
              <p>Your browser doesn't support HTML5 audio. </p>
            </audio>
          </div>
        )

        break
      default:
        return <Unsupported />
    }
  }

  if (loading) return <Skeleton />

  return type ? renderMedia(type) : <Unsupported />
}
