import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectAccounts } from "../../../../redux/account/account.selectors"
import { updateAccounts } from "../../../../redux/account/account.actions"
import { database } from "../../../../firebase"
import parse from "html-react-parser"
import { Container, Form, Row, Col } from "react-bootstrap"
import SpinnerComponent from "../../../commons/SpinnerComponent"
import { FaEdit, FaExclamationCircle, FaTrashAlt } from "react-icons/fa"
import ReelerButton from "../../../commons/ReelerButton"
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"
import FadeAlert from "../../../commons/FadeAlert/FadeAlert"

export default function TextMessageTemplates() {
  const dispatch = useDispatch()
  const account = useSelector(selectAccounts)
  const [loading, setLoading] = useState(false)
  const [textMessage, setTextMessage] = useState(null)
  const [templateName, setTemplateName] = useState(null)
  const [editTextMessage, setEditTextMessage] = useState(null)
  const [msg, setMsg] = useState(null)

  const handleAddTextMessage = () => {
    setLoading(true)
    setMsg(null)
    const newTextMessage = {
      templateName: templateName,
      textMessage: textMessage,
      createdAt: new Date(),
    }

    let textMessages
    if (account?.right_request_templates?.textMessages) {
      textMessages = [
        ...account?.right_request_templates?.textMessages,
        newTextMessage,
      ]
    } else {
      textMessages = [newTextMessage]
    }
    database.accounts
      .doc(account.id)
      .update({
        "right_request_templates.textMessages": textMessages,
      })
      .then(() => {
        dispatch(
          updateAccounts({
            right_request_templates: {
              ...account.right_request_templates,
              textMessages: textMessages,
            },
          })
        )

        setTextMessage(null)
        setMsg({ success: true, text: "Saved!" })

        setLoading(false)
      })
  }

  const removeTextMessage = index => {
    setLoading(true)

    let updatedTextMessageArr =
      account?.right_request_templates.textMessages.filter(
        (comment, i) => i !== index
      )
    database.accounts
      .doc(account.id)
      .update({
        "right_request_templates.textMessages": updatedTextMessageArr,
      })
      .then(() => {
        dispatch(
          updateAccounts({
            right_request_templates: {
              ...account.right_request_templates,
              textMessages: updatedTextMessageArr,
            },
          })
        )
        setMsg({ success: false, text: "Removed!" })

        setLoading(false)
      })
  }

  const handleSaveEditTextMessage = () => {
    setLoading(true)

    let updatedTextMessageArr =
      account?.right_request_templates.textMessages.map((message, i) => {
        if (i === editTextMessage.index) {
          return {
            ...message,
            templateName: editTextMessage.templateName,
            textMessage: editTextMessage.textMessage,
          }
        }
        return message
      })
    database.accounts
      .doc(account.id)
      .update({
        "right_request_templates.textMessages": updatedTextMessageArr,
      })
      .then(() => {
        dispatch(
          updateAccounts({
            right_request_templates: {
              ...account.right_request_templates,
              textMessages: updatedTextMessageArr,
            },
          })
        )

        setLoading(false)
        setEditTextMessage(null)
        setMsg({ success: true, text: "Saved!" })
      })
  }

  return !account ? (
    <SpinnerComponent size="sm" />
  ) : (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={10}>
          <h6 className="mr-3">Text message templates</h6>

          <Form.Group>
            <Form.Text>
              Write one or several texts for social media messages where you ask
              end-users to visit an “approval page” to grant you permission for
              the use of content. The typical case would be a text that you post
              on Instagram as a comment or send as a direct message to the
              content creator. You will be able to tweak or personalize the
              message text each time you post it.
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={8} sm={12}>
          {msg && <FadeAlert msg={msg} />}

          <Form.Group controlId="predefine-rights-comment">
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>1. Template name</Form.Label>
                  <Form.Control
                    type="text"
                    value={templateName}
                    onChange={e => setTemplateName(e.target.value)}
                  />
                  <Form.Text className="text-muted">
                    Give this template an internal name.
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Text message template</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={textMessage ? textMessage : ""}
                    placeholder=""
                    onChange={e => setTextMessage(e.target.value)}
                    rows={3}
                    style={{ fontSize: "12px" }}
                  />
                  <Form.Text muted>
                    E.g., ‘Hello, we love your photo. Would you approve of us
                    featuring it on our company’s marketing channels?’ The link
                    to the approval page will automatically be added at the end
                    of your message.
                  </Form.Text>
                </Form.Group>

                <div className="d-flex flex-row justify-content-end">
                  <ReelerButton
                    text="Save message"
                    styleClass="btn-main"
                    loading={loading}
                    disabled={textMessage?.length > 2 ? false : true}
                    dispatch={() => handleAddTextMessage()}
                  />
                </div>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="">
            <Form.Label>
              Saved text messages (
              {account?.right_request_templates?.textMessages
                ? account?.right_request_templates?.textMessages?.length
                : "0"}
              )
            </Form.Label>

            {account?.right_request_templates?.textMessages?.map(
              (message, index) => (
                <div
                  key={index}
                  className="d-flex flex-row justify-content-between align-items-center"
                  style={{
                    marginBottom: " 10px",
                    padding: "10px",
                    background: "var(--reeler-grey)",
                    borderRadius: "3px",
                  }}
                >
                  {editTextMessage?.index === index ? (
                    <div className="d-flex flex-column w-100 pr-3">
                      <small>{`${index + 1}:`}</small>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          value={editTextMessage.templateName}
                          onChange={e =>
                            setEditTextMessage({
                              ...editTextMessage,
                              templateName: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="edit-rights-comment"
                        className="w-100 mr-3"
                      >
                        <Form.Control
                          as="textarea"
                          style={{ fontSize: "12px" }}
                          value={editTextMessage.textMessage}
                          onChange={e =>
                            setEditTextMessage({
                              ...editTextMessage,
                              textMessage: e.target.value,
                            })
                          }
                          rows={3}
                        />

                        <div className="d-flex flex-row justify-content-end pt-3">
                          <ReelerButton
                            text="Close"
                            styleClass="btn-secondary"
                            dispatch={() => setEditTextMessage(null)}
                          />
                          <ReelerButton
                            text="Save"
                            styleClass="btn-main ml-3"
                            loading={loading}
                            disabled={
                              editTextMessage?.textMessage?.length > 2
                                ? false
                                : true
                            }
                            dispatch={() => handleSaveEditTextMessage()}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  ) : (
                    <small>{`${index + 1}: ${message?.textMessage}`}</small>
                  )}

                  <div className="d-flex flex-row">
                    <ReelerTooltip text={`Edit comment`}>
                      <FaEdit
                        onClick={() =>
                          setEditTextMessage({
                            index: index,
                            templateName: message?.templateName,
                            textMessage: message?.textMessage,
                          })
                        }
                        size={14}
                        className="icon-color"
                        style={{ cursor: "pointer" }}
                      />
                    </ReelerTooltip>
                    <ReelerTooltip text={`Delete comment`}>
                      <FaTrashAlt
                        onClick={() => removeTextMessage(index)}
                        size={14}
                        className="icon-color ml-3"
                        style={{ cursor: "pointer" }}
                      />
                    </ReelerTooltip>
                  </div>
                </div>
              )
            )}
          </Form.Group>
        </Col>
      </Row>
    </Container>
  )
}
