import React from "react"
import { Form, Row, Col } from "react-bootstrap"

import { useDispatch, useSelector } from "react-redux"
import { selectFeed } from "../../../../../../redux/feed/feed.selectors"
import { updateFeed } from "../../../../../../redux/feed/feed.actions"
import UnitInputGroup from "../../../../../commons/UnitInputGroup/UnitInputGroup"

export default function CardSettings() {
  const feed = useSelector(selectFeed)
  const dispatch = useDispatch()

  const handleChange = ({ target: { name, value, type } }) => {
    if (type === "number") {
      dispatch(
        updateFeed({
          feedSettings: { ...feed.feedSettings, [name]: Number(value) },
        })
      )
    } else {
      dispatch(
        updateFeed({ feedSettings: { ...feed.feedSettings, [name]: value } })
      )
    }
  }

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="border-radius">
            <UnitInputGroup
              label="Border radius"
              name="borderRadius"
              min={0}
              max={50}
              value={
                feed?.feedSettings?.borderRadius
                  ? feed?.feedSettings?.borderRadius
                  : Number()
              }
              dispatch={handleChange}
              unit="px"
            />
            <Form.Text muted>Roundness of corners</Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="show-social-media-icon"
                checked={feed.feedSettings?.socialMediaIcon}
                name="show-social-media-icon"
                onChange={() =>
                  dispatch(
                    updateFeed({
                      feedSettings: {
                        ...feed.feedSettings,
                        socialMediaIcon: !feed.feedSettings?.socialMediaIcon,
                      },
                    })
                  )
                }
              />
              <label
                className="custom-control-label"
                htmlFor="show-social-media-icon"
              >
                Show social media icon
              </label>
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="show-popup"
                checked={feed.feedSettings?.showFeedModal}
                name="show-popup"
                onChange={() =>
                  dispatch(
                    updateFeed({
                      feedSettings: {
                        ...feed.feedSettings,
                        showFeedModal: !feed.feedSettings?.showFeedModal,
                        showProductInfoInModal: false,
                        linkToProductUrl: false,
                      },
                    })
                  )
                }
              />
              <label className="custom-control-label" htmlFor="show-popup">
                Open popup window
              </label>
            </div>
          </Form.Group>
          {feed.feedSettings?.showFeedModal ? (
            <div className="ml-3">
              <Form.Group>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="show-caption-in-modal"
                    checked={feed.feedSettings?.showCaptionInModal}
                    name="show-caption-in-modal"
                    onChange={() =>
                      dispatch(
                        updateFeed({
                          feedSettings: {
                            ...feed.feedSettings,
                            showCaptionInModal:
                              !feed.feedSettings?.showCaptionInModal,
                          },
                        })
                      )
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="show-caption-in-modal"
                  >
                    Show edited caption in popup window
                  </label>
                </div>
              </Form.Group>
              <Form.Group>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="show-product-info"
                    checked={feed.feedSettings?.showProductInfoInModal}
                    name="show-product-info"
                    onChange={() =>
                      dispatch(
                        updateFeed({
                          feedSettings: {
                            ...feed.feedSettings,
                            showProductInfoInModal:
                              !feed.feedSettings?.showProductInfoInModal,
                          },
                        })
                      )
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="show-product-info"
                  >
                    Show product information in popup window
                  </label>
                </div>
              </Form.Group>
              {feed.feedSettings?.showProductInfoInModal ? (
                <div className="ml-3">
                  <Form.Group controlId="feed-product-heading">
                    <Form.Label>Add an intro heading</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={feed?.feedSettings?.productHeading}
                      onChange={e =>
                        dispatch(
                          updateFeed({
                            feedSettings: {
                              ...feed.feedSettings,
                              productHeading: e.target.value,
                            },
                          })
                        )
                      }
                    />

                    <Form.Text muted>
                      Add a heading above the product list. If left empypty no
                      heading will be shown.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="feed-product-button-text">
                    <Form.Label>Add a product button text</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={feed?.feedSettings?.productButtonText}
                      onChange={e =>
                        dispatch(
                          updateFeed({
                            feedSettings: {
                              ...feed.feedSettings,
                              productButtonText: e.target.value,
                            },
                          })
                        )
                      }
                    />

                    <Form.Text muted>
                      Add a product button text. If left empty no button will be
                      shown.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="show-product-info-show-description"
                        checked={
                          feed.feedSettings?.showProductDescriptionInModal
                        }
                        name="show-product-info-show-description"
                        onChange={() =>
                          dispatch(
                            updateFeed({
                              feedSettings: {
                                ...feed.feedSettings,
                                showProductDescriptionInModal:
                                  !feed.feedSettings
                                    ?.showProductDescriptionInModal,
                              },
                            })
                          )
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="show-product-info-show-description"
                      >
                        Show product description in popup window
                      </label>
                    </div>
                  </Form.Group>
                </div>
              ) : null}
            </div>
          ) : null}
          {!feed.feedSettings?.showFeedModal ? (
            <Form.Group>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="link-to-product-url"
                  checked={feed.feedSettings?.linkToProductUrl}
                  name="link-to-product-url"
                  onChange={() =>
                    dispatch(
                      updateFeed({
                        feedSettings: {
                          ...feed.feedSettings,
                          linkToProductUrl:
                            !feed.feedSettings?.linkToProductUrl,
                        },
                      })
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="link-to-product-url"
                >
                  Link to product url on click
                </label>
              </div>
            </Form.Group>
          ) : null}
        </Col>
      </Row>
    </>
  )
}
