import React, { useState, useRef } from "react"

import { database, functions } from "../../../../firebase"

import { useSelector, useDispatch } from "react-redux"

import { selectAccounts } from "../../../../redux/account/account.selectors"
import { selectCurrentUser } from "../../../../redux/user/user.selectors"
import { updateAccounts } from "../../../../redux/account/account.actions"
import { Container, Form, Row, Col } from "react-bootstrap"
import SpinnerComponent from "../../../commons/SpinnerComponent"
import ReelerButton from "../../../commons/ReelerButton"
import FadeAlert from "../../../commons/FadeAlert/FadeAlert"
import { RIGHTS_EMAIL_CODES } from "../../../../constants"
import CopyToClipboard from "../../../commons/CopyToClipboard"

let defaultValues = {
  emailTemplateName: "",
  senderAlias: "",
  subject: "",
  body: "",
}

export default function EmailTemplates() {
  const [saving, setSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [msg, setMsg] = useState(null)
  const [sendingEmail, setSendingEmail] = useState(false)
  const [emailTemplate, setEmailTemplate] = useState(null)
  const [index, setIndex] = useState(null)
  const dispatch = useDispatch()
  const account = useSelector(selectAccounts)
  const user = useSelector(selectCurrentUser)
  const selectEmailTemplateRef = useRef()

  const handleSendEmail = async email => {
    var handleSendEmail = functions.httpsCallable("handleSendEmail")

    const { data } = await handleSendEmail({
      email,
    })
    return data
  }

  const handleSendTestEmail = async () => {
    setSendingEmail(true)
    setMsg(null)
    const email = {
      to: user.email,
      from: {
        email: "noreply@reelertech.com",
        name: emailTemplate?.senderAlias,
      },
      subject: emailTemplate?.subject,
      text: emailTemplate?.body.replace(/\r\n|\r|\n/g, "<br />"),
      html: emailTemplate?.body.replace(/\r\n|\r|\n/g, "<br />"),
    }
    console.log(email)
    try {
      await handleSendEmail(email)
      setSendingEmail(false)
      setMsg({ success: true, text: "Email sent!" })
    } catch (err) {
      setSendingEmail(false)
      console.log(err)
    }
  }

  const handleSubmit = () => {
    setSaving(true)
    setMsg(null)
    let emailTemplates
    if (index) {
      emailTemplates = account?.right_request_templates?.emailTemplates.map(
        (t, i) => (i === parseInt(index) ? emailTemplate : t)
      )
    } else {
      if (account?.right_request_templates?.emailTemplates) {
        emailTemplates = [
          ...account?.right_request_templates?.emailTemplates,
          emailTemplate,
        ]
        setIndex(emailTemplate.length - 1)
      } else {
        emailTemplates = [emailTemplate]
        setIndex(0)
      }
    }

    database.accounts
      .doc(account.id)
      .update({
        "right_request_templates.emailTemplates": emailTemplates,
      })
      .then(() => {
        //Update accounts in redux
        dispatch(
          updateAccounts({
            right_request_templates: {
              ...account?.right_request_templates,
              emailTemplates: emailTemplates,
            },
          })
        )
        setSaving(false)
        setMsg({ success: true, text: "Saved!" })
      })
      .catch(err => {
        console.log(err)
        setSaving(false)
      })
  }

  const deleteEmailTemplate = () => {
    setIsDeleting(true)
    let emailTemplates = account?.right_request_templates?.emailTemplates
    emailTemplates.splice(index, 1)

    database.accounts
      .doc(account.id)
      .update({
        "right_request_templates.emailTemplates": emailTemplates,
      })
      .then(() => {
        //Update accounts in redux
        dispatch(
          updateAccounts({
            right_request_templates: {
              ...account?.right_request_templates,
              emailTemplates: emailTemplates,
            },
          })
        )
        setIndex(null)
        setEmailTemplate(null)
        setMsg({ success: false, text: "Template deleted!" })
        setIsDeleting(false)
      })
      .catch(err => {
        console.log(err)
        setSaving(false)
      })
  }

  const handleSelectEmailTemplate = value => {
    setEmailTemplate(account?.right_request_templates?.emailTemplates[value])
    setIndex(value)
  }

  const handleCreateNewTemplate = () => {
    setIndex(null)
    selectEmailTemplateRef.current.value = null
    setEmailTemplate(defaultValues)
  }

  return !account ? (
    <SpinnerComponent size="sm" />
  ) : (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={10}>
          <h6 className="mr-3">Email templates</h6>

          <Form.Group>
            <Form.Text>
              Write one or several email templates that you will send to
              end-users, asking them to visit an “approval page” to grant you
              permission for the use of content. For instance, you could create
              one email template for content creators (granting permission to
              use their work) and another email template for 3rd parties
              (granting permission for the use of images where they appear). At
              the moment of sending an email, you define if it’s a creator or a
              3rd party you are emailing to, and which email template to use.
              You will be able to tweak or personalize the email text each time
              you send it.
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3 mt-1 justify-content-center">
        <Col
          md={10}
          className="d-flex flex-row justify-content-center align-items-center"
        >
          <ReelerButton
            dispatch={() => handleCreateNewTemplate()}
            text="Create new template"
            styleClass="btn-main"
          />
          <span className="mx-3">or</span>
          <Form.Control
            ref={selectEmailTemplateRef}
            as="select"
            name="textMessage"
            style={{ width: "250px" }}
            onChange={e => handleSelectEmailTemplate(e.target.value)}
          >
            <option>Edit templates</option>
            {account?.right_request_templates?.emailTemplates?.map(
              (emailTemplate, index) => (
                <option key={index} value={index}>
                  {`${index + 1}: ${emailTemplate.emailTemplateName}`}
                </option>
              )
            )}
          </Form.Control>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={8} sm={12}>
          {emailTemplate ? (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Template name</Form.Label>
                <Form.Control
                  type="text"
                  value={emailTemplate?.emailTemplateName}
                  onChange={e =>
                    setEmailTemplate({
                      ...emailTemplate,
                      emailTemplateName: e.target.value,
                    })
                  }
                />
                <Form.Text muted>
                  Give this email template an internal name.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email sender alias</Form.Label>
                <Form.Control
                  type="text"
                  value={emailTemplate?.senderAlias}
                  onChange={e =>
                    setEmailTemplate({
                      ...emailTemplate,

                      senderAlias: e.target.value,
                    })
                  }
                />
                <Form.Text muted>
                  How your email sender name should be displayed in the email
                  client
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email subject</Form.Label>
                <Form.Control
                  type="text"
                  value={emailTemplate?.subject}
                  onChange={e =>
                    setEmailTemplate({
                      ...emailTemplate,

                      subject: e.target.value,
                    })
                  }
                />
                <Form.Text muted>
                  Write an email subject for this email template.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email body</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={10}
                  value={emailTemplate?.body}
                  onChange={e =>
                    setEmailTemplate({
                      ...emailTemplate,

                      body: e.target.value,
                    })
                  }
                />

                <Form.Text muted>
                  Write the email body text for this template. Make sure to
                  include the code{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {RIGHTS_EMAIL_CODES.link}
                  </span>{" "}
                  <CopyToClipboard
                    link={RIGHTS_EMAIL_CODES.link}
                    tooltipText="Copy link code and past in the email body."
                  />{" "}
                  somewhere in your text, where you want the link to the
                  approval page to be inserted. To make your email easier to
                  read, you can include empty lines between the paragraphs. You
                  will be able to personalize or tweak the template text each
                  time you send a rights request by email. Add{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {RIGHTS_EMAIL_CODES.approverFirstName}
                  </span>{" "}
                  <CopyToClipboard
                    link={RIGHTS_EMAIL_CODES.approverFirstName}
                    tooltipText="Copy name code and past in the email body."
                  />{" "}
                  if you want to personalize the email to use the first name in
                  the contact details section of the rights request.
                </Form.Text>
              </Form.Group>
              <Row>
                <Col>
                  {msg && <FadeAlert msg={msg} />}

                  <Form.Group className="d-flex justify-content-end">
                    <ReelerButton
                      loading={sendingEmail}
                      disabled={sendingEmail}
                      dispatch={() => handleSendTestEmail()}
                      text="Send test email to yourself"
                      styleClass="btn-secondary mr-3"
                      spinnerClass="ml-1"
                    />
                    <ReelerButton
                      loading={isDeleting}
                      disabled={!index}
                      dispatch={() => deleteEmailTemplate()}
                      text="Delete email template"
                      styleClass="btn-danger mr-3"
                      spinnerClass="ml-1"
                    />
                    <ReelerButton
                      loading={saving}
                      disabled={saving}
                      dispatch={e => handleSubmit()}
                      text="Save"
                      styleClass="btn-main"
                      spinnerClass="ml-1"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          ) : null}
        </Col>
      </Row>
    </Container>
  )
}
