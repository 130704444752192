import React, { useState, useEffect } from "react"
// 3 party
import { v4 as uuidV4 } from "uuid"
import { Form, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

import { useEditor } from "../../../../hooks/useEditor"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  addFormField,
  addNewFormField,
  saveFormField,
  setShowEditModal,
} from "../../../../redux/campaign/campaign.actions"
import {
  selectCampaign,
  selectFormSchema,
  selectedField,
} from "../../../../redux/campaign/campaign.selectors"
import { selectAccounts } from "../../../../redux/account/account.selectors"

import ReelerButton from "../../../commons/ReelerButton"
import * as SS from "../../../campaigns/campaign/components/styles"

import ReelerSwitch from "../../../commons/Switch/ReelerSwitch"
import {
  Circle,
  CircleContainer,
  VerticalLine,
} from "../../../commons/common-styles"
import ReelerAccordation from "../../../commons/ReelerAccordation"
import { MAIN_ROUTES } from "../../../../constants/routes"

/**
 * Logo
 * Introduction to approval page (wyziwyg)
 *
 * Choose which fields that should be shown
 *
 * Terms 1
 * Terms 2
 *
 * Email template
 *
 */

export default function ThirdPartyApprovalField({ previousFieldId }) {
  const dispatch = useDispatch()
  const formSchema = useSelector(selectFormSchema)
  const fieldId = useSelector(selectedField)
  const campaign = useSelector(selectCampaign)
  const account = useSelector(selectAccounts)
  const [settings, setSettings] = useState({
    widget: "third_party_approval",
    title: "Email for approval",
    placeholder: "Email",
    export: true,
    form_fields_on_approval_page: [],
  })
  const { ReelerEditor, getHTML, setDefaultState } = useEditor()

  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (fieldId && formSchema) {
      const defaultValues = formSchema.properties[fieldId]
      setSettings(defaultValues)
      setDefaultState(defaultValues?.approvalPage?.rightsRequestText)
    }
  }, [formSchema, fieldId])

  const handleCheck = fieldId => {
    // check if form field already is added, then remove, otherwise add to array
    if (settings?.form_fields_on_approval_page) {
      if (settings?.form_fields_on_approval_page.includes(fieldId)) {
        // Filter out the field
        let arr = settings.form_fields_on_approval_page.filter(
          id => id !== fieldId
        )

        setSettings({ ...settings, form_fields_on_approval_page: arr })
      } else {
        let arr = [...settings?.form_fields_on_approval_page, fieldId]
        setSettings({
          ...settings,
          form_fields_on_approval_page: arr,
        })
      }
    } else {
      setSettings({
        ...settings,
        form_fields_on_approval_page: [fieldId],
      })
    }
  }

  console.log(settings)

  const handleSubmit = () => {
    setSaving(true)
    let field = settings

    if (field?.approvalPage) {
      field["approvalPage"] = {
        ...field.approvalPage,
        rightsRequestText: getHTML(),
      }
    } else {
      field["approvalPage"] = {
        rightsRequestText: getHTML(),
      }
    }

    //fieldId is existing if we are updating the field, then we should only update the field and not the fieldId and order.
    if (fieldId) {
      dispatch(saveFormField(fieldId, field))
    } else {
      dispatch(addNewFormField(field, previousFieldId))
    }
    setTimeout(() => {
      setSaving(false)
    }, 500)
  }

  const handleChange = e => {
    const index = e.target.value
    let template = account?.right_request_templates?.emailTemplates[index]

    setSettings({
      ...settings,
      emailTemplate: template,
    })
  }

  const handleSelectApprovalPage = index => {
    let approvalPage = account?.right_request_templates?.approvalPages[index]

    setSettings({
      ...settings,
      approvalPage: approvalPage,
    })
    setDefaultState(approvalPage?.rightsRequestText)
  }

  return (
    <Form>
      <Row>
        <Col>
          <h1>Third party approval</h1>
        </Col>
      </Row>
      <Row className="mx-n3 mb-3">
        <Col md={7}>
          <Form.Label>Give this field an internal name</Form.Label>
          <Form.Control
            type="text"
            value={settings.dataFieldName}
            onChange={e =>
              setSettings({ ...settings, dataFieldName: e.target.value })
            }
          />
          <Form.Text muted>
            Choose an internal name for this field which will be used inside
            Reeler when viewing or exporting data.
          </Form.Text>
        </Col>
      </Row>
      <hr className="solid" />
      <Row>
        <Col sm={3} md={2} className="d-flex flex-column">
          <CircleContainer>
            <Circle active={true}>1</Circle>
          </CircleContainer>
          <VerticalLine />
        </Col>
        <Col className="pb-3">
          <ReelerAccordation
            visible={true}
            title={
              <span
                style={{
                  fontSize: "1.25rem",
                  color: "var(--reeler-title)",
                }}
              >
                Edit form details
              </span>
            }
          >
            <SS.GreyContainer>
              <Form.Group>
                <Form.Group>
                  <Col md={6} className="mx-n3">
                    <Form.Label>Text label above the field</Form.Label>
                    <Form.Control
                      type="text"
                      value={settings?.title}
                      onChange={e =>
                        setSettings({ ...settings, title: e.target.value })
                      }
                    />
                  </Col>
                  <Form.Text muted>
                    (Optional) Add a text label above the form field
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <ReelerSwitch
                    label="Required field"
                    name="required"
                    action={() =>
                      setSettings({
                        ...settings,
                        required: !settings?.required,
                      })
                    }
                    checkedBoolean={settings?.required}
                  />
                </Form.Group>
                <Form.Group>
                  <Col md={6} className="mx-n3">
                    <Form.Label>Placeholder</Form.Label>
                    <Form.Control
                      type="text"
                      value={settings?.placeholder}
                      onChange={e =>
                        setSettings({
                          ...settings,
                          placeholder: e.target.value,
                        })
                      }
                    />
                    <Form.Text muted>
                      (Optional) Add a placeholder to the input. Please note
                      that this form field only accepts an email address as
                      input; no other text is allowed.
                    </Form.Text>
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={settings?.description}
                    onChange={e =>
                      setSettings({ ...settings, description: e.target.value })
                    }
                  />
                  <Form.Text muted>
                    (Optional) Add a short text below the field to explain why
                    you want the user to input information
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3 mt-3">
                  <ReelerSwitch
                    label="Make this field exportable"
                    name="export"
                    action={() =>
                      setSettings({ ...settings, export: !settings?.export })
                    }
                    checkedBoolean={settings?.export}
                  />
                </Form.Group>
              </Form.Group>
            </SS.GreyContainer>
          </ReelerAccordation>
        </Col>
      </Row>
      <Row>
        <Col sm={3} md={2} className="d-flex flex-column">
          <CircleContainer>
            <Circle active={settings?.emailTemplate ? true : false}>2</Circle>
          </CircleContainer>
          <VerticalLine />
        </Col>
        <Col className="pb-3">
          <ReelerAccordation
            visible={true}
            title={
              <span
                style={{
                  fontSize: "1.25rem",
                  color: "var(--reeler-title)",
                }}
              >
                Configure approval email
              </span>
            }
          >
            <SS.GreyContainer>
              <Form.Group>
                <Form.Label>Select email template (optional)</Form.Label>
                <Form.Control
                  as="select"
                  name="textMessage"
                  value={account?.right_request_templates?.emailTemplates.findIndex(
                    obj =>
                      obj.emailTemplateName ===
                      settings?.emailTemplate?.emailTemplateName
                  )}
                  onChange={handleChange}
                >
                  <option>Choose predefined email template</option>
                  {account?.right_request_templates?.emailTemplates.map(
                    (emailTemplate, index) => (
                      <option key={index} value={index}>
                        {`${index + 1}: ${emailTemplate.emailTemplateName}`}
                      </option>
                    )
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Email sender alias</Form.Label>

                <Form.Control
                  type="text"
                  value={settings?.emailTemplate?.senderAlias}
                  onChange={e =>
                    setSettings({
                      ...settings,
                      emailTemplate: {
                        ...settings.emailTemplate,
                        senderAlias: e.target.value,
                      },
                    })
                  }
                />
                <Form.Text muted>
                  Add an email sender alias that will be shown in the email
                  client
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Email subject</Form.Label>
                <Form.Control
                  type="text"
                  value={settings?.emailTemplate?.subject}
                  onChange={e =>
                    setSettings({
                      ...settings,
                      emailTemplate: {
                        ...settings.emailTemplate,
                        subject: e.target.value,
                      },
                    })
                  }
                />
                <Form.Text muted>Add an email subject</Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Email body</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={settings?.emailTemplate?.body}
                  onChange={e =>
                    setSettings({
                      ...settings,
                      emailTemplate: {
                        ...settings.emailTemplate,
                        body: e.target.value,
                      },
                    })
                  }
                />

                <Form.Text muted>
                  Write an email body that is sent out to the third party for
                  approval. Copy &#123;&#123;approval_link&#x7D;&#x7D; and place
                  it where you want the link to the approval page to be
                  generated.
                </Form.Text>
              </Form.Group>
            </SS.GreyContainer>
          </ReelerAccordation>
        </Col>
      </Row>
      <Row>
        <Col sm={3} md={2} className="d-flex flex-column">
          <CircleContainer>
            <Circle active={settings?.approvalPage ? true : false}>3</Circle>
          </CircleContainer>
          <VerticalLine />
        </Col>
        <Col className="pb-3">
          <ReelerAccordation
            title={
              <span
                style={{
                  fontSize: "1.25rem",
                  color: "var(--reeler-title)",
                }}
              >
                Select approval page template
              </span>
            }
          >
            <SS.GreyContainer>
              {account?.right_request_templates?.approvalPages.length > 0 ? (
                <>
                  <Form.Group controlId="userId">
                    <Form.Control
                      as="select"
                      name="link_templates"
                      value={account?.right_request_templates?.approvalPages.findIndex(
                        obj =>
                          obj.link_name === settings?.approvalPage?.link_name
                      )}
                      onChange={e => handleSelectApprovalPage(e.target.value)}
                    >
                      <option>Select template</option>

                      {account?.right_request_templates?.approvalPages?.map(
                        (approvalPage, index) => (
                          <option key={index} value={index}>
                            {approvalPage.link_name}
                          </option>
                        )
                      )}
                    </Form.Control>
                    <Form.Text muted>
                      Select which approval page template to use.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      Personalize approval page introduction text
                    </Form.Label>

                    <ReelerEditor />

                    <Form.Text className="text-muted">
                      Here you can personalize the text on the approval page, to
                      boost your chances of getting approval (optional).
                    </Form.Text>
                  </Form.Group>
                </>
              ) : (
                <Form.Group className="p-3">
                  <Form.Text>
                    You haven’t created any approval page templates yet. To
                    start generating individual approval pages, please first
                    create a page template here.
                  </Form.Text>
                  <Link to={MAIN_ROUTES.rightsApprovalPages.path}>
                    <ReelerButton text="Go here" />
                  </Link>
                </Form.Group>
              )}
            </SS.GreyContainer>
          </ReelerAccordation>
        </Col>
      </Row>
      <Row>
        <Col sm={3} md={2} className="d-flex flex-column">
          <CircleContainer>
            <Circle active={false}>4</Circle>
          </CircleContainer>
          <VerticalLine />
        </Col>
        <Col className="pb-3">
          <ReelerAccordation
            title={
              <span
                style={{
                  fontSize: "1.25rem",
                  color: "var(--reeler-title)",
                }}
              >
                Select form fields (optional)
              </span>
            }
          >
            <SS.GreyContainer>
              <Form.Label>
                Choose which form fields to be visible on the approval landing
                page
              </Form.Label>
              <Form.Group className="ml-3">
                {formSchema.order
                  .filter(
                    filedId =>
                      !["file", "terms", "submitButton"].includes(filedId)
                  )
                  .map((fieldId, index) => {
                    let field = formSchema.properties[fieldId]
                    // Exlude text and image fields

                    if (!field) return null

                    return (
                      ![
                        "file",
                        "text",
                        "image",
                        "divider",
                        "submitButton",
                      ].includes(field?.widget) && (
                        <div key={index} className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={fieldId}
                            id={`form-field-${fieldId}`}
                            name="formFieldsOnApprovalPage"
                            onChange={() => handleCheck(fieldId)}
                            checked={settings?.form_fields_on_approval_page?.includes(
                              fieldId
                            )}
                          />
                          <label
                            className="form-check-label"
                            htmlfor={`form-field-${fieldId}`}
                          >
                            {field?.title
                              ? field.title
                              : field?.dataFieldName
                              ? field?.dataFieldName
                              : fieldId}
                          </label>
                        </div>
                      )
                    )
                  })}
              </Form.Group>
            </SS.GreyContainer>
          </ReelerAccordation>
        </Col>
      </Row>
      {/* Removed this since it is handled by the approval page template <Row>
        <Col sm={3} md={2} className="d-flex flex-column">
          <CircleContainer>
            <Circle
              active={settings?.terms || settings?.privacy ? true : false}
            >
              5
            </Circle>
          </CircleContainer>
          <VerticalLine />
        </Col>
        <Col className="pb-3">
          <ReelerAccordation
            title={
              <span
                style={{
                  fontSize: "1.25rem",
                  color: "var(--reeler-title)",
                }}
              >
                Terms and privacy conditions
              </span>
            }
          >
            <SS.GreyContainer>
              <Form.Group className="ml-3">
                <Form.Label>Terms</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={settings?.terms}
                  onChange={e =>
                    setSettings({ ...settings, terms: e.target.value })
                  }
                />
                <Form.Text muted>
                  {`Please insert a “terms and conditions” (T&C) text that users
                  will be asked to approve. If you wish to include links, such
                  as a link to a more comprehensive T&C document, you can use
                  HTML like this: 
                  <a href="URL" target="_blank">
                    Terms & Conditions
                  </a>`}
                </Form.Text>
              </Form.Group>
              <Form.Group className="ml-3">
                <Form.Label>Privacy</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={settings?.privacy}
                  onChange={e =>
                    setSettings({ ...settings, privacy: e.target.value })
                  }
                />
                <Form.Text muted>
                  {`Please include a “data processing” text that users will be
                  required to approve. Users should consent to the processing of
                  the personal data they provide in the form. To add a link to
                  your complete privacy policy, you can use HTML like this:
                  <a href="URL" target="_blank">
                    Privacy Policy
                  </a>`}
                </Form.Text>
              </Form.Group>
            </SS.GreyContainer>
          </ReelerAccordation>
        </Col>
      </Row> */}

      <Row>
        <Col sm={3} md={2} className="d-flex flex-column">
          <CircleContainer>
            <Circle active={fieldId ? true : false}>5</Circle>
          </CircleContainer>
        </Col>
        <Col className="pb-3">
          <ReelerAccordation
            title={
              <span
                style={{
                  fontSize: "1.25rem",
                  color: "var(--reeler-title)",
                }}
              >
                Save field
              </span>
            }
          >
            <SS.GreyContainer>
              <Form.Group>
                <ReelerButton
                  loading={saving}
                  disabled={saving}
                  dispatch={e => handleSubmit()}
                  text="Save"
                  className="mr-3"
                  styleClass="btn-main"
                  spinnerClass="ml-1"
                />
              </Form.Group>
            </SS.GreyContainer>
          </ReelerAccordation>
        </Col>
      </Row>
    </Form>
  )
}
