import React from "react"

import "../../App.css"
import InstagramIntegration from "./components/InstagramIntegration"
import Section from "../../../layouts/core/Section"
import { Header } from "../../../layouts/core/Header"
import Title from "../../../layouts/core/Title"
import Body from "../../../layouts/core/Body"
import BackButton from "../../commons/BackButton"
import WhatsappIntegration from "./components/WhatsappIntegration"

export default function Integrations() {
  return (
    <Section>
      <Header>
        <Title>Integrations</Title>
        <div className="ml-auto">
          <BackButton />
        </div>
      </Header>
      <Body>
        <div className="d-flex">
          <InstagramIntegration />
          <WhatsappIntegration />
        </div>
      </Body>
    </Section>
  )
}
