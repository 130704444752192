import React, { useState } from "react"
import { Dropdown } from "react-bootstrap"
import { FaCircle, FaCaretDown } from "react-icons/fa"
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip"
import { CAMPAIGN_STATUS } from "../../../constants"

export default function FilterSourceStatus({ state, dispatch }) {
  const [status, setStatus] = useState("active")
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="d-flex flex-row justify-content-center campaign-status"
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </div>
  ))

  const handleFilterStatus = value => {
    setStatus(value)
    dispatch({ ...state, status: value })
  }

  return (
    <Dropdown style={{ width: "30px" }}>
      <Dropdown.Toggle as={CustomToggle} id="source-status">
        <ReelerTooltip text={"Filter source status"}>
          <>
            {status !== "showAll" ? (
              <FaCircle
                className={
                  status === CAMPAIGN_STATUS.active
                    ? "icon-campaign-active"
                    : "icon-campaign-inactive"
                }
              />
            ) : (
              ""
            )}
            <FaCaretDown className="campaign-status-caret " />
          </>
        </ReelerTooltip>
      </Dropdown.Toggle>

      <Dropdown.Menu className="reeler-dropdown">
        <Dropdown.Item
          onClick={() => handleFilterStatus(CAMPAIGN_STATUS.active)}
        >
          <FaCircle className="mr-2 mb-1 icon-campaign-active" />
          Active
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => handleFilterStatus(CAMPAIGN_STATUS.inactive)}
        >
          <FaCircle className="mr-2 mb-1 icon-campaign-inactive" />
          Paused
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleFilterStatus("showAll")}>
          Show All
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
