import React, { useState } from "react"

import { Modal } from "react-bootstrap"
import { FaTrashAlt } from "react-icons/fa"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectFormSchema } from "../../../../redux/campaign/campaign.selectors"
import { removeFieldFromForm } from "../../../../redux/campaign/campaign.actions"

// Reeler

import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"
import ReelerButton from "../../../commons/ReelerButton"
import ReelerIconButton from "../../../commons/reelerIconButton/ReelerIconButton"

export default function RemoveWidgetFromForm({ fieldId }) {
  const dispatch = useDispatch()
  const formSchema = useSelector(selectFormSchema)
  const [showModal, setShowModal] = useState(false)

  const handleRemoveField = () => {
    const newOrder = formSchema.order
    const index = newOrder.indexOf(fieldId)
    newOrder.splice(index, 1)
    const { [fieldId]: value, ...newProperties } = formSchema.properties
    dispatch(removeFieldFromForm(newOrder, newProperties))
  }
  return (
    <div>
      <ReelerTooltip text="Add new form field">
        <span onClick={() => setShowModal(true)}>
          Remove field <FaTrashAlt />
        </span>
      </ReelerTooltip>
      <Modal centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Remove field from form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to remove this field from the form?</p>
        </Modal.Body>

        <Modal.Footer>
          <ReelerButton
            text="Close"
            dispatch={() => setShowModal(false)}
            styleClass="btn-secondary"
          />
          <ReelerIconButton
            text="Remove field from form"
            icon={<FaTrashAlt className="mr-1" />}
            styleClass="btn-delete"
            dispatch={() => handleRemoveField()}
          />
        </Modal.Footer>
      </Modal>
    </div>
  )
}
