import React, { useState } from "react"
import { FaTimes } from "react-icons/fa"
// Firebase
import { database } from "../../../../../../../../firebase"

// Reeler
import * as S from "../asset-approve-styles"
import ReelerTooltip from "../../../../../../../commons/ReelerTooltip/ReelerTooltip"
import { ASSET_STATE } from "../../../../../../../../constants"
import SpinnerComponent from "../../../../../../../commons/SpinnerComponent"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectActiveAsset } from "../../../../../../../../redux/asset/asset.selectors"
import { updateAsset } from "../../../../../../../../redux/asset/asset.actions"
import { selectCurrentUser } from "../../../../../../../../redux/user/user.selectors"
import { USER_ROLES } from "../../../../../../../../constants/routes"

export default function Reject({ asset }) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const assetRedux = useSelector(selectActiveAsset)
  const currentUser = useSelector(selectCurrentUser)

  const handleReject = () => {
    setLoading(true)
    if (asset?.state === ASSET_STATE.rejected) {
      // Remove state from asset

      database.assets
        .doc(asset.id)
        .update({
          state: ASSET_STATE.unreviewed,
          stateChangedAt: database.getCurrentTimestamp(),
        })
        .then(() => {
          if (assetRedux) {
            dispatch(
              updateAsset({
                ...assetRedux,
                state: ASSET_STATE.unreviewed,
                stateChangedAt: database.getCurrentTimestamp(),
              })
            )
          }
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      // Add state from asset

      database.assets
        .doc(asset.id)
        .update({
          state: ASSET_STATE.rejected,
          stateChangedAt: database.getCurrentTimestamp(),
        })
        .then(() => {
          if (assetRedux) {
            dispatch(
              updateAsset({
                ...assetRedux,
                state: ASSET_STATE.rejected,
                stateChangedAt: database.getCurrentTimestamp(),
              })
            )
          }
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  return (
    <ReelerTooltip
      text={currentUser?.role !== USER_ROLES.viewer ? "Reject asset" : null}
    >
      <S.ApproveIcon
        disabled={currentUser?.role === USER_ROLES.viewer}
        onClick={() => handleReject()}
        borderRadius={"0 3px 3px 0"}
        bgHover={
          asset.state === ASSET_STATE.rejected
            ? "var(--reeler-danger-hover)"
            : "var(--reeler-grey-darker)"
        }
        bgColor={
          asset.state === ASSET_STATE.rejected
            ? "var(--reeler-danger-light)"
            : "var(--reeler-grey-middle)"
        }
      >
        {loading ? (
          <SpinnerComponent />
        ) : (
          <FaTimes
            style={{
              color:
                asset?.state === ASSET_STATE.rejected
                  ? "var(--reeler-danger)"
                  : "var(--reeler-icon)",
            }}
          />
        )}
      </S.ApproveIcon>
    </ReelerTooltip>
  )
}
