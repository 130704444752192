import React, { useState } from "react"
import { Dropdown } from "react-bootstrap"
import { FaCaretDown } from "react-icons/fa"
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip"
import { CAMPAIGN_TYPE } from "../../../constants"

export default function FilterCampaignType({ state, dispatch }) {
  const [type, setType] = useState("showAll")
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="d-flex flex-row campaign-status align-items-center"
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </div>
  ))

  const handleFilter = value => {
    setType(value)
    dispatch({ ...state, type: value })
  }

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="campaign-type">
        <ReelerTooltip text={"Filter campaign type"}>
          <>
            Type
            <FaCaretDown className="campaign-status-caret" />
          </>
        </ReelerTooltip>
      </Dropdown.Toggle>

      <Dropdown.Menu className="reeler-dropdown">
        <Dropdown.Item
          onClick={() => handleFilter(CAMPAIGN_TYPE.igHashtag.type)}
        >
          <span className="mr-2">{CAMPAIGN_TYPE.igHashtag.icon}</span>
          Hashtag
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => handleFilter(CAMPAIGN_TYPE.igMentions.type)}
        >
          <span className="mr-2">{CAMPAIGN_TYPE.igMentions.icon}</span>
          Mentions
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleFilter(CAMPAIGN_TYPE.upload.type)}>
          <span className="mr-2">{CAMPAIGN_TYPE.upload.icon}</span>
          Web form
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleFilter(CAMPAIGN_TYPE.email.type)}>
          <span className="mr-2">{CAMPAIGN_TYPE.email.icon}</span>
          Email
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleFilter("showAll")}>
          Show All
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
