import React from "react"
import * as S from "./icon-styles"
import ReelerTooltip from "../ReelerTooltip/ReelerTooltip"

export default function Icon({
  size = "md",
  tooltip,
  tooltipPlacement = "top",
  notification,
  active,
  children,
  ...rest
}) {
  const renderIcon = () => (
    <S.Icon size={size} active={active} {...rest}>
      {notification ? (
        <S.IconNotification>{notification}</S.IconNotification>
      ) : null}
      {children}
    </S.Icon>
  )

  if (tooltip)
    return (
      <ReelerTooltip text={tooltip} placement={tooltipPlacement}>
        {renderIcon()}
      </ReelerTooltip>
    )

  return renderIcon
}
