import React from "react"
import ContentGridAsset from "../../campaign/components/ContentGridAsset"

export default function AssetListView({ asset }) {
  return (
    <div className="d-flex w-100 flex-row mb-3 ml-3 mr-3 list-asset">
      <div className="p-3" style={{ width: "30%", background: "#fff" }}>
        <ContentGridAsset asset={asset} />
      </div>
      <div className="p-3" style={{ width: "70%", background: "#fff" }}></div>
    </div>
  )
}
