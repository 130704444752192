import React from "react"
import { Row, Col, Form } from "react-bootstrap"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectAssetRightsRequest } from "../../../../../../../redux/asset/asset.selectors"
import { setAssetRightsRequest } from "../../../../../../../redux/asset/asset.actions"
import ReelerAccordation from "../../../../../../commons/ReelerAccordation"
import SaveRightRequest from "./SaveRightRequest"

export default function ContactInformation() {
  const dispatch = useDispatch()

  const rightsRequest = useSelector(selectAssetRightsRequest)

  return (
    <Row>
      <Col className="pb-3">
        <div
          style={{
            margin: "0 0 0 1rem",
            padding: "0rem 1rem",
            border: "1px solid var(--reeler-grey-dark)",
          }}
        >
          <ReelerAccordation
            visible={false}
            title={
              <span
                style={{
                  fontSize: "1rem",
                  color: "var(--reeler-title)",
                }}
              >
                Contact information (optional)
              </span>
            }
          >
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>

                  <Form.Control
                    type="text"
                    size="sm"
                    placeholder="First Name"
                    value={rightsRequest?.contactDetails?.name}
                    onChange={e =>
                      dispatch(
                        setAssetRightsRequest({
                          ...rightsRequest,
                          contactDetails: {
                            ...rightsRequest?.contactDetails,
                            name: e.target.value,
                          },
                        })
                      )
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    size="sm"
                    placeholder="Last Name"
                    value={rightsRequest?.contactDetails?.lastName}
                    onChange={e =>
                      dispatch(
                        setAssetRightsRequest({
                          ...rightsRequest,
                          contactDetails: {
                            ...rightsRequest?.contactDetails,
                            lastName: e.target.value,
                          },
                        })
                      )
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    size="sm"
                    placeholder="Email"
                    value={rightsRequest?.contactDetails?.email}
                    onChange={e =>
                      dispatch(
                        setAssetRightsRequest({
                          ...rightsRequest,
                          contactDetails: {
                            ...rightsRequest?.contactDetails,
                            email: e.target.value,
                          },
                        })
                      )
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={rightsRequest?.note}
                    onChange={e =>
                      dispatch(
                        setAssetRightsRequest({
                          ...rightsRequest,
                          note: e.target.value,
                        })
                      )
                    }
                  />

                  <Form.Text className="text-muted">
                    Add an internal note.
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <SaveRightRequest />
              </Col>
            </Row>
          </ReelerAccordation>
        </div>
        <div className="ml-3">
          <Form.Text muted>
            Contact information is only needed if you plan to send your request
            via email (not needed for sending a DM on Instagram).
          </Form.Text>
        </div>
      </Col>
    </Row>
  )
}
