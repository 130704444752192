import React, { useState } from "react"
import { database } from "../../firebase"
import "../App.css"
import "./EmailNotification.css"
import { Form } from "react-bootstrap"
import ReelerButton from "../commons/ReelerButton"
import FadeAlert from "../commons/FadeAlert/FadeAlert"
import { useSelector, useDispatch } from "react-redux"
import { selectCurrentUser } from "../../redux/user/user.selectors"
import { updateCurrentUser } from "../../redux/user/user.actions"
import Section from "../../layouts/core/Section"
import { Header } from "../../layouts/core/Header"
import Title from "../../layouts/core/Title"
import Body from "../../layouts/core/Body"
import BackButton from "../commons/BackButton"

const EmailNotification = () => {
  const currentUser = useSelector(selectCurrentUser)
  const [msg, setMsg] = useState(null)

  const dispatch = useDispatch()

  const [saving, setSaving] = useState(false)

  function handleSubmit(e) {
    e.preventDefault()
    setSaving(true)
    setMsg(null)
    database.users
      .doc(currentUser.id)
      .update({
        notifications: {
          dailyNotification: currentUser?.notifications?.dailyNotification
            ? currentUser.notifications?.dailyNotification
            : false,

          weeklyNotification: currentUser?.notifications?.weeklyNotification
            ? currentUser.notifications?.weeklyNotification
            : false,
        },
      })

      .then(() => {
        setMsg({
          success: true,
          text: "Succes, email notifications is updated",
        })
        setSaving(false)
      })
      .catch(err => {
        setMsg({ success: false, text: "Couldn't update email notification" })
        setSaving(false)
      })
  }

  return (
    <Section>
      <Header>
        <Title>Email Notification</Title>
        <div className="ml-auto">
          <BackButton />
        </div>
      </Header>

      <Body>
        <p>These preferences will only be applied to you.</p>

        <Form onSubmit={handleSubmit}>
          <h6 className="form-heading">
            Set the default frequency for email notifications
          </h6>
          <Form.Group className="d-flex flex-row justify-content-start align-items-center mt-3 ml-2">
            <Form.Check
              type="checkbox"
              label="Daily"
              name="daily"
              checked={currentUser?.notifications?.dailyNotification}
              onChange={() =>
                dispatch(
                  updateCurrentUser({
                    ...currentUser,
                    notifications: {
                      ...currentUser?.notifications,
                      dailyNotification:
                        !currentUser?.notifications?.dailyNotification,
                    },
                  })
                )
              }
            />
          </Form.Group>
          <Form.Group className="d-flex flex-row justify-content-start align-items-center mt-3 ml-2">
            <Form.Check
              type="checkbox"
              label="Weekly"
              name="weekly"
              checked={currentUser?.notifications?.weeklyNotification}
              onChange={() =>
                dispatch(
                  updateCurrentUser({
                    ...currentUser,
                    notifications: {
                      ...currentUser?.notifications,
                      weeklyNotification:
                        !currentUser?.notifications?.weeklyNotification,
                    },
                  })
                )
              }
            />
          </Form.Group>

          {msg && <FadeAlert msg={msg} duration={5000} />}
          <div className="d-flex flex-row justify-content-end">
            <ReelerButton
              text="Update"
              styleClass="btn-main"
              type="submit"
              disabled={saving}
              loading={saving}
            />
          </div>
        </Form>
      </Body>
    </Section>
  )
}

export default EmailNotification
