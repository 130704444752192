import React, { useState, useCallback } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"

import { functions } from "../../../firebase"
import SpinnerComponent from "../../commons/SpinnerComponent"
import { selectAccounts } from "../../../redux/account/account.selectors"
import ReelerBadge from "../ReelerBadge"

export default function CountAssetsInFilter({ filter }) {
  const account = useSelector(selectAccounts)
  const [loading, setLoading] = useState(true)
  const [assets, setAssets] = useState(0)

  const countNumberOfAssetsInFilter = useCallback(async () => {
    setLoading(true)
    var countNumberOfAssetsInFilter = functions.httpsCallable(
      "countNumberOfAssetsInFilter"
    )

    try {
      if (filter && account) {
        const { data } = await countNumberOfAssetsInFilter({
          filter,
          accountId: account.id,
        })
        setAssets(data.noOfAssets)
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }, [])

  useEffect(() => {
    if (filter && account) {
      countNumberOfAssetsInFilter()
    } else {
      setLoading(false)
    }
  }, [])

  if (loading) return <SpinnerComponent size="sm" />

  return <ReelerBadge value={assets} />
}
