import React from "react"
import { Form, Row, Table } from "react-bootstrap"

import "../App.css"

import Section from "../../layouts/core/Section"
import { Header } from "../../layouts/core/Header"
import Body from "../../layouts/core/Body"
import Title from "../../layouts/core/Title"
import BackButton from "../commons/BackButton"

import Navbar from "./components/Navbar"
import Page from "./components/Page"
import PageSection from "./components/PageSection"
import PageSectionTitle from "./components/PageSectionTitle"

const example = {
  data: [
    {
      username: "johndoe",
      creator_caption:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In consequat augue at nunc auctor egestas. In in nibh nec libero aliquet ultrices. Sed at malesuada ligula, feugiat faucibus mauris.",
      pub_date: "2019-09-26T22:36:43+0000",
      media_type: "IMAGE",
      media_id: "6no5lsuObG7WKxL1CUoz",
      images: {
        original:
          "https://app.reelertech.com/api/v1.0/media/6no5lsuObG7WKxL1CUoz",
        h_200:
          "https://app.reelertech.com/api/v1.0/media/6no5lsuObG7WKxL1CUoz?size=200",
        h_500:
          "https://app.reelertech.com/api/v1.0/media/6no5lsuObG7WKxL1CUoz?size=500",
      },
    },
    {
      username: "johndoe",
      creator_caption:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In consequat augue at nunc auctor egestas. In in nibh nec libero aliquet ultrices. Sed at malesuada ligula, feugiat faucibus mauris.",
      pub_date: "2019-09-26T22:36:43+0000",
      media_type: "VIDEO",
      media_id: "jM0vW5zxXkenmsPgJ0HR",
      video_url:
        "https://app.reelertech.com/api/v1.0/media/jM0vW5zxXkenmsPgJ0HR",
    },
  ],
}
const errorExample = {
  error: {
    message: "No valid access token provided.",
  },
}

const RecentLabeledContentAPIView = () => {
  return (
    <Section>
      <Header>
        <Title>Reeler Content API</Title>
        <div className="ml-auto">
          <BackButton />
        </div>
      </Header>
      <Body>
        <Row>
          <Navbar />
          <Page>
            <h1 className="title">Recent Labeled Content</h1>
            <p>
              This API endpoint returns the most recently published IMAGE and
              VIDEO assets that have been marked with certain labels.
            </p>

            <PageSection>
              <PageSectionTitle>Endpoint</PageSectionTitle>

              <Form.Control
                disabled={true}
                value="GET /recent_labeled_content"
                aria-label="Enpoint"
              />
            </PageSection>

            <PageSection>
              <PageSectionTitle>Limitations</PageSectionTitle>
              <ul>
                <li>Returns images and videos (no audioclips).</li>
                <li>
                  Returns the content assets that were most recently added to
                  your Reeler account and have been labeled in a certain way.
                </li>
                <li>
                  Responses are limited to a maximum of 50 assets per call.
                </li>
              </ul>
            </PageSection>

            <PageSection>
              <PageSectionTitle>Requirements</PageSectionTitle>

              <ul>
                <li>Access token</li>
              </ul>
            </PageSection>
            <PageSection>
              <PageSectionTitle>Request syntax</PageSectionTitle>
              <Form.Control
                as="textarea"
                rows={3}
                disabled={true}
                value="GET https://app.reelertech.com/api/v1.0/recent_labeled_content?labels={labels}&media_type={media-type}&dimension={dimension}&fields={fields}&limit={limit}
 &access_token={access-token}"
                aria-label="Base URL"
              />
              <Form.Text muted>
                Returns the content assets that were most recently added to your
                Reeler account and have been labeled in a certain way.
              </Form.Text>
            </PageSection>
            <PageSection>
              <PageSectionTitle>Query String Parameters</PageSectionTitle>
              <p>
                Include any of the following query string parameters to refine
                your request:
              </p>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span>
                        labels <b>required</b> (Comma-separated list)
                      </span>
                    </td>
                    <td>
                      <span>
                        Enter a comma-separated list of labels to filter content
                        assets.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>fields (Comma-separated list)</td>
                    <td>
                      <span>
                        A comma-separated list of Fields you want returned. If
                        omitted, default fields will be returned.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>media_type</td>
                    <td>
                      Select either IMAGE or VIDEO. If omitted, both images and
                      videos will be returned. For images, three different size
                      versions will be returned: original, h_200 (with a height
                      limit of 200px), and h_500 (with a height limit of 500px).
                      For videos, only one version will be returned.
                    </td>
                  </tr>
                  <tr>
                    <td>orientation</td>
                    <td>
                      <span>
                        Select content orientation as PORTRAIT, SQUARE, or
                        LANDSCAPE. If omitted, assets of any orientation will be
                        returned.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>limit</td>
                    <td>
                      <span>
                        Specify the number of unique content assets you wish to
                        retrieve (maximum 50). If omitted, 10 assets will be
                        returned by default.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>cache_age</td>
                    <td>
                      <span>
                        Use the cache_age parameter to define the maximum
                        duration for caching API responses, expressed in
                        seconds. Both the API response with the content links,
                        and the content assets themselves, will be cached for
                        the same maximum duration (content assets will be cached
                        after the first time they are called). If you omit the
                        cache_age parameter, the default duration of “3600”
                        seconds (1 hour) will be used. The cache_age parameter
                        allows you to adjust the maximum age from “0" (no
                        caching) to a maximum of “2592000” seconds (one month).
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        access_token <b>required</b> (String)
                      </span>
                    </td>
                    <td>
                      <span>
                        Your Reeler account's unique “access token”, generated
                        within your Reeler account.
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </PageSection>
            <PageSection>
              <PageSectionTitle>Fields</PageSectionTitle>
              <p>
                A comma-separated list of fields you want returned. You can use
                the “fields” query string parameter to request any or all of the
                below fields. If omitted, only the media_type field and the
                content asset URL(s) will be returned.
              </p>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span>id</span>
                    </td>
                    <td>
                      <span>
                        The Content ID is a unique number that identifies the
                        content asset in your Reeler account.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>username</td>
                    <td>
                      <span>
                        The creator's username is sometimes extracted from
                        Instagram, and in other cases, it´s manually edited by a
                        user in your Reeler account.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>media_type</td>
                    <td>Returns either IMAGE or VIDEO.</td>
                  </tr>
                  <tr>
                    <td>source_type</td>
                    <td>
                      Returns either INSTAGRAM_HASHTAG, INSTAGRAM_MENTION, EMAIL
                      or UPLOAD.
                    </td>
                  </tr>
                  <tr>
                    <td>creator_caption</td>
                    <td>
                      <span>
                        The original caption as entered by the creator, for
                        example, on Instagram.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>edited_caption</td>
                    <td>
                      <span>
                        A caption edited by a user in your Reeler account.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>instagram_post_link</span>
                    </td>
                    <td>
                      <span>
                        Link to the original Instagram post (if the asset was
                        sourced from there).
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>pub_date</span>
                    </td>
                    <td>
                      <span>
                        The date and time when the content asset was either
                        published on social media or uploaded to Reeler, in ISO
                        8601 format, with the time zone relative to UTC (default
                        is UTC ±00:00).
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </PageSection>
            <PageSection>
              <PageSectionTitle>Example request:</PageSectionTitle>
              <Form.Control
                as="textarea"
                rows={3}
                disabled={true}
                value="GET https://app.reelertech.com/api/v1.0/recent_labeled_content?labels=travel,stockholm&fields=username,media_type,creator_caption,pub_date&access_token=IGQVJ..."
                aria-label="Example request"
              />
            </PageSection>
            <PageSection>
              <PageSectionTitle>Example response:</PageSectionTitle>
              <Form.Control
                as="textarea"
                rows={24}
                disabled={true}
                value={JSON.stringify(example, undefined, 2)}
                aria-label="Example response"
              />
            </PageSection>
            <PageSection>
              <PageSectionTitle>Error response:</PageSectionTitle>
              <Form.Control
                as="textarea"
                rows={5}
                disabled={true}
                value={JSON.stringify(errorExample, undefined, 2)}
                aria-label="Error response"
              />
            </PageSection>
          </Page>
        </Row>
      </Body>
    </Section>
  )
}

export default RecentLabeledContentAPIView
