import React from "react"
import { Badge } from "react-bootstrap"

export default function ReelerBadge({ value = "0" }) {
  return (
    <Badge className="badge-main">
      {value > 1000 ? (value / 1000).toFixed(1) + "k" : value}
    </Badge>
  )
}
