import React from "react"
import { setFeed } from "../../../../../redux/feed/feed.actions"
import { selectFeed } from "../../../../../redux/feed/feed.selectors"
import { useDispatch, useSelector } from "react-redux"
import { Form, Row, Col } from "react-bootstrap"
import { FaRegCopy } from "react-icons/fa"
import ReelerTooltip from "../../../../commons/ReelerTooltip/ReelerTooltip"
import { FEED_TYPES } from "../../../../../constants"
import EmbedEmailFeed from "./email-feed/components/EmbedEmailFeed"

const EmbedTab = () => {
  const feed = useSelector(selectFeed)
  const dispatch = useDispatch()

  const handleEmbedType = type => {
    dispatch(setFeed({ ...feed, embedType: type }))
  }

  const divCode = `<div id="reeler-widget" data-feed="${feed?.id}"></div>`
  const scriptCode = `<script src="${process.env.REACT_APP_FIREBASE_WIDGET_URL}/ReelerWidgets.js"></script>`

  const iframeCode = `<iframe
  width="100%"
  height="700px"
  scrolling="yes"
  style="border: none"
  src="${process.env.REACT_APP_FIREBASE_WIDGET_URL}/iframe/feed/${feed?.id}"
></iframe>`

  const copyUrl = divId => {
    var r = document.createRange()
    r.selectNode(document.getElementById(divId))
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(r)
    document.execCommand("copy")
    window.getSelection().removeAllRanges()
  }
  return (
    <>
      <Row>
        <Col md={6}>
          {feed?.feedType === FEED_TYPES.email ? (
            <EmbedEmailFeed />
          ) : (
            <Form.Group id="embed">
              <Col>
                <Form.Label className="mb-0">Embed web feed as:</Form.Label>
                <Form.Check>
                  <Form.Check.Input
                    onChange={() => {
                      handleEmbedType("div")
                    }}
                    checked={feed?.embedType === "div"}
                    name="form_type"
                    type="radio"
                    id="divScript-check"
                  />
                  <Form.Check.Label htmlFor="divScript-check">
                    Div + script
                  </Form.Check.Label>
                </Form.Check>

                <Form.Check className="my-1">
                  <Form.Check.Input
                    onChange={() => {
                      handleEmbedType("iframe")
                    }}
                    checked={feed?.embedType === "iframe"}
                    name="form_type"
                    type="radio"
                    id="iframe-check"
                  />
                  <Form.Check.Label htmlFor="iframe-check">
                    Iframe
                  </Form.Check.Label>
                </Form.Check>
              </Col>
              <Col className="mt-3">
                <>
                  <div className="d-flex flex-row align-items-center">
                    <div
                      id="script-link"
                      className="mt-2 p-2"
                      style={{
                        width: "100%",
                        height: "100px",
                        background: "#fff",
                        fontSize: "10px",
                        border: "1px solid lightgrey",
                        borderRadius: ".25rem",
                      }}
                    >
                      {feed?.embedType === "div"
                        ? divCode + scriptCode
                        : iframeCode}
                    </div>
                    <ReelerTooltip text="Copy the code">
                      <FaRegCopy
                        style={{ strokeWidth: "3" }}
                        className="icon-color ml-3 icon-btn"
                        onClick={() => copyUrl("script-link")}
                      />
                    </ReelerTooltip>
                  </div>
                </>
              </Col>
            </Form.Group>
          )}
        </Col>
      </Row>
    </>
  )
}

export default EmbedTab
