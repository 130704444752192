import React, { useRef, useState, useEffect, useCallback } from "react"
import { Modal, Row, Col, Form, Button } from "react-bootstrap"
import ReactCrop from "react-image-crop"
import { v4 as uuidV4 } from "uuid"

import "react-image-crop/dist/ReactCrop.css"
import { FaCrop } from "react-icons/fa"
// import MapMarker from "./map_marker.svg"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"
import ReelerButton from "../../../../../commons/ReelerButton"
import ReelerSwitch from "../../../../../commons/Switch/ReelerSwitch"
// Firebase
import { database, storage } from "../../../../../../firebase"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentUser } from "../../../../../../redux/user/user.selectors"
import { updateAsset } from "../../../../../../redux/asset/asset.actions"
import { getMediaMetadata } from "../../../../../../utils/GetMediaMetadata"

function generateDownload(canvas, crop, filename, type) {
  if (!crop || !canvas) {
    return
  }

  // canvas.height = 1080;
  // canvas.width =
  canvas.toBlob(
    blob => {
      const previewUrl = window.URL.createObjectURL(blob)
      const anchor = document.createElement("a")
      anchor.download = filename
      anchor.crossOrigin = "Anonymous"
      anchor.href = URL.createObjectURL(blob)
      anchor.click()

      window.URL.revokeObjectURL(previewUrl)
    },
    type,
    1
  )
}

export default function AssetCropImage({ asset }) {
  const dispatch = useDispatch()

  const [url, setUrl] = useState(asset.url)
  const user = useSelector(selectCurrentUser)

  const [crop, setCrop] = useState({
    unit: "%",
    width: 100,
    height: 100,
    x: 0,
    y: 0,
  })
  const [scale, setScale] = useState(null)
  const [completedCrop, setCompletedCrop] = useState(null)
  const [title, setTitle] = useState()
  const [mainVersion, setMainVersion] = useState()
  const [makeNewVersionMain, setMakeNewVersionMain] = useState(false)
  const [canvas, setCanvas] = useState()
  const [open, setOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState()
  const [errMsg, setErrMsg] = useState(null)

  // const [photoCredentials, setPhotoCredentials] = useState({
  //   firstName: asset.firstName ? asset.firstName : "",
  //   lastName: asset.lastName ? asset.lastName : "",
  // })
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const modalRef = useRef()

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  const onLoad = useCallback(img => {
    imgRef.current = img
    imgRef.current.crossOrigin = "*"
    const scaleX = img.naturalWidth / img.width
    const scaleY = img.naturalHeight / img.height
    setScale({
      scaleX: scaleX,
      scaleY: scaleY,
      naturalWidth: img.naturalWidth,
      naturalHeight: img.naturalHeight,
      width: img.width,
      height: img.height,
    })
  }, [])

  useEffect(() => {
    if (asset) {
      // if we find a main version, then set its values, otherwise use old values in asset.
      if (asset?.assetVersions?.length > 0) {
        let mainVersion = asset?.assetVersions.filter(
          version => version.main === true
        )
        if (mainVersion.length > 0) {
          setMainVersion(mainVersion[0])
          setUrl(mainVersion[0].url)
        }
      }
    }

    // asset.name
    // ? asset.name.replace(/[^a-z0-9]/gi, "_").toLowerCase() +
    //     moment(asset.createdAt.toDate()).format("YYYY-MM-DD") +
    //     ".jpeg"
    // : asset.location ? : "croppedImage.jpeg"
    // }
  }, [asset])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const ctx = canvas.getContext("2d")
    //const pixelRatio = window.devicePixelRatio

    //canvas.width = crop.width * pixelRatio * scaleX
    canvas.width = crop.width * scale.scaleX
    //canvas.height = crop.height * pixelRatio * scaleY
    canvas.height = crop.height * scale.scaleY
    setCanvas({ width: canvas.width, height: canvas.height })

    //ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = "high"

    ctx.drawImage(
      image,
      crop.x * scale.scaleX,
      crop.y * scale.scaleY,
      crop.width * scale.scaleX,
      crop.height * scale.scaleY,
      0,
      0,
      crop.width * scale.scaleX,
      crop.height * scale.scaleY
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop])

  const addTemplateToCanvas = () => {
    const canvas = previewCanvasRef.current
    if (canvas.getContext) {
      const ctx = canvas.getContext("2d")

      // ctx.fillStyle = "#D41B32"
      // ctx.fillRect(100, 50, 60, 60)

      // var img = new Image() // Create new img element
      // img.src = MapMarker
      // ctx.drawImage(img, 118, 65)

      //ctx.fillStyle = "#1F2C36"
      ctx.font = "normal 600 56px Gotham, Helvetica Neue, sans-serif"
      let rectWithCredetialsName =
        120 + ctx.measureText("Magnus Bågenholm").width
      ctx.fillStyle = "#138ADE"
      ctx.fillRect(0, 50, rectWithCredetialsName, 70)
      ctx.fillStyle = "#FFF"
      ctx.fillText("Magnus Bågenholm", 100, 105)

      //ctxTextName.fillStyle = "#F2F4F5"

      // ctxTextLocation.shadowColor = "black"
      // ctxTextLocation.shadowBlur = 6
      // ctxTextLocation.shadowOffsetX = 6
      // ctxTextLocation.shadowOffsetY = 6

      //ctx.fillStyle = "#1F2C36"
      //ctx.fillStyle = "#0993F5"
      //ctx.fillRect(100, 120, 560, 60)

      ctx.font = "normal normal 38px Gotham, Helvetica Neue, sans-serif"
      ctx.fillStyle = "#FFF"
      //ctx.fillStyle = "#F2F4F5"

      ctx.shadowColor = "black"
      ctx.shadowBlur = 4
      ctx.shadowOffsetX = 4
      ctx.shadowOffsetY = 4
      ctx.fillText("Gnesta", 100, 160)
    }
  }

  const handleSaveNewVersion = () => {
    setUploading(true)

    let uploadedBy =
      user.firstName || user.lastName
        ? user.firstName + " " + user.lastName
        : user.email

    // Create a Blob from the canvas

    previewCanvasRef.current.toBlob(
      blob => {
        // Upload
        let accessToken = uuidV4()
        let format = mainVersion.type.split("/").pop()
        let newFileName = accessToken + "." + format
        let filePath = asset.accountId + "/" + asset.campaignId + "/"

        const uploadTask = storage.ref(filePath + newFileName).put(blob)

        uploadTask.on(
          "state_changed",
          snapshot => {
            setProgress(snapshot.bytesTransferred / snapshot.totalBytes)
          },
          err => {
            setErrMsg("Something went wrong when saving new version.")
            setUploading(false)
          },
          async () => {
            uploadTask.snapshot.ref.getDownloadURL().then(async url => {
              let updateAssetInfo = {}
              let newVersion = {
                id: accessToken,
                title: title,
                url: url,
                size: blob.size,
                type: blob.type,
                file_name: newFileName,
                file_path: filePath,
                access_token: accessToken,
                modifiedAt: Date.now(),
                uploadedBy: uploadedBy,
                main: makeNewVersionMain,
              }

              var file = new File([blob], "tempFile")
              const metadata = await getMediaMetadata(file, asset.media_type)
              newVersion["metadata"] = metadata
              // update redux and firestore

              let updatedArr = asset.assetVersions

              // If main is true, then filter out the current main and set its main to false, unless its the same version as beeing updated
              if (newVersion.main) {
                updateAssetInfo["metadata"] = metadata
                updatedArr = updatedArr.map(version => {
                  if (version.main === true) {
                    return { ...version, main: false }
                  }
                  return version
                })
              }

              // add new version to versions array
              updatedArr = [...updatedArr, newVersion]

              updateAssetInfo["assetVersions"] = updatedArr

              // Update firestore
              var docRef = database.assets.doc(asset.id)
              docRef.update(updateAssetInfo)

              // update redux state
              dispatch(
                updateAsset({
                  ...asset,
                  ...updateAssetInfo,
                })
              )
              setUploading(false)
            })
          }
        )
      },
      mainVersion.type,
      1
    )
  }

  return (
    <>
      <div className="mt-3">
        <ReelerTooltip text="Crop and edit asset">
          <Button
            variant="secondary"
            size="sm"
            className="d-flex flex-row align-items-center"
            onClick={openModal}
          >
            <FaCrop size={16} className="icon-color mr-1" />
            <span>Crop image</span>
          </Button>
        </ReelerTooltip>
      </div>
      <Modal
        dialogClassName="modal-90w"
        ref={modalRef}
        show={open}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Crop image</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <div className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                  <span
                    style={{
                      width: 30 * (1.91 / 1),
                      height: "30px",
                    }}
                    className={
                      crop?.aspect === 1.91 / 1
                        ? "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center image-ratio-box-selected"
                        : "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center "
                    }
                    onClick={() =>
                      setCrop({
                        aspect: 1.91 / 1,
                        unit: "%",
                        width: 100,
                        x: 0,
                        y: 0,
                      })
                    }
                  >
                    1.91:1
                  </span>
                  <div className="d-flex flex-column justify-content-center align-items-center ">
                    <span
                      style={{
                        width: 30 * (16 / 9),
                        height: "30px",
                      }}
                      className={
                        crop?.aspect === 16 / 9
                          ? "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center image-ratio-box-selected"
                          : "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center "
                      }
                      onClick={() =>
                        setCrop({
                          aspect: 16 / 9,
                          unit: "%",
                          width: 100,
                          x: 0,
                          y: 0,
                        })
                      }
                    >
                      16:9
                    </span>
                    {scale?.naturalWidth >= 1920 &&
                      scale?.naturalHeight >= 1080 && (
                        <span
                          style={{
                            fontSize: "8px",
                            textDecoration: "underline",
                          }}
                          className={
                            crop?.aspect === 16 / 9 && 1920 / scale.scaleX
                              ? "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center image-ratio-box-selected"
                              : "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center "
                          }
                          onClick={() =>
                            setCrop({
                              aspect: 16 / 9,
                              unit: "px",
                              width: 1920 / scale.scaleX,

                              x: 0,
                              y: 0,
                            })
                          }
                        >
                          1920x1080
                        </span>
                      )}
                  </div>
                  <span
                    style={{
                      width: 30 * (4 / 3),
                      height: "30px",
                    }}
                    className={
                      crop?.aspect === 4 / 3
                        ? "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center image-ratio-box-selected"
                        : "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center "
                    }
                    onClick={() =>
                      setCrop({
                        aspect: 4 / 3,
                        unit: "%",
                        width: 100,
                        x: 0,
                        y: 0,
                      })
                    }
                  >
                    4:3
                  </span>
                  <span
                    style={{
                      width: 30 * (5 / 4),
                      height: "30px",
                    }}
                    className={
                      crop?.aspect === 5 / 4
                        ? "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center image-ratio-box-selected"
                        : "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center "
                    }
                    onClick={() =>
                      setCrop({
                        aspect: 5 / 4,
                        unit: "%",
                        width: 100,
                        x: 0,
                        y: 0,
                      })
                    }
                  >
                    5:4
                  </span>

                  <span
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                    className={
                      crop?.aspect === 1
                        ? "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center image-ratio-box-selected"
                        : "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center "
                    }
                    onClick={() =>
                      setCrop({
                        aspect: 1,
                        unit: "%",
                        width: 100,
                        x: 0,
                        y: 0,
                      })
                    }
                  >
                    1:1
                  </span>

                  <span
                    style={{
                      width: 50 * (4 / 5),
                      height: "50px",
                    }}
                    className={
                      crop?.aspect === 4 / 5
                        ? "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center image-ratio-box-selected"
                        : "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center "
                    }
                    onClick={() =>
                      setCrop({
                        aspect: 4 / 5,
                        unit: "%",
                        width: 100,
                        x: 0,
                        y: 0,
                      })
                    }
                  >
                    4:5
                  </span>
                  <span
                    style={{
                      width: 50 * (3 / 4),
                      height: "50px",
                    }}
                    className={
                      crop?.aspect === 3 / 4
                        ? "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center image-ratio-box-selected"
                        : "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center "
                    }
                    onClick={() =>
                      setCrop({
                        aspect: 3 / 4,
                        unit: "%",
                        width: 100,
                        x: 0,
                        y: 0,
                      })
                    }
                  >
                    3:4
                  </span>
                  <span
                    style={{
                      width: 60 * (9 / 16),
                      height: "60px",
                    }}
                    className={
                      crop?.aspect === 9 / 16
                        ? "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center image-ratio-box-selected"
                        : "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center "
                    }
                    onClick={() =>
                      setCrop({
                        aspect: 9 / 16,
                        unit: "%",
                        width: 100,
                        x: 0,
                        y: 0,
                      })
                    }
                  >
                    9:16
                  </span>

                  <span
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                    className={
                      crop?.aspect === undefined
                        ? "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center image-ratio-box-selected"
                        : "d-flex image-ratio-box justify-content-center align-items-center icon-btn text-center "
                    }
                    onClick={() =>
                      setCrop({
                        unit: "%",
                        height: 90,
                        width: 90,
                        x: 0,
                        y: 0,
                      })
                    }
                  >
                    Free
                  </span>
                </div>
                <div className="d-flex justify-content-center">
                  <ReactCrop
                    src={url}
                    crop={crop}
                    onImageLoaded={onLoad}
                    onChange={newCrop => setCrop(newCrop)}
                    onComplete={c => setCompletedCrop(c)}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div className="d-flex flex-column justify-content-between 100h">
                <div className="d-flex flex-column justify-content-center">
                  <h1 class="title">Preview</h1>
                  <canvas
                    ref={previewCanvasRef}
                    crossorigin="anonymous"
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={{
                      width: Math.round(completedCrop?.width ?? 0),
                      height: Math.round(completedCrop?.height ?? 0),
                    }}
                  />

                  <Form.Text muted>
                    {canvas && `${canvas.width}x${canvas.height}`}
                  </Form.Text>
                </div>
              </div>
              <div className="d-flex flex-column mt-3">
                <Form.Group className="mb-3" controlId="versionTitle">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                  />
                  <Form.Text className="text-muted">
                    Set a title for this version
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <ReelerSwitch
                    label="Set as main version?"
                    name="main-version"
                    action={e => setMakeNewVersionMain(!makeNewVersionMain)}
                    checkedBoolean={makeNewVersionMain}
                  />
                </Form.Group>
              </div>
              <Modal.Footer>
                <div
                  className="d-flex flex-row justify-content-end align-items-center mt-2"
                  style={{ width: "100%" }}
                >
                  {false && (
                    <ReelerButton
                      text="Graphics"
                      styleClass="btn-secondary"
                      dispatch={() => addTemplateToCanvas()}
                    />
                  )}

                  <div>
                    <ReelerButton
                      text="Close"
                      styleClass="btn-secondary ml-2"
                      dispatch={closeModal}
                    />
                    <ReelerButton
                      text="Save new version"
                      styleClass="btn-main ml-2"
                      loading={uploading}
                      dispatch={() => handleSaveNewVersion()}
                    />
                  </div>
                </div>
              </Modal.Footer>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}
