import FeedActionTypes from "./feed.types"

export const initiateFeed = payload => ({
  type: FeedActionTypes.INITIATE_FEED,
  payload,
})
export const setLoading = payload => ({
  type: FeedActionTypes.SET_LOADING,
  payload,
})
export const setIsSortingAssets = payload => ({
  type: FeedActionTypes.SET_SORTING_ASSETS,
  payload,
})
export const setFeed = payload => ({
  type: FeedActionTypes.SET_FEED,
  payload,
})

export const updateFeed = payload => ({
  type: FeedActionTypes.UPDATE_FEED,
  payload,
})

export const loadAssets = payload => ({
  type: FeedActionTypes.SET_ASSETS,
  payload,
})
export const updateItemInAssets = payload => ({
  type: FeedActionTypes.UPDATE_ITEM_IN_ASSETS,
  payload,
})
export const setDefaultAssets = payload => ({
  type: FeedActionTypes.SET_DEFAULT_ASSETS,
  payload,
})
export const setFeedType = payload => ({
  type: FeedActionTypes.FEED_TYPE_CHANGE,
  payload,
})

export const resetAllChanges = () => ({
  type: FeedActionTypes.RESET,
})
export const setIsFeedDirty = payload => ({
  type: FeedActionTypes.SET_IS_FEED_DIRTY,
  payload,
})

export const setIsAssetsDirty = payload => ({
  type: FeedActionTypes.SET_IS_ASSETS_DIRTY,
  payload,
})

export const setIsValidToSave = payload => ({
  type: FeedActionTypes.SET_IS_VALID,
  payload,
})

export const setValidationMessage = payload => ({
  type: FeedActionTypes.SET_VALIDATION_MSG,
  payload,
})

export const setGridSettings = payload => ({
  type: FeedActionTypes.GRID_SETTINGS_CHANGE,
  payload,
})

export const setSocialWallSettings = payload => ({
  type: FeedActionTypes.SOCIAL_WALL_SETTINGS_CHANGE,
  payload,
})

export const setCarouselSettings = payload => ({
  type: FeedActionTypes.CAROUSEL_SETTINGS_CHANGE,
  payload,
})

export const setSlideshowSettings = payload => ({
  type: FeedActionTypes.SLIDESHOW_SETTINGS_CHANGE,
  payload,
})

export const setFeedEmbedType = payload => ({
  type: FeedActionTypes.FEED_EMBED_TYPE_CHANGE,
  payload,
})
export const setFeedSortType = payload => ({
  type: FeedActionTypes.FEED_SORT_TYPE_CHANGE,
  payload,
})
export const setFeedTags = payload => ({
  type: FeedActionTypes.SET_FEED_TAGS,
  payload,
})
export const resetToInitialFeedState = () => ({
  type: FeedActionTypes.RESET_TO_INITIAL_STATE,
})
