import React, { useState, useEffect } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"
import "./ReelerTooltip.css"

export default function ReelerTooltip({
  placement = "top",
  text = "",
  children,
}) {
  const [tooltipText, setTooltipText] = useState(text)

  useEffect(() => {
    setTooltipText(text)
  }, [text])

  const id = uuidv4()
  return tooltipText ? (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip id={id}>{tooltipText}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  ) : (
    children
  )
}
