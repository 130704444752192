import React, { useEffect, useState, useRef } from "react"
import { Modal, Form, Row, Col } from "react-bootstrap"

import { useSelector, useDispatch } from "react-redux"
import {
  selectAccountId,
  selectAccounts,
} from "../../../../redux/account/account.selectors"
import withoutProperty from "../../../../utils/withoutProperty"
import { functions, database } from "../../../../firebase"
import ReelerButton from "../../../../components/commons/ReelerButton"
import { useEditor } from "../../../../hooks/useEditor"
import useUserFeedbackMessage from "../../../../hooks/useUserFeedbackMessage"
import { v4 as uuidv4 } from "uuid"
import { updateAccounts } from "../../../../redux/account/account.actions"
import { RIGHTS_EMAIL_CODES } from "../../../../constants"
import CopyToClipboard from "../../../../components/commons/CopyToClipboard"
import { selectCurrentUser } from "../../../../redux/user/user.selectors"

export default function EditEmailTemplate({
  existingTemplate,
  existingIndex,
  btn,
}) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [template, setTemplate] = useState({})
  const [isSendingEmail, setIsSendingEmail] = useState(false)

  const [index, setIndex] = useState(null)
  const [msg, setMsg] = useState(null)
  const [saving, setSaving] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useUserFeedbackMessage()

  const account = useSelector(selectAccounts)
  const user = useSelector(selectCurrentUser)

  // new Product
  const newTemplate = {
    emailTemplateName: "",
    senderAlias: "",
    subject: "",
    body: "",
  }

  function openModal() {
    setMsg(null)
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  useEffect(() => {
    if (existingTemplate) {
      setIndex(existingIndex)
      setTemplate(existingTemplate)
    } else {
      setTemplate(newTemplate)
    }
  }, [existingTemplate])

  const handleUpdateTemplate = (property, value) => {
    setTemplate({ ...template, [property]: value })
  }

  const handleSendEmail = async email => {
    var handleSendEmail = functions.httpsCallable("handleSendEmail")

    const { data } = await handleSendEmail({
      email,
    })
    return data
  }

  const handleSendTestEmail = async () => {
    setIsSendingEmail(true)
    setMsg(null)
    const email = {
      to: user.email,
      from: {
        email: "noreply@reelertech.com",
        name: template?.senderAlias,
      },
      subject: template?.subject,
      text: template?.body.replace(/\r\n|\r|\n/g, "<br />"),
      html: template?.body.replace(/\r\n|\r|\n/g, "<br />"),
    }
    try {
      await handleSendEmail(email)
      setIsSendingEmail(false)
      setSuccessMessage("Test email sent!")
    } catch (err) {
      setIsSendingEmail(false)
      console.log(err)
    }
  }

  const handleSubmit = () => {
    setSaving(true)
    let emailTemplates
    if (index) {
      // update existing template
      emailTemplates = account?.right_request_templates?.emailTemplates.map(
        (t, i) => (i === parseInt(index) ? template : t)
      )
    } else {
      // create a new
      if (account?.right_request_templates?.emailTemplates) {
        emailTemplates = [
          ...account?.right_request_templates?.emailTemplates,
          template,
        ]
        setIndex(emailTemplates.length - 1)
      } else {
        emailTemplates = [template]
        setIndex(0)
      }
    }

    database.accounts
      .doc(account.id)
      .update({
        "right_request_templates.emailTemplates": emailTemplates,
      })
      .then(() => {
        //Update accounts in redux
        dispatch(
          updateAccounts({
            right_request_templates: {
              ...account?.right_request_templates,
              emailTemplates: emailTemplates,
            },
          })
        )
        setSaving(false)
        setSuccessMessage("Saved!")
      })
      .catch(err => {
        console.log(err)
        setSaving(false)
      })
  }

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <div onClick={openModal}>{btn}</div>
      </div>

      <Modal show={open} onHide={closeModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Email template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Template name</Form.Label>
                <Form.Control
                  type="text"
                  value={template?.emailTemplateName}
                  onChange={e =>
                    handleUpdateTemplate("emailTemplateName", e.target.value)
                  }
                />
                <Form.Text muted>
                  Give this email template an internal name.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email sender alias</Form.Label>
                <Form.Control
                  type="text"
                  value={template?.senderAlias}
                  onChange={e =>
                    handleUpdateTemplate("senderAlias", e.target.value)
                  }
                />
                <Form.Text muted>
                  How your email sender name should be displayed in the email
                  client
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email subject</Form.Label>
                <Form.Control
                  type="text"
                  value={template?.subject}
                  onChange={e =>
                    handleUpdateTemplate("subject", e.target.value)
                  }
                />
                <Form.Text muted>
                  Write an email subject for this email template.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email body</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={10}
                  value={template?.body}
                  onChange={e => handleUpdateTemplate("body", e.target.value)}
                />
                <Form.Text muted>
                  Write the email body text for this template. Make sure to
                  include the code{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {RIGHTS_EMAIL_CODES.link}
                  </span>{" "}
                  <CopyToClipboard
                    link={RIGHTS_EMAIL_CODES.link}
                    tooltipText="Copy link code and past in the email body."
                  />{" "}
                  somewhere in your text, where you want the link to the
                  approval page to be inserted. To make your email easier to
                  read, you can include empty lines between the paragraphs. You
                  will be able to personalize or tweak the template text each
                  time you send a rights request by email. Add{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {RIGHTS_EMAIL_CODES.approverFirstName}
                  </span>{" "}
                  <CopyToClipboard
                    link={RIGHTS_EMAIL_CODES.approverFirstName}
                    tooltipText="Copy name code and past in the email body."
                  />{" "}
                  if you want to personalize the email to use the first name in
                  the contact details section of the rights request.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ReelerButton
            text="Close"
            styleClass="btn-secondary"
            dispatch={closeModal}
          />
          <ReelerButton
            loading={isSendingEmail}
            disabled={isSendingEmail}
            dispatch={() => handleSendTestEmail()}
            text="Send test email to yourself"
            styleClass="btn-secondary mr-3"
            spinnerClass="ml-1"
          />
          <ReelerButton
            text="Save"
            styleClass="btn-main"
            loading={saving}
            dispatch={() => handleSubmit()}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
