import React, { useState } from "react"
import { database, storage, functions } from "../../../firebase"

// Third party
import { v4 as uuidV4 } from "uuid"
import { ProgressBar, Col, Row, Alert } from "react-bootstrap"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  selectMedia,
  selectSelectedMedia,
  selectErrorMedia,
  selectSettings,
} from "../../../redux/import-assets/import.selectors"
import { selectCurrentUser } from "../../../redux/user/user.selectors"
import { useAuth } from "../../../contexts/AuthContext"
import { selectAccounts } from "../../../redux/account/account.selectors"
import ReelerButton from "../../commons/ReelerButton"
import MediaFile from "./MediaFile"
import { FaFileImport } from "react-icons/fa"
import IconLink from "../../commons/IconLink/IconLink"
import { CAMPAIGN_TYPE, ASSET_STATUS, ASSET_STATE } from "../../../constants"
import { getAssetMediaType } from "../../../utils/GetAssetMediaType"
import { useEffect } from "react"
import Section from "../../../layouts/core/Section"
import { Header } from "../../../layouts/core/Header"
import Title from "../../../layouts/core/Title"
import Body from "../../../layouts/core/Body"
import BackButton from "../../commons/BackButton"
import { MAIN_ROUTES } from "../../../constants/routes"
import { getMediaMetadata } from "../../../utils/GetMediaMetadata"

export default function ReviewMediaFiles() {
  const [importing, setImporting] = useState(false)
  const [progress, setProgress] = useState(0)
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const [uploadingFileName, setUploadingFileName] = useState("")
  const [unImportedMedia, setUnImportedMedia] = useState([])
  const [numberOfImportedAssets, setNumberOfImportedAssets] = useState(0)
  const selectedMedia = useSelector(selectSelectedMedia)
  const media = useSelector(selectMedia)
  const errors = useSelector(selectErrorMedia)
  const settings = useSelector(selectSettings)
  //const account = useSelector(selectAccounts)
  const user = useSelector(selectCurrentUser)
  const { account } = useAuth()

  const handleImportMedia = () => {
    console.log("Importing...")
    setImporting(true)

    if (settings.replace) {
      importNewVersions()
    } else {
      importNewAssets()
    }
  }

  const importNewVersions = () => {
    // 1 Find the corresping asset
    // 2 heck if it should be main version
    // 3 create a new version
    // 4 upload media
    // 5 save doc
    let noOfSuccessfullyImportedDocs = 0
    let promises = []
    let uploadedBy =
      user.firstName || user.lastName
        ? user.firstName + " " + user.lastName
        : user.email

    selectedMedia.map(async mediaFile => {
      let promise = new Promise(async function (resolve, reject) {
        if (mediaFile?.assetId) {
          let file = mediaFile.file
          let assetId = mediaFile?.assetId

          var docRef = database.assets.doc(assetId)
          let assetDoc = await docRef.get()
          let asset = assetDoc.data()

          if (asset) {
            setProgress(0)
            // Create an upload task. Store UGC file under campaignID

            // Generate an id for filename
            let accessToken = uuidV4()
            const fileFormat = file.name.split(".").pop()
            let newFileName = accessToken + "." + fileFormat
            const originalFilename = file.name
            const fileSize = file.size
            const fileType = file.type
            let filePath = account.id + "/import/"

            const uploadTask = storage.ref(filePath + newFileName).put(file, {
              metadata: {
                metadata: {
                  firebaseStorageDownloadTokens: accessToken,
                },
              },
            })

            // Add object with asset id and url to the array

            uploadTask.on(
              "state_changed",
              snapshot => {
                setProgress(snapshot.bytesTransferred / snapshot.totalBytes)
                setUploadingFileName(file.name)
              },
              err => {
                setUnImportedMedia([...unImportedMedia, mediaFile])
                setUploadError(true)
                setImporting(false)
              },
              async () => {
                uploadTask.snapshot.ref.getDownloadURL().then(async url => {
                  const now = Date.now()
                  let assetUpdateInfo = {}
                  // get metadata
                  const metadata = await getMediaMetadata(
                    file,
                    asset.media_type
                  )

                  let newVersion = {
                    id: uuidV4(),
                    original: false,
                    main: settings.main,
                    file_name: newFileName,
                    file_path: filePath,
                    access_token: accessToken,
                    original_file_name: originalFilename,
                    title: settings?.title,
                    size: fileSize,
                    type: fileType,
                    url: url,
                    modifiedAt: now,
                    createdAt: now,
                    uploadedBy,
                    metadata,
                  }

                  // add the url to the asset doc
                  let updatedArr = asset?.assetVersions

                  if (updatedArr?.length > 0) {
                    // If main is true, then filter out the current main and set its main to false, unless its the same version as beeing updated
                    if (settings.main && updatedArr) {
                      // if new version is main, update asset metadata
                      assetUpdateInfo["metadata"] = metadata
                      updatedArr = updatedArr.map(version => {
                        if (version.main === true) {
                          return { ...version, main: false }
                        }
                        return version
                      })
                    }
                  } else {
                    // If we do not have any existing versions we need to create a new original/main from the asset object and put it in an array
                    let original = {
                      id: uuidV4(),
                      title: "original",
                      file_name: asset?.file_name ? asset?.file_name : "n/a",
                      size: asset?.size ? asset?.size : "n/a",
                      type: asset?.type ? asset?.type : "n/a",
                      url: asset.url,
                      modifiedAt: now,
                      createdAt: asset.createdAt,
                      original: true,
                      main: !settings.main,
                      metadata,
                      uploadedBy,
                    }

                    updatedArr = [original]
                    assetUpdateInfo["metadata"] = metadata
                  }

                  // add new version to versions array
                  updatedArr = [...updatedArr, newVersion]

                  let notes = settings?.notes + "\n" + asset?.notes

                  assetUpdateInfo["assetVersions"] = updatedArr
                  assetUpdateInfo["tags"] = {
                    ...asset.tags,
                    ...settings.tags,
                    reupload: true,
                  }
                  assetUpdateInfo["imported"] = true
                  assetUpdateInfo["notes"] = notes

                  docRef
                    .update(assetUpdateInfo)
                    .then(() => {
                      // Increment number of upploaded content
                      noOfSuccessfullyImportedDocs += 1
                      resolve()
                    })

                    .catch(err => {
                      setUnImportedMedia([...unImportedMedia, mediaFile])
                      console.log(err)
                    })
                })
              }
            )
          }
        }
      })
      promises.push(promise)
    })

    Promise.all(promises)
      .then(() => {
        setNumberOfImportedAssets(noOfSuccessfullyImportedDocs)
        setImporting(false)
        setUploadSuccess(true)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const importNewAssets = () => {
    // Check if we are uploading new versions or creating new assets
    // Create new doc
    let noOfSuccessfullyImportedDocs = 0
    let promises = []
    let uploadedBy =
      user.firstName || user.lastName
        ? user.firstName + " " + user.lastName
        : user.email
    let doc = {
      tags: { ...settings.tags, import: true },
      campaign_type: CAMPAIGN_TYPE.import.type,
      status: ASSET_STATUS.approved,
      titleOfTheWork: settings?.title,
      notes: settings?.notes,
      library: false,
      state: settings?.state,
      imported: true,
      createdAt: database.getCurrentTimestamp(),
      accountId: account.id,
    }

    selectedMedia.map(async mediaFile => {
      let promise = new Promise(function (resolve, reject) {
        let file = mediaFile.file
        setProgress(0)
        // Create an upload task. Store UGC file under campaignID

        // Generate an id for filename
        let accessToken = uuidV4()
        const fileFormat = file.name.split(".").pop()
        let newFileName = accessToken + "." + fileFormat
        const originalFilename = file.name
        const fileSize = file.size
        const fileType = file.type
        let filePath = account.id + "/import/"

        // Retrieve media type
        let mediaType = getAssetMediaType(originalFilename)

        // add file_name, size and type to the doc
        doc["file_name"] = originalFilename
        doc["size"] = fileSize
        doc["type"] = fileType
        doc["extension"] = fileFormat
        doc["media_type"] = mediaType

        const uploadTask = storage.ref(filePath + newFileName).put(file, {
          metadata: {
            metadata: {
              firebaseStorageDownloadTokens: accessToken,
            },
          },
        })

        // Add object with asset id and url to the array

        uploadTask.on(
          "state_changed",
          snapshot => {
            setProgress(snapshot.bytesTransferred / snapshot.totalBytes)
            setUploadingFileName(file.name)
          },
          err => {
            setUnImportedMedia([...unImportedMedia, mediaFile])
            setUploadError(true)
            setImporting(false)
          },
          async () => {
            uploadTask.snapshot.ref.getDownloadURL().then(async url => {
              const now = Date.now()
              let original = {
                id: uuidV4(),
                original: true,
                main: true,
                file_name: newFileName,
                file_path: filePath,
                access_token: accessToken,
                original_file_name: originalFilename,
                title: "original",
                size: fileSize,
                type: fileType,
                url: url,
                modifiedAt: now,
                createdAt: now,
                uploadedBy,
              }

              // get metadata
              const metadata = await getMediaMetadata(file, mediaType)
              original["metadata"] = metadata
              doc["metadata"] = metadata

              // add the url to the asset doc
              doc["url"] = url
              doc["assetVersions"] = [original]

              database.assets
                .add(doc)
                .then(async docRef => {
                  // Check if filename ends with MOV, then call cloudinary to start transcoding
                  if (fileFormat.toLowerCase() === "mov") {
                    // Fetch hashtag media for campaign
                    var onNewMOVMedia = functions.httpsCallable("onNewMOVMedia")

                    const response = await onNewMOVMedia({
                      assetId: docRef.id,
                    })
                  }
                })
                .then(() => {
                  // Increment number of upploaded content
                  noOfSuccessfullyImportedDocs += 1
                  resolve()
                })

                .catch(err => {
                  console.log(err)
                  setUnImportedMedia([...unImportedMedia, mediaFile])
                })
            })
          }
        )
        // end promise
      })
      promises.push(promise)
    })
    Promise.all(promises)
      .then(() => {
        setNumberOfImportedAssets(noOfSuccessfullyImportedDocs)
        setImporting(false)
        setUploadSuccess(true)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    account && (
      <Section>
        <Header>
          <Title>Import content assets</Title>
          <div className="ml-auto">
            <BackButton />
          </div>
        </Header>
        <Body>
          <Row>
            <Col>
              <p>
                Review and select the photos and videos that you like to import
                to your Reeler library.
              </p>
            </Col>
          </Row>
          <Row className="mt-3">
            {media.map((mediaFile, index) => (
              <Col sm={6} md={6} lg={2} className="mb-3 g-1" key={mediaFile.id}>
                <MediaFile mediaFile={mediaFile} />
              </Col>
            ))}
          </Row>

          <Row>
            <Col>
              {!uploadSuccess && progress > 0 ? (
                <div className="d-flex flex-column">
                  <ProgressBar
                    className="mt-3 mb-3"
                    animated={!uploadError}
                    variant={uploadError ? "danger" : "primary"}
                    now={uploadError ? 100 : progress * 100}
                    label={
                      uploadError ? "Error" : `${Math.round(progress * 100)}%`
                    }
                  />
                  <span>Uploading file: {uploadingFileName}</span>
                </div>
              ) : null}
              {uploadSuccess ? (
                <Alert variant={"success"}>
                  {`Successfully imported ${numberOfImportedAssets} files.`}
                </Alert>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex flex-row">
              <IconLink
                url={MAIN_ROUTES.importAssets.path}
                text="Select more files"
                icon={<FaFileImport className="mr-1" />}
                styleClass="mr-3"
              />
              <ReelerButton
                loading={importing}
                disabled={
                  selectedMedia.length === 0 || importing || uploadSuccess
                }
                text={
                  settings.replace
                    ? `Import ${selectedMedia.length} new versions`
                    : `Import ${selectedMedia.length} new assets`
                }
                dispatch={() => handleImportMedia()}
                className="mr-3"
                styleClass="btn-main"
                style={{ width: "120px" }}
                spinnerClass="ml-1"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              {errors.length > 0 ? (
                <p>
                  <small
                    style={{ color: "var(--reeler-danger)" }}
                  >{`Warning: Found ${errors.length} media file(s) that cannot be imported. You can continue to import the other selected media files.`}</small>
                </p>
              ) : null}
              {unImportedMedia.length > 0 ? (
                <div>
                  <p>
                    <small
                      style={{ color: "var(--reeler-danger)" }}
                    >{`Warning: ${unImportedMedia.length} media file(s) could not be imported.`}</small>
                  </p>
                  <ul>
                    {unImportedMedia.map(mediaFile => (
                      <li>
                        <span>{mediaFile.file.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </Col>
          </Row>
        </Body>
      </Section>
    )
  )
}
