import React from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  setFeed,
  setFeedType,
  initiateFeed,
} from "../../../../../redux/feed/feed.actions"
import moment from "moment"

import { FaTh, FaIdCard, FaRegClock, FaEdit } from "react-icons/fa"
import { MdViewCarousel, MdViewDay } from "react-icons/md"
import { RiSlideshow2Fill } from "react-icons/ri"
import { Card, Row } from "react-bootstrap"
import CampaignBadge from "../../../../campaigns/components/CampaignBadge"
import { FeedTypeIcon } from "../../../styles"
import { renderDate } from "../../../../../utils/RenderDate"
import ReelerTooltip from "../../../../commons/ReelerTooltip/ReelerTooltip"
import CopyFeed from "./CopyFeed"
import { MAIN_ROUTES } from "../../../../../constants/routes"
import CountAssetsInFilter from "../../../../commons/CountAssetsInFilter/CountAssetsInFilter"

const FeedsListCard = ({ feed }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSelectFeed = () => {
    dispatch(initiateFeed())
    dispatch(setFeed(feed))
    dispatch(setFeedType(feed.feedType))
    history.push(MAIN_ROUTES.feedsEditFeed.path)
  }

  return (
    <tr>
      <td
        style={{ width: "300px" }}
        className="align-middle"
        onClick={() => handleSelectFeed()}
      >
        {feed?.feedName}
      </td>

      <td style={{ width: "100px", textTransform: "capitalize" }}>
        {feed?.feedType ? feed?.feedType : "web"}
      </td>
      <td style={{ width: "100px", textTransform: "capitalize" }}>
        {feed?.feedLayout}
      </td>
      <td style={{ maxWidth: "100px" }}>
        {feed?.createdAt &&
          moment(new Date(feed?.createdAt?.seconds * 1000)).format(
            "YYYY-MM-DD"
          )}
      </td>

      <td className="text-center">
        <div className="d-flex flex-row justify-content-end algign-items-center">
          <span
            className="d-flex justify-content-center align-items-center mr-2"
            style={{ width: "30px" }}
          >
            <CountAssetsInFilter
              filter={
                feed?.tags && Object.keys(feed?.tags)?.length > 0
                  ? { tags: Object.keys(feed?.tags) }
                  : null
              }
            />
          </span>
          <span
            className="d-flex justify-content-center align-items-center mr-2"
            style={{ width: "30px" }}
          >
            <ReelerTooltip text="Edit feed">
              <FaEdit
                onClick={() => handleSelectFeed()}
                className="icon-color"
              />
            </ReelerTooltip>
          </span>
          <span
            className="d-flex justify-content-center align-items-center mr-2"
            style={{ width: "30px" }}
          >
            <CopyFeed feed={feed} />
          </span>
        </div>
      </td>
    </tr>
  )
}

export default FeedsListCard
