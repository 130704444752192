import React, { useState, useEffect, Suspense } from "react"
import { Row, Col } from "react-bootstrap"
import MediaFiles from "./components/MediaFiles"
import Filter from "./components/FilterSidebar/Filter"
import CollectionInformation from "./components/CollectionInformation/CollectionInformation"
import { FaChevronRight, FaFilter } from "react-icons/fa"
import { HoverIcon, Sidebar, SidebarTeaser } from "./styles"
import BulkEdit from "../bulk-edit/BulkEdit"
import { useParams, Link, useHistory } from "react-router-dom"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { BulkEditReset } from "../../redux/bulk-edit/bulkEdit.actions"
import {
  clearFilter,
  setFilterParameters,
  toggleShowFilter,
} from "../../redux/filter/filter.actions"
import {
  selectActiveFilter,
  selectFilterParameters,
  selectShowFilter,
  selectViewerFilterParameters,
} from "../../redux/filter/filter.selectors"
import { resetImport } from "../../redux/import-assets/import.actions"

import { ShowFilter } from "./components/FilterSidebar/ShowFilter"
import Section from "../../layouts/core/Section"
import { Header } from "../../layouts/core/Header"
import Body from "../../layouts/core/Body"
import Title from "../../layouts/core/Title"
import NumberOfAssets from "./components/NumberOfAssets"
import SpinnerComponent from "../commons/SpinnerComponent"
import ClearFilter from "./components/FilterSidebar/ClearFilter"
import ReelerTooltip from "../commons/ReelerTooltip/ReelerTooltip"
import ReelerButton from "../commons/ReelerButton"
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md"
import { ACCOUNT_PLANS, MAIN_ROUTES, USER_ROLES } from "../../constants/routes"
import { Divider } from "@material-ui/core"
import AuthBasedComponent from "../authentication/AuthBasedComponent"
import { selectCurrentUser } from "../../redux/user/user.selectors"
import Icon from "../commons/Icon"

const MediaLibraryView = () => {
  const { assetId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const filterParameters = useSelector(selectFilterParameters)
  const viewerFilterParameters = useSelector(selectViewerFilterParameters)
  const activeFilter = useSelector(selectActiveFilter)
  const showFilter = useSelector(selectShowFilter)
  const currentUser = useSelector(selectCurrentUser)

  const handleShowFilter = () => dispatch(toggleShowFilter())

  useEffect(() => {
    // Reset selected bulk edit
    dispatch(BulkEditReset())
  }, [])

  useEffect(() => {
    if (currentUser && currentUser?.role !== USER_ROLES.viewer) return

    if (activeFilter) return

    if (assetId) return

    history.push(MAIN_ROUTES.filters.path)
  }, [currentUser, activeFilter, assetId])

  useEffect(() => {
    if (assetId) {
      dispatch(clearFilter())
      dispatch(setFilterParameters({ assetId: assetId }))
    }
  }, [assetId])

  const handleImport = () => {
    dispatch(resetImport())
    history.push(MAIN_ROUTES.importAssets.path)
  }

  return (
    <Section>
      <Header>
        <Title>
          <span className="mr-1">Content</span>
          <NumberOfAssets />
        </Title>
        <div className="d-flex w-100 flex-row align-items-center justify-content-between">
          <div className="d-flex flex-row align-items-center">
            <Icon
              onClick={() => handleShowFilter()}
              tooltip={showFilter ? "Hide filter" : "Show filter"}
              tooltipPlacement="right"
              notification={
                filterParameters
                  ? currentUser?.role === USER_ROLES.viewer
                    ? viewerFilterParameters
                      ? Object.keys(viewerFilterParameters).length
                      : null
                    : Object.keys(filterParameters).length
                  : null
              }
            >
              <MdFilterAlt size={25} />
            </Icon>
          </div>

          <BulkEdit />
          <div>
            <AuthBasedComponent
              plans={[
                ACCOUNT_PLANS.basic,
                ACCOUNT_PLANS.pro,
                ACCOUNT_PLANS.premium,
              ]}
            >
              <ReelerButton
                dispatch={() => handleImport()}
                text="Import"
                styleClass="btn-secondary mr-3"
              />
            </AuthBasedComponent>
            <ReelerButton
              dispatch={() => history.push(MAIN_ROUTES.download.path)}
              text="Downloads"
              styleClass="btn-secondary"
            />
          </div>
        </div>
      </Header>

      <Row className="mx-0 position-relative">
        <Sidebar $show={showFilter} sm={12} md={3}>
          <Filter />
        </Sidebar>

        <SidebarTeaser $show={!showFilter} onClick={() => handleShowFilter()}>
          <ReelerTooltip text="Show filter">
            <div style={{ marginTop: "-60px" }}>
              <FaChevronRight size="1rem" />
            </div>
          </ReelerTooltip>
        </SidebarTeaser>
        <Col className="px-0">
          <CollectionInformation />
          <div style={{ margin: " 16px 1.25rem" }}>
            <Suspense fallback={<SpinnerComponent />}>
              <MediaFiles />
            </Suspense>
          </div>
        </Col>
      </Row>
    </Section>
  )
}

export default MediaLibraryView
