import { createSelector } from "reselect"

const feed = state => state.feed

export const selectFeed = createSelector([feed], feed => feed?.feed)

export const selectFeedSettings = createSelector(
  [feed],
  feed => feed?.feed?.feedSettings
)

export const selectFeedType = createSelector([feed], feed => feed?.feedLayout)

export const selectIsValidToSave = createSelector(
  [feed],
  feed => feed?.isValidToSave
)

export const selectIsFeedDirty = createSelector(
  [feed],
  feed => feed?.isFeedDirty
)
export const selectIsAssetsDirty = createSelector(
  [feed],
  feed => feed?.isAssetsDirty
)
export const selectValidationMsg = createSelector(
  [feed],
  feed => feed?.validationMsg
)
export const selectLoading = createSelector([feed], feed => feed?.loading)
export const selectIsSortingAssets = createSelector(
  [feed],
  feed => feed?.isSortingAssets
)
export const selectAssets = createSelector([feed], feed => feed?.assets)

export const selectPreviewAssets = createSelector([feed], feed =>
  feed?.assets.filter(a => !feed?.feed?.excludedAssets?.includes(a.id))
)

export const selectGridSettings = createSelector(
  [feed],
  feed => feed?.gridSettings
)

export const selectSocialWallSettings = createSelector(
  [feed],
  feed => feed?.socialWallSettings
)

export const selectCarouselSettings = createSelector(
  [feed],
  feed => feed?.carouselSettings
)

export const selectSlideshowSettings = createSelector(
  [feed],
  feed => feed?.slideshowSettings
)

export const selectFeedLayoutSettings = createSelector(
  [feed],
  feed => feed?.feedLayoutSettings
)

export const selectFeedEmbedType = createSelector(
  [feed],
  feed => feed?.feedEmbedType
)

export const selectFeedSortType = createSelector(
  [feed],
  feed => feed?.feedSortType
)
export const selectFeedTags = createSelector([feed], feed => feed?.feedTags)
