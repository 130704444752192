import React, { useState, useEffect } from "react"
import { functions } from "../../../firebase"
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa"

// Third party
import moment from "moment"

import { useParams } from "react-router-dom"
import * as S from "./rightRequest.styled"
import { Container, Row, Col, Modal } from "react-bootstrap"
import Loader from "../../commons/loader/Loader"
import ReelerIconButton from "../../commons/reelerIconButton/ReelerIconButton"
import MediaType from "../../commons/MediaType"
import { ASSET_RIGHT_STATUS } from "../../../constants"

moment.locale()

/**
 *
 * approvalPageTemplate
 * Campaign/source type
 * assets [] Assetid, Instagram Media | ASSET UPLOAD INFO
 * creatorApprovalStatus: CREATED, PENDING, COMPETED
 *
 *
 * Intressant artikel om hur man lägger facebook app id dolt
 * https://jasonwatmore.com/post/2020/10/25/react-facebook-login-tutorial-example
 */

const RightRequest = () => {
  const { linkId } = useParams()
  const [rightRequest, setRightRequest] = useState(null)
  const [open, setOpen] = useState(false)
  const [loadingRightRequest, setLoadingRightRequest] = useState(true)
  const [saving, setSaving] = useState(false)
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false)

  const fetchRightRequest = async linkId => {
    var fetchRightRequest = functions.httpsCallable("fetchRightRequest")

    const { data } = await fetchRightRequest({
      linkId,
    })
    return data
  }
  const handleRightRequest = async (linkId, approval) => {
    var handleRightRequest = functions.httpsCallable("handleRightRequest")

    const { data } = await handleRightRequest({
      linkId,
      approval: approval,
    })
    return data
  }

  useEffect(() => {
    setLoadingRightRequest(true)

    // Fetch asset info from API
    if (linkId) {
      fetchRightRequest(linkId)
        .then(res => {
          console.log(res)
          setLoadingRightRequest(false)
          setRightRequest(res)
        })
        .catch(err => {
          setLoadingRightRequest(false)
          setRightRequest(null)

          console.log(err)
        })
    }
  }, [])

  useEffect(() => {
    window.FB.getLoginStatus(function (response) {
      console.log(response)
      //statusChangeCallback(response);
    })
  }, [])

  if (loadingRightRequest && !rightRequest)
    return (
      <S.Styles>
        <S.LoadingScreen>
          <Loader />
        </S.LoadingScreen>
      </S.Styles>
    )

  const handleApproval = approval => {
    setSaving(true)
    handleRightRequest(linkId, approval)
      .then(res => {
        console.log(res)
        setRightRequest(res)
        setSaving(false)
      })
      .catch(err => {
        console.log(err)
        setSaving(false)
      })
  }

  const openModal = () => {
    setOpen(true)
  }
  const closeModal = () => {
    setOpen(false)
  }

  return !loadingRightRequest && rightRequest ? (
    <S.Styles>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={8}>
            <div className="d-flex justify-content-center p-5">
              {rightRequest?.linkTemplate?.logo_url ? (
                <img
                  alt="Brand logo"
                  style={{
                    objectFit: "contain",
                    maxWidth: "200px",
                    maxHeight: "80px",
                  }}
                  src={rightRequest.linkTemplate?.logo_url}
                />
              ) : null}
            </div>
            <p
              className="lead"
              style={{ fontSize: "1rem", whiteSpace: "pre-line" }}
            >
              {rightRequest?.linkTemplate?.rightsRequestText}
            </p>

            <div className="d-flex flex-column">
              {rightRequest?.linkTemplate?.terms ? (
                <a
                  style={{
                    textDecoration: "underline",
                    color: "var(--reeler-link)",
                  }}
                  href={rightRequest?.linkTemplate?.terms}
                  target="_blank"
                >
                  Terms & Conditions
                </a>
              ) : null}
              {rightRequest?.linkTemplate?.privacyPolicy ? (
                <a
                  style={{
                    textDecoration: "underline",
                    color: "var(--reeler-link)",
                  }}
                  href={rightRequest?.linkTemplate?.privacyPolicy}
                  target="_blank"
                >
                  Privacy policy
                </a>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center pt-4">
          <Col md={6}>
            <div className="d-flex flex-column">
              {rightRequest?.linkStatus !== "COMPLETED" ? (
                <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                  <ReelerIconButton
                    small
                    loading={saving}
                    text="Approve"
                    icon={<FaThumbsUp className="mr-1" />}
                    styleClass="btn-main w-100 mr-1"
                    dispatch={() => handleApproval(ASSET_RIGHT_STATUS.approved)}
                  />

                  <ReelerIconButton
                    small
                    loading={saving}
                    text="Reject"
                    icon={<FaThumbsDown className="mr-1" />}
                    styleClass="btn-delete w-100 ml-1"
                    dispatch={() =>
                      handleApproval(ASSET_RIGHT_STATUS.notApproved)
                    }
                  />
                </div>
              ) : (
                <p>
                  <strong>
                    Status: {rightRequest?.approvalStatus} (
                    {moment(rightRequest.completedAt).format(
                      "YYYY-MM-DD hh:ss"
                    )}
                    )
                  </strong>
                </p>
              )}

              <div className="icon-btn" onClick={openModal}>
                <MediaType asset={rightRequest?.asset} imgSize={500} />
              </div>
              <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton />
                <MediaType asset={rightRequest?.asset} />
              </Modal>
            </div>
          </Col>
        </Row>
        <Row className="p-5">
          <Col>
            <div className="d-flex justify-content-center align-items-center mr-2">
              <small
                style={{
                  color: "var(--reeler-grey-darker)",
                }}
              >
                Powered by
              </small>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="ml-1"
                href="https://www.reelertech.com"
                style={{
                  fontFamily: "Changa One",
                  color: "var(--reeler-grey-darker)",
                }}
              >
                Reeler
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </S.Styles>
  ) : (
    <S.Styles>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={8} className="text-center px-4 py-5 my-5">
            <h1 className="display-5 fw-bold text-body-emphasis">
              No request found
            </h1>
            <p className="lead" style={{ fontSize: "1rem" }}>
              We cannot find the right request you are looking for. Please make
              sure your link is correct.
            </p>
          </Col>
        </Row>
      </Container>
    </S.Styles>
  )
}

export default RightRequest
