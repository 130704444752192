import React from "react"
import ProductModal from "./components/ProductModal"
import ReelerButton from "../commons/ReelerButton"
import ProductList from "./components/ProductList"
import Section from "../../layouts/core/Section"
import { Header } from "../../layouts/core/Header"
import Title from "../../layouts/core/Title"
import Body from "../../layouts/core/Body"
import BackButton from "../commons/BackButton"

export default function ProductsView() {
  return (
    <Section>
      <Header>
        <Title>Products</Title>
        <div className="ml-auto">
          <BackButton />
        </div>
      </Header>
      <Body>
        <p>Add and manage products that can be added to your assets</p>

        <ProductModal
          btn={<ReelerButton text="Add product" styleClass="btn-main" />}
        />
        <ProductList />
      </Body>
    </Section>
  )
}
