import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa"
import parse from "html-react-parser"
import { ReactComponent as DummyImage } from "../../../../assets/images/DummyAsset_optimized.svg"
import MediaType from "../../../../components/commons/MediaType"
import ReelerIconButton from "../../../../components/commons/reelerIconButton/ReelerIconButton"

export default function PreviewRequestLink({ approvalPage, asset }) {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col>
          <div className="d-flex justify-content-center p-5">
            {approvalPage?.logo_url ? (
              <img
                alt="Brand logo"
                style={{
                  objectFit: "contain",
                  maxWidth: "200px",
                  maxHeight: "80px",
                }}
                src={approvalPage?.logo_url}
              />
            ) : (
              <DummyImage
                style={{
                  objectFit: "contain",
                  maxWidth: "200px",
                  maxHeight: "80px",
                }}
              />
            )}
          </div>

          {approvalPage?.rightsRequestText ? (
            parse(approvalPage?.rightsRequestText)
          ) : (
            <div>
              <h1>Lorem ipsum dolor sit amet</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque sit amet justo id tellus ultrices rutrum. Proin
                blandit libero elementum metus porttitor faucibus. Nulla sit
                amet efficitur eros. Nullam facilisis nunc quis nunc vehicula,
                sit amet dapibus eros mollis. Praesent felis massa, porta
                vestibulum mi vel, consectetur feugiat leo
              </p>
            </div>
          )}

          <div className="d-flex flex-column">
            {approvalPage?.terms ? (
              <a
                style={{
                  textDecoration: "underline",
                  color: "var(--reeler-link)",
                }}
                href={approvalPage?.terms}
                target="_blank"
              >
                Terms & Conditions
              </a>
            ) : null}
            {approvalPage?.privacyPolicy ? (
              <a
                style={{
                  textDecoration: "underline",
                  color: "var(--reeler-link)",
                }}
                href={approvalPage?.privacyPolicy}
                target="_blank"
              >
                Privacy policy
              </a>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row className="justify-content-md-center pt-4">
        <Col>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <ReelerIconButton
                small
                text={
                  approvalPage?.approveButtonText
                    ? approvalPage?.approveButtonText
                    : "Approve"
                }
                icon={<FaThumbsUp className="mr-1" />}
                styleClass="btn-main w-100 mr-1"
              />

              <ReelerIconButton
                small
                text={
                  approvalPage?.rejectButtonText
                    ? approvalPage?.rejectButtonText
                    : "Reject"
                }
                icon={<FaThumbsDown className="mr-1" />}
                styleClass="btn-delete w-100 ml-1"
              />
            </div>

            {asset ? <MediaType asset={asset} imgSize={500} /> : <DummyImage />}
          </div>
        </Col>
      </Row>
      <Row className="p-3">
        <Col>
          <div className="d-flex justify-content-center align-items-center mr-2">
            <small
              style={{
                color: "var(--reeler-grey-darker)",
              }}
            >
              Powered by
            </small>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="ml-1"
              href="https://www.reelertech.com"
              style={{
                fontFamily: "Changa One",
                color: "var(--reeler-grey-darker)",
              }}
            >
              Reeler
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
