import React from "react"

export default function AssetTextField({ icon, text }) {
  return (
    <div className="d-flex flex-row align-items-center p-1">
      <div className="d-flex h-100">{icon}</div>

      <div className="pl-3 pr-3 w-100">
        <span className="asset-info-text">{text}</span>
      </div>
    </div>
  )
}
