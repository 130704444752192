import React from "react"
import CreateFeed from "../../header/CreateFeed"
import ReelerIconButton from "../../../../commons/reelerIconButton/ReelerIconButton"
import { FaPlusCircle } from "react-icons/fa"

const CTA = () => {
  return (
    <div className="pt-5 d-flex flex-column justify-content-center align-items-center">
      <img
        className="mb-4"
        width="200px"
        src="../../no_content_women_dog.svg"
        alt=""
      />
      <p className="text-center">
        It looks like you don't have any feeds yet...
        <br />
        Try creating one!
      </p>
      <CreateFeed
        btn={
          <ReelerIconButton
            text="Create feed"
            size="lg"
            icon={<FaPlusCircle className="mr-1" />}
          />
        }
      />
    </div>
  )
}

export default CTA
