import React, { useEffect, useState } from "react"
// import { AddTagsToAsset } from "../campaigns/campaign/components/AddTagsToAsset"
import { BulkAddTagsToAsset } from "./BulkAddTagsToAsset"
import DeleteUGC from "../campaigns/campaign/components/DeleteUGC"
import RateContent from "../campaigns/campaign/components/RateContent"
import {
  selectBulkEditArray,
  selectBulkEditMsg,
} from "../../redux/bulk-edit/bulkEdit.selectors"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../redux/user/user.selectors"
import {
  selectFiltredAssets,
  selectSubFiltredAssets,
} from "../../redux/filter/filter.selectors"
import BulkEditAlert from "../commons/BulkEditAlert/BulkEditAlert"
import ToggleToLib from "./toggle-to-lib/ToggleToLib"
import { useDispatch } from "react-redux"
import {
  BulkEditReset,
  SelectAll,
} from "../../redux/bulk-edit/bulkEdit.actions"
import BulkDownload from "./bulk-download/BulkDownload"
import { BulkAddToFeed } from "./bulk_add_to_feed/BulkAddToFeed"
import * as S from "./styles"
import BulkAssetApprove from "./bulk-asset-approve/BulkAssetApprove"
import BulkDeleteContent from "./bulk-delete/BulkDeleteContent"
import CampaignBadge from "../campaigns/components/CampaignBadge"
import BulkMassDownloadAssets from "./bulk_mass_download_files/bulkMassDownloadFiles"
import { BulkNotes } from "./BulkNotes/BulkNotes"
import { useFixAfterScrolling } from "../../hooks/useFixAfterScrolling"
import AuthBasedComponent from "../authentication/AuthBasedComponent"
import { ACCOUNT_PLANS, USER_ROLES } from "../../constants/routes"

const BulkEdit = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const assetArray = useSelector(selectBulkEditArray)
  const filtredAssets = useSelector(selectFiltredAssets)
  const subFiltredAssets = useSelector(selectSubFiltredAssets)

  const ShowSelectAll = () => {
    const handleSelectAll = () => {
      if (subFiltredAssets?.length > 0) {
        dispatch(SelectAll(subFiltredAssets))
      } else {
        dispatch(SelectAll(filtredAssets))
      }
    }

    if (filtredAssets?.length > 0 || subFiltredAssets?.length > 0) {
      return <span onClick={() => handleSelectAll()}>Select all</span>
    }

    return null
  }

  return (
    <>
      <S.BulkEditContainer
        $positionFixed={false}
        $active={assetArray?.length > 0}
      >
        <div className="d-flex flex-row align-items-center">
          <S.BulkEditItem>Bulk edit</S.BulkEditItem>
          <S.BulkEditItem>
            <CampaignBadge assets={assetArray.length} />
          </S.BulkEditItem>
        </div>
        <AuthBasedComponent
          plans={[
            ACCOUNT_PLANS.basic,
            ACCOUNT_PLANS.pro,
            ACCOUNT_PLANS.premium,
          ]}
        >
          <S.BulkEditItem>
            <BulkAssetApprove assets={assetArray} />
          </S.BulkEditItem>
        </AuthBasedComponent>
        <AuthBasedComponent
          plans={[
            ACCOUNT_PLANS.basic,
            ACCOUNT_PLANS.pro,
            ACCOUNT_PLANS.premium,
          ]}
        >
          <S.BulkEditItem>
            <BulkDeleteContent assets={assetArray} />
          </S.BulkEditItem>
        </AuthBasedComponent>
        <AuthBasedComponent
          plans={[
            ACCOUNT_PLANS.basic,
            ACCOUNT_PLANS.pro,
            ACCOUNT_PLANS.premium,
          ]}
        >
          <S.BulkEditItem>
            <BulkAddTagsToAsset assets={assetArray} />
          </S.BulkEditItem>
        </AuthBasedComponent>
        <AuthBasedComponent
          plans={[
            ACCOUNT_PLANS.basic,
            ACCOUNT_PLANS.pro,
            ACCOUNT_PLANS.premium,
          ]}
          userRoles={[USER_ROLES.editor, USER_ROLES.owner]}
        >
          <S.BulkEditItem>
            <BulkNotes assets={assetArray} />
          </S.BulkEditItem>
        </AuthBasedComponent>
        {/* <RateContent assetId={assetArray} user={currentUser} /> */}

        {/* <BulkDownload assets={assetArray} /> */}
        <AuthBasedComponent
          plans={[
            ACCOUNT_PLANS.basic,
            ACCOUNT_PLANS.pro,
            ACCOUNT_PLANS.premium,
          ]}
          userRoles={[USER_ROLES.editor, USER_ROLES.owner, USER_ROLES.viewer]}
        >
          <S.BulkEditItem>
            <BulkMassDownloadAssets assets={assetArray} />
          </S.BulkEditItem>
        </AuthBasedComponent>
        <S.BulkEditItem>
          <ShowSelectAll />
        </S.BulkEditItem>
        <S.BulkEditItem>
          <span onClick={() => dispatch(BulkEditReset())}>Uncheck all</span>
        </S.BulkEditItem>
      </S.BulkEditContainer>
    </>
  )
}
export default BulkEdit
