import React, { useState, useEffect } from "react"
// 3 party
import { v4 as uuidV4 } from "uuid"
import { Dropdown, Form, Col } from "react-bootstrap"
import { FaRegCircle, FaRegSquare } from "react-icons/fa"
// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  addFormField,
  addNewFormField,
  saveFormField,
  setShowEditModal,
} from "../../../../redux/campaign/campaign.actions"
import {
  selectFormSchema,
  selectedField,
} from "../../../../redux/campaign/campaign.selectors"
import ReelerButton from "../../../commons/ReelerButton"
import ReelerSwitch from "../../../commons/Switch/ReelerSwitch"

import * as S from "../styles"

export default function TermsField({ previousFieldId, closeModal }) {
  const dispatch = useDispatch()
  const formSchema = useSelector(selectFormSchema)
  const fieldId = useSelector(selectedField)

  const [settings, setSettings] = useState({
    widget: "terms",
    format: "check",
    terms: "",
    required: true,
    export: true,
  })

  useEffect(() => {
    if (fieldId && formSchema) {
      const defaultValues = formSchema.properties[fieldId]
      setSettings(defaultValues)
    }
  }, [fieldId, formSchema])

  const handleSubmit = () => {
    // Remove last element since it only is Add option and should not be included
    const field = settings
    //fieldId is existing if we are updating the field, then we should only update the field and not the fieldId and order.
    if (fieldId) {
      dispatch(saveFormField(fieldId, field))
      //dispatch(setShowEditModal(false))
    } else {
      dispatch(addNewFormField(field, previousFieldId))

      closeModal()
    }
  }

  return (
    <Form>
      <Form.Group className="mb-3">
        <Col md={6} className="mx-n3">
          <Form.Label>Give this field an internal name</Form.Label>
          <Form.Control
            type="text"
            value={settings.dataFieldName}
            onChange={e =>
              setSettings({ ...settings, dataFieldName: e.target.value })
            }
          />
          <Form.Text muted>
            Choose an internal name for this field which will be used inside
            Reeler when viewing or exporting data..
          </Form.Text>
        </Col>
      </Form.Group>
      <Form.Group>
        <Form.Label>Add terms</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={settings?.terms}
          onChange={e => setSettings({ ...settings, terms: e.target.value })}
        />
        <Form.Text muted>
          {`Add your terms and contions. Link to external page by adding <a target=“_blank” href='www.yourdomain.se'>Your text</a>`}
        </Form.Text>
      </Form.Group>

      <Form.Group>
        <ReelerSwitch
          label="Required field"
          name="required"
          action={() =>
            setSettings({ ...settings, required: !settings?.required })
          }
          checkedBoolean={settings?.required}
        />
        <Form.Text muted>
          Be aware that require demands all checkboxes to be check. Set required
          to not active if you want to give the user the option to choose
          different options.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3 mt-3">
        <ReelerSwitch
          label="Make this field exportable"
          name="export"
          action={() => setSettings({ ...settings, export: !settings?.export })}
          checkedBoolean={settings?.export}
        />
      </Form.Group>

      <Form.Group>
        <ReelerButton
          dispatch={e => handleSubmit()}
          text="Save"
          className="mr-3"
          styleClass="btn-main"
          spinnerClass="ml-1"
        />
      </Form.Group>
    </Form>
  )
}
