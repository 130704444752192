import React, { useState } from "react"
import { useEffect } from "react"
import { functions } from "../../../firebase"
import SpinnerComponent from "../../commons/SpinnerComponent"
import CampaignBadge from "./CampaignBadge"
import ReelerBadge from "../../commons/ReelerBadge"

export default function CampaignNumberOfAssets({ campaignId }) {
  const [loading, setLoading] = useState(true)
  const [assets, setAssets] = useState(0)

  useEffect(() => {
    if (campaignId) {
      setLoading(true)

      const CountNumberOfAssetsForCampaign = async () => {
        var CountNumberOfAssetsForCampaign = functions.httpsCallable(
          "CountNumberOfAssetsForCampaign"
        )

        try {
          const { data } = await CountNumberOfAssetsForCampaign({
            campaignId: campaignId,
          })
          setAssets(data.noOfAssets)
          setLoading(false)
        } catch (err) {
          console.log(err)
        }
      }

      CountNumberOfAssetsForCampaign()
    }
  }, [campaignId])

  if (loading) return <SpinnerComponent size="sm" />

  return <ReelerBadge value={assets} />
}
