import FeedActionTypes from "./feed.types"

const INITIAL_STATE = {
  feed: {
    embedType: "div",
    sortType: "addedToFeedAt",
    assetCount: 0,
    hidden: false,
    feedType: "grid",
    feedSettings: {
      widgetHeight: 250,
      columns: 3,
      rows: 3,
      gutter: 0,
      borderRadius: 0,
    },
  },
  feedTags: {},
  defaultAssets: [],
  assets: [],
  loading: false,
  isSortingAssets: false,
  feedType: null,
  isValidToSave: true,
  validationMsg: null,
  isFeedDirty: false,
  isAssetsDirty: false,
  gridSettings: {
    widgetHeight: 250,
    columns: 3,
    rows: 3,
    gutter: 0,
    borderRadius: 0,
  },
  carouselSettings: {
    widgetHeight: 250,
    dots: false,
    infinite: false,
    speed: 500,
    gutter: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    arrowColor: "#7f7f7f",
    borderRadius: 0,
  },
  socialWallSettings: {
    columns: 4,
    gutter: 1,
    borderRadius: 3,
    showCaption: true,
    showShadow: true,
  },
  slideshowSettings: {
    dots: false,
    effect: false,
    infinite: false,
    likes: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    timePerAsset: 1000,
    username: false,
  },
  feedLayoutSettings: {
    autoScroll: false,
    initial: 3,
  },
}

const feedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FeedActionTypes.INITIATE_FEED:
      return INITIAL_STATE
    case FeedActionTypes.SET_FEED:
      return {
        ...state,
        feed: action.payload,
      }
    case FeedActionTypes.UPDATE_FEED:
      return {
        ...state,
        feed: { ...state.feed, ...action.payload },
      }
    case FeedActionTypes.SET_DEFAULT_ASSETS:
      return {
        ...state,
        defaultAssets: action.payload,
      }
    case FeedActionTypes.SET_ASSETS:
      return {
        ...state,
        assets: action.payload,
      }
    case FeedActionTypes.UPDATE_ITEM_IN_ASSETS:
      return {
        ...state,
        assets: state.assets.map((item, index) => {
          if (item.id !== action.payload.id) {
            // This isn't the item we care about - keep it as-is
            return item
          }

          // Otherwise, this is the one we want - return an updated value
          return {
            ...item,
            ...action.payload,
          }
        }),
      }
    case FeedActionTypes.RESET:
      return {
        ...state,
        assets: state.defaultAssets,
        isFeedDirty: false,
        isAssetsDirty: false,
      }
    case FeedActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case FeedActionTypes.SET_SORTING_ASSETS:
      return {
        ...state,
        isSortingAssets: action.payload,
      }
    case FeedActionTypes.SET_IS_VALID:
      return {
        ...state,
        isValidToSave: action.payload,
      }
    case FeedActionTypes.SET_VALIDATION_MSG:
      return {
        ...state,
        validationMsg: action.payload,
      }
    case FeedActionTypes.SET_IS_FEED_DIRTY:
      return {
        ...state,
        isFeedDirty: action.payload,
      }
    case FeedActionTypes.SET_IS_ASSETS_DIRTY:
      return {
        ...state,
        isAssetsDirty: action.payload,
      }
    case FeedActionTypes.GRID_SETTINGS_CHANGE:
      return {
        ...state,
        gridSettings: action.payload,
      }

    case FeedActionTypes.CAROUSEL_SETTINGS_CHANGE:
      return {
        ...state,
        carouselSettings: action.payload,
      }
    case FeedActionTypes.SOCIAL_WALL_SETTINGS_CHANGE:
      return {
        ...state,
        socialWallSettings: action.payload,
      }
    case FeedActionTypes.SLIDESHOW_SETTINGS_CHANGE:
      return {
        ...state,
        slideshowSettings: action.payload,
      }
    case FeedActionTypes.FEED_LAYOUT_SETTINGS_CHANGE:
      return {
        ...state,
        feedLayoutSettings: action.payload,
      }
    case FeedActionTypes.FEED_TYPE_CHANGE:
      return {
        ...state,
        feedType: action.payload,
      }
    case FeedActionTypes.FEED_EMBED_TYPE_CHANGE:
      return {
        ...state,
        feedEmbedType: action.payload,
      }
    case FeedActionTypes.FEED_SORT_TYPE_CHANGE:
      return {
        ...state,
        feedSortType: action.payload,
      }
    case FeedActionTypes.SET_FEED_TAGS:
      return {
        ...state,
        feedTags: action.payload,
      }
    case FeedActionTypes.RESET_TO_INITIAL_STATE:
      return {
        ...INITIAL_STATE,
      }
    default:
      return state
  }
}

export default feedReducer
