import React, { useRef, useState, useEffect } from "react"
import axios from "axios"
import { database } from "../../../../../../firebase"
import { useSelector } from "react-redux"
import { Modal, Row, Col, Form, Alert } from "react-bootstrap"
import ReelerButton from "../../../../../commons/ReelerButton"
import { selectAccounts } from "../../../../../../redux/account/account.selectors"
import { FaRegHeart, FaInstagram } from "react-icons/fa"
import { BsChat } from "react-icons/bs"
import { IoPaperPlaneOutline } from "react-icons/io5"
import { storage } from "../../../../../../firebase"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"
import MediaType from "../../../../../commons/MediaType"
import ConnectToInstagram from "../../../../../commons/ConnectToInstagram"
import ReelerIconButton from "../../../../../commons/reelerIconButton/ReelerIconButton"
import {
  IMG_SIZES,
  MEDIA_TYPE,
  PUBLISHING_JOB_STATUS,
  PUBLISHING_JOB_TYPES,
} from "../../../../../../constants"

export default function AssetPublishToInstagram({ asset }) {
  const [open, setOpen] = useState(false)
  const [openPublish, setOpenPublish] = useState(false)
  const [publishing, setPublishing] = useState(false)
  const [msg, setMsg] = useState()
  const [instagramBusinessAccount, setInstagramBusinessAccount] = useState(null)

  const account = useSelector(selectAccounts)
  const [caption, setCaption] = useState("")
  const [reels, setReels] = useState(false)

  const modalRef = useRef()

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  function openModalPublish() {
    setOpenPublish(true)
  }

  function closeModalPublish() {
    setOpenPublish(false)
  }

  useEffect(() => {
    if (account && !instagramBusinessAccount) {
      setInstagramBusinessAccount(account?.integrations?.instagram?.accounts[0])
    }
  }, [account])

  const handleChangeIGBusinessAccount = id => {
    const igBusinessAccount =
      account?.integrations?.instagram?.accounts?.filter(
        account => account.ig_business_account_profile.id === id
      )
    setInstagramBusinessAccount(igBusinessAccount[0])
  }
  /**
   * Use the POST /{ig-user-id}/media endpoint to upload a photo and create an IG Container.
   */

  const createAnIGContainer = async (
    businessAccountId,
    accessToken,
    mediaType,
    mediaUrl,
    caption
  ) => {
    // Need to check if the content is IMAGE or VIDEO, different API calls

    let apiUrl

    let downloadableURL = await storage.refFromURL(mediaUrl).getDownloadURL()
    let downloadableURI = encodeURIComponent(downloadableURL)

    let encodedCaption = encodeURIComponent(caption)

    switch (mediaType) {
      case "IMAGE":
        apiUrl = `https://graph.facebook.com/v16.0/${businessAccountId}/media?image_url=${downloadableURI}&caption=${encodedCaption}&access_token=${accessToken}`
        break
      case "VIDEO":
        apiUrl = `https://graph.facebook.com/v16.0/${businessAccountId}/media?media_type=VIDEO&video_url=${downloadableURI}&caption=${encodedCaption}&access_token=${accessToken}`
        break
      case "REELS":
        apiUrl = `https://graph.facebook.com/v16.0/${businessAccountId}/media?media_type=REELS&video_url=${downloadableURI}&caption=${encodedCaption}&access_token=${accessToken}`
        break
      default:
        return false
    }
    console.log(apiUrl)

    const { data } = await axios({
      url: apiUrl,
      method: "post",
    })
    return data
  }

  /**
   * Use the POST /{ig-user-id}/media_publish endpoint to publish the photo using its container.
   */

  const handlePublishToInstagram = () => {
    setMsg(null)
    setPublishing(true)

    createAnIGContainer(
      instagramBusinessAccount.ig_business_account_profile.id,
      account?.integrations?.instagram?.long_lived_user_token,
      reels ? "REELS" : asset.media_type,
      asset.url,
      caption
    )
      .then(instagramIGContainer => {
        // 1) Check container status
        // 2) Create a new document with container id
        // 3) Build a function that runns every 5 minute
        // that checks container status and publish when status is FINISHED

        // Get the container status

        let newPublishingJob = {
          type: PUBLISHING_JOB_TYPES.instagram,
          containerId: instagramIGContainer.id,
          ig_business_account_id:
            instagramBusinessAccount.ig_business_account_profile.id,
          access_token: instagramBusinessAccount.long_lived_access_token,
          assetId: asset.id,
          accountId: asset.accountId,
          caption: caption,
          reels: reels,
          status: PUBLISHING_JOB_STATUS.inProgress,
          createdAt: database.getCurrentTimestamp(),
        }

        database.publishingJobs
          .add(newPublishingJob)
          .then(() => {
            setMsg({
              text: "Content successfully published on Instagram.",
              variant: "success",
            })
            setPublishing(false)
          })
          .catch(err => {
            setMsg({
              text: "We could not publish the content to Instagram. Please contact Reeler support.",
              variant: "danger",
            })
            console.log(err)
            setPublishing(false)
          })
      })
      .catch(err => {
        console.log(err)
        if (err.response.data.error) {
          setMsg({
            text: err.response.data.error.message,
            variant: "danger",
          })
          setPublishing(false)
        } else {
          setMsg({
            text: "We could not publish the content to Instagram. Please contact Reeler support.",
            variant: "danger",
          })
          setPublishing(false)
        }
      })
  }

  return (
    <>
      <ReelerTooltip text="Publish asset to Instagram">
        <ReelerIconButton
          text=""
          styleClass="btn-secondary"
          dispatch={openModal}
          icon={<FaInstagram size={16} className="icon-color icon-btn mr-1" />}
        />
      </ReelerTooltip>

      <Modal
        dialogClassName="modal-90w"
        ref={modalRef}
        show={open}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Publish to Instagram</Modal.Title>
        </Modal.Header>

        {account &&
        account.integrations &&
        account.integrations.instagram &&
        account.integrations.instagram.accounts &&
        account?.integrations?.instagram?.long_lived_user_token ? (
          <>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Choose Instagram account</Form.Label>
                    <Form.Control
                      as="select"
                      name="ig_business_account"
                      value={
                        instagramBusinessAccount?.ig_business_account_profile
                          ?.id
                      }
                      onChange={e =>
                        handleChangeIGBusinessAccount(e.target.value)
                      }
                    >
                      {account?.integrations?.instagram?.accounts.map(
                        (account, key) => (
                          <option
                            key={key}
                            value={account.ig_business_account_profile.id}
                          >
                            {account.ig_business_account_profile.username}
                          </option>
                        )
                      )}
                    </Form.Control>
                    <Form.Text muted>
                      Choose which Instagram business account that this hashtag
                      source should be accociated with.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Caption</Form.Label>

                    <Form.Control
                      as="textarea"
                      rows={6}
                      defaultValue={
                        asset?.edited_caption ? asset?.edited_caption : null
                      }
                      onChange={e => {
                        setCaption(e.target.value)
                      }}
                    />

                    <Form.Text muted>
                      Add a caption text to the asset. You can include #hashtags
                      and @usernames (mentioned users will get a notification
                      when you publish). Max 2,200 characters, 30 hashtags, and
                      20 @usernames.
                    </Form.Text>
                  </Form.Group>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      disabled={
                        asset?.media_type !== MEDIA_TYPE.VIDEO ? true : false
                      }
                      className="custom-control-input"
                      id="reels"
                      checked={reels}
                      name="reels"
                      onChange={() => setReels(!reels)}
                    />
                    <label className="custom-control-label" htmlFor="reels">
                      Post as Instagram Reel
                    </label>
                  </div>
                </Col>
                <Col>
                  <div
                    className="d-flex justify-content-center"
                    style={{ background: "var(--reeler-grey)" }}
                  >
                    <div
                      className="asset"
                      style={{
                        width: "290px",
                        margin: "10px",
                      }}
                    >
                      <div className="d-flex flex-row p-2 align-items-center">
                        {instagramBusinessAccount &&
                        instagramBusinessAccount?.ig_business_account_profile
                          .profile_picture_url ? (
                          <img
                            className="mr-2"
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50px",
                            }}
                            src={
                              instagramBusinessAccount
                                ?.ig_business_account_profile
                                .profile_picture_url
                            }
                            alt=""
                          />
                        ) : (
                          <span
                            className="mr-2"
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50px",
                              background: "#63453f",
                            }}
                          ></span>
                        )}

                        {instagramBusinessAccount &&
                          instagramBusinessAccount.ig_business_account_profile
                            .username && (
                            <span
                              style={{ fontWeight: "700", fontSize: "10px" }}
                              className="mr-1"
                            >
                              {
                                instagramBusinessAccount
                                  .ig_business_account_profile.username
                              }
                            </span>
                          )}
                      </div>
                      <div>
                        <MediaType asset={asset} imgSize={IMG_SIZES.preView} />
                      </div>
                      <div className="p-2">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="d-flex flex-row">
                            <FaRegHeart className="mr-2" />
                            <BsChat className="mr-2" />
                            <IoPaperPlaneOutline />
                          </div>
                          <div className="d-flex flex-row"></div>
                        </div>

                        <p className="asset-info-text pt-2 text-wrap">
                          {instagramBusinessAccount &&
                            instagramBusinessAccount.ig_business_account_profile
                              .username && (
                              <span
                                style={{ fontWeight: "700", fontSize: "10px" }}
                                className="mr-1"
                              >
                                {
                                  instagramBusinessAccount
                                    .ig_business_account_profile.username
                                }
                              </span>
                            )}
                          <span style={{ whiteSpace: "pre-line" }}>
                            {caption}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex flex-row justify-content-end align-items-center mt-2">
                <ReelerButton
                  text="Close"
                  styleClass="btn-secondary"
                  dispatch={closeModal}
                />
                <ReelerButton
                  text="Publish"
                  styleClass="btn-main ml-2"
                  dispatch={openModalPublish}
                />
              </div>
            </Modal.Footer>
          </>
        ) : (
          <ConnectToInstagram />
        )}
      </Modal>
      <Modal centered show={openPublish} onHide={closeModalPublish}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to publish this post to Instagram?
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {msg && (
            <Alert style={{ textAlign: "center" }} variant={msg.variant}>
              {msg.text}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div
            className="mr-4"
            variant="secondary"
            onClick={closeModalPublish}
            style={{ cursor: "pointer" }}
          >
            Close
          </div>
          <ReelerButton
            loading={publishing}
            disabled={msg?.variant === "success" ? true : false}
            dispatch={() => handlePublishToInstagram()}
            text="Publish now"
            styleClass="btn-main"
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
