import React, { useState, useRef } from "react"
//Redux
import { useDispatch, useSelector } from "react-redux"
import {
  addMedia,
  updateSettings,
  resetImport,
} from "../../../redux/import-assets/import.actions"
import {
  selectSettings,
  selectMedia,
} from "../../../redux/import-assets/import.selectors"
import { useHistory } from "react-router-dom"

// Third party
import { v4 as uuidV4 } from "uuid"

// Reeler
import { Form, Col, Row } from "react-bootstrap"
import * as S from "../importAssets-style"
import ImportTags from "./ImportTags"
import ReelerButton from "../../commons/ReelerButton"

import { ASSET_STATE } from "../../../constants"
import Section from "../../../layouts/core/Section"
import { Header } from "../../../layouts/core/Header"
import Title from "../../../layouts/core/Title"
import Body from "../../../layouts/core/Body"
import BackButton from "../../commons/BackButton"
import { MAIN_ROUTES } from "../../../constants/routes"

const UploadMediaFiles = () => {
  // Refs
  const filesRef = useRef()
  const dispatch = useDispatch()
  const history = useHistory()

  const settings = useSelector(selectSettings)
  const media = useSelector(selectMedia)
  const [saving, setSaving] = useState(false)
  const [fileMessage, setFileMessage] = useState(null)

  const handleUpload = () => {
    const files = filesRef.current.files
    const mediaFiles = []
    setFileMessage(null)

    if (files === null) return

    Array.from(files).forEach(file => {
      let format = file.name.split(".").pop().toLowerCase()
      console.log(format)
      let allowedFormats = [
        "mp4",
        "mov",
        "png",
        "jpg",
        "jpeg",
        "tiff",
        "tif",
        "mp3",
        "wav",
        "ogg",
        "m4a",
        "aif",
        "aiff",
      ]
      if (!allowedFormats.includes(format)) {
        setFileMessage(
          "File format can only be mp4, mov, png, jpg, jpeg, mp3, wav or ogg."
        )
        return
      }

      if (file.size > 1000000000) {
        setFileMessage("File size is too big. File size limit is 1GB")
        return
      }
      // Cannot upload duplicates
      if (media.some(m => m.file.name === file.name)) {
        setFileMessage("File already exists in the list.")
        return
      }
      mediaFiles.push({ id: uuidV4(), tags: {}, file: file })
      console.log("Media check ok", mediaFiles)
    })
    console.log("Media files ", mediaFiles)
    if (mediaFiles.length > 0) {
      console.log("Adding media to redux")
      dispatch(addMedia(mediaFiles))
    }
  }

  const handleNext = () => {
    history.push(MAIN_ROUTES.importAssetsReview.path)
  }

  return (
    <Section>
      <Header>
        <Title>Import content assets</Title>
        <div className="ml-auto">
          <BackButton />
        </div>
      </Header>
      <Body>
        <Row className="mb-4">
          <Col sx={12} md={6}>
            <Form.Group>
              <Form.Check
                type="radio"
                id={`import-new-assets`}
                label={<strong>Import new assets</strong>}
                onChange={() => dispatch(updateSettings({ replace: false }))}
                checked={!settings?.replace}
              />
              <Form.Text muted>
                If you have content assets that you have collected outside of
                Reeler, you can import them here to your Reeler content library.
                The source type will be set as "Import".
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="radio"
                id={`import-replace-assets`}
                label={<strong>Update existing assets</strong>}
                onChange={() => dispatch(updateSettings({ replace: true }))}
                checked={settings?.replace}
              />
              <Form.Text muted>
                If you have downloaded content assets from Reeler and modified
                them, you can bulk-upload your new versions here. Each file name
                must end with the correct unique Reeler content asset ID,
                starting with "_R" (e.g. _R0123456789abcdefghjj).
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col sx={12} md={6}>
            <Form.Label>Title (optional)</Form.Label>
            <Form.Control
              type="text"
              value={settings?.title}
              onChange={e =>
                dispatch(updateSettings({ title: e.target.value }))
              }
            />
            <Form.Text muted>
              {settings?.replace
                ? "Write a version title that will be set on new version"
                : "Optionally write a content title (will be the same for all content assets in this batch)"}
            </Form.Text>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col sx={12} md={6}>
            <Form.Label>Note (optional)</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="Leave a note"
              value={settings?.notes}
              onChange={e =>
                dispatch(updateSettings({ notes: e.target.value }))
              }
            />
            <Form.Text muted>
              Optionally save a note (will be the same for all content assets in
              this batch)
            </Form.Text>
          </Col>
        </Row>
        {settings?.replace ? (
          <Row className="mb-3">
            <Col sx={12} md={6}>
              <Form.Group controlId="default" className="mb-3">
                <Form.Label>Set new versions as "Main version"?</Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    inline
                    id={`main-yes`}
                    label={`Yes`}
                    onChange={() =>
                      dispatch(updateSettings({ main: !settings?.main }))
                    }
                    checked={settings?.main}
                  />
                  <Form.Check
                    type="radio"
                    inline
                    id={`main-no`}
                    label={`No`}
                    onChange={() =>
                      dispatch(updateSettings({ main: !settings?.main }))
                    }
                    checked={!settings?.main}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
        ) : null}
        {!settings?.replace ? (
          <Row className="mb-3">
            <Col sx={12} md={6}>
              <Form.Group controlId="default" className="mb-3">
                <Form.Label>Approve new assets?</Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    inline
                    id={`approve`}
                    label={`Yes, set imported assets as “approved” (green checkmark)`}
                    onChange={() =>
                      dispatch(updateSettings({ state: ASSET_STATE.approved }))
                    }
                    checked={settings?.state === ASSET_STATE.approved}
                  />
                  <Form.Check
                    type="radio"
                    inline
                    id={`library-no`}
                    label={`No, set imported assets as “unreviewed”`}
                    onChange={() =>
                      dispatch(
                        updateSettings({ state: ASSET_STATE.unreviewed })
                      )
                    }
                    checked={settings?.state === ASSET_STATE.unreviewed}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            <Form.Group controlId="formFileMultiple" className="mb-3">
              <Form.Label>Choose files to import</Form.Label>
              <Form.Control
                size="sm"
                ref={filesRef}
                type="file"
                multiple
                onChange={() => handleUpload()}
              />
              <Form.Text muted>
                Reeler supports video (.mp4, .mov), images (.png, .jpg, .jpeg,
                .tif, .tiff) and audio (.mp3, .wav, .m4a, .ogg, .aif, .aiff).
              </Form.Text>

              {fileMessage ? (
                <S.FileMessageContainer>{fileMessage}</S.FileMessageContainer>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group controlId="formFileMultiple" className="mb-3">
              <Form.Label>Add content tags</Form.Label>
              <ImportTags />
              <Form.Text muted>
                Tags are used to categorize and find content later in your
                Reeler content library. Here, you can set tags that will be
                added to all assets in this import batch. Press “Enter” after
                typing each tag. Later, you can manually add additional tags to
                individual content assets.
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <ReelerButton
              loading={saving}
              disabled={media.length > 0 ? false : true}
              text={`Review ${media.length} files to import >>`}
              dispatch={() => handleNext()}
              className="mr-3"
              styleClass="btn-main"
              style={{ width: "120px" }}
              spinnerClass="ml-1"
            />
          </Col>
        </Row>
      </Body>
    </Section>
  )
}

export default UploadMediaFiles
