import React from "react"
import { Dropdown, Row, Col, Form } from "react-bootstrap"
import { FaHistory } from "react-icons/fa"
import * as S from "./styles"
import Icon from "../../../../../../commons/Icon"
import { renderDate } from "../../../../../../../utils/RenderDate"

export const RightsRequesStatusLog = ({ rightsRequest }) => {
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      // onMouseOver={() => setShow(true)}
      // onMouseOut={() => setShow(false)}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </div>
  ))

  return rightsRequest?.id ? (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} drop="start">
        <span>
          <Icon
            notification={rightsRequest?.approvalStatusLog?.length}
            tooltip="Show rights request history"
          >
            <FaHistory />
          </Icon>
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="reeler-dropdown">
        <div className="p-2 mb-3" style={{ width: "500px" }}>
          <div className="mb-2">
            <strong>Rights request history log</strong>
          </div>
          <Row className="mb-3">
            <Col>
              {rightsRequest?.approvalStatusLog
                ?.sort((a, b) => b.createdAt - a.createdAt)
                ?.map((status, index) => (
                  <div key={index} className="d-flex flex-row">
                    <div className="d-flex justify-content-center align-items-center mr-2">
                      <S.ApprovalStatus status={status?.status} />
                    </div>
                    <div className="mr-2">
                      <Form.Text>
                        {status?.note ? status?.note : status?.status}
                      </Form.Text>
                    </div>
                    <div>
                      <Form.Text>
                        ({renderDate(status?.createdAt, "YYYY-MM-DD")})
                      </Form.Text>
                    </div>
                  </div>
                ))}
            </Col>
          </Row>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  ) : null
}
