import React, { useEffect, useRef, useState } from "react"
import { Form, Card } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import CenteredContainer from "../commons/CenteredContainer"
import { useDispatch, useSelector } from "react-redux"
import { emailSignInStart } from "../../redux/user/user.actions"
import {
  selectUserError,
  selectCurrentUser,
} from "../../redux/user/user.selectors"
import ReelerButton from "../commons/ReelerButton"
import StaticAlert from "../commons/StaticAlert/StaticAlert"
import { MAIN_ROUTES, PUBLIC_ROUTES } from "../../constants/routes"

const Login = () => {
  const errorMessage = useSelector(selectUserError)
  const currentUser = useSelector(selectCurrentUser)
  const history = useHistory()
  const dispatch = useDispatch()
  const emailRef = useRef()
  const passwordRef = useRef()
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (currentUser) {
      history.push(MAIN_ROUTES.content.path)
    }
  }, [currentUser])

  async function handleSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value.length > 127) {
      return setError({ success: false, text: "Password to long" })
    }

    if (emailRef.current.value.length > 256) {
      return setError({ success: false, text: "Invalid email address" })
    }
    setLoading(true)

    dispatch(
      emailSignInStart({
        email: emailRef.current.value,
        password: passwordRef.current.value,
      })
    )
    setLoading(false)
  }

  useEffect(() => {
    if (errorMessage) {
      setError({ text: "The password is invalid or the email does not exist." })
    } else {
      setError("")
    }
  }, [errorMessage])

  return (
    <CenteredContainer>
      <h1 className="text-center mb-5 logo">Reeler</h1>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Login</h2>
          {error && <StaticAlert msg={error} />}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <ReelerButton
              text="Login"
              disabled={loading}
              styleClass="btn-main w-100"
              type="submit"
            />
          </Form>
          <div className="w-100 text-center mt-3">
            <Link className="link" to={PUBLIC_ROUTES.forgotPassword.path}>
              Forgot Password?
            </Link>
          </div>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2 ">
        Need an account?{" "}
        <a href="https://www.reelertech.com/new-account">Sign Up</a>
      </div>
    </CenteredContainer>
  )
}

export default Login
