import React, { useState, useEffect, useRef } from "react"
import firebase from "firebase/app"
import { useSelector, useDispatch } from "react-redux"
import { saveCampaign } from "../../../../redux/campaign/campaign.actions"
import { selectCampaign } from "../../../../redux/campaign/campaign.selectors"
import { Form, InputGroup, FormControl } from "react-bootstrap"
import parse from "html-react-parser"
import SpinnerComponent from "../../../commons/SpinnerComponent"
import { FaRegCheckCircle, FaExclamationCircle, FaTrash } from "react-icons/fa"
import { database } from "../../../../firebase"
import axios from "axios"
import ReelerButton from "../../../commons/ReelerButton"
import ReelerIconButton from "../../../commons/reelerIconButton/ReelerIconButton"
export default function SearchHashtag({
  ig_business_id,
  long_lived_token,
  campaignId,
}) {
  const dispatch = useDispatch()
  const campaign = useSelector(selectCampaign)
  const [loading, setLoading] = useState(false)
  const [change, setChange] = useState(false)
  const [query, setQuery] = useState()
  const [msg, setMsg] = useState(null)

  const getIGHashtagID = async (userId, searchTerm, access_token) => {
    const { data } = await axios({
      url: `https://graph.facebook.com/v16.0/ig_hashtag_search?user_id=${userId}&q=${searchTerm}&access_token=${access_token}`,
      method: "get",
    })

    return data
  }

  const handleGetHashtagId = () => {
    setLoading(true)
    setMsg(null)
    // Get hashtag id for hashtag

    const hashtag = query.replace(/#+/g, "")

    getIGHashtagID(ig_business_id, hashtag, long_lived_token)
      .then(data => {
        let hashtag_id = data.data[0].id

        database.campaigns
          .doc(campaignId)
          .update({
            "hashtag.id": hashtag_id,
            "hashtag.name": hashtag,
            "hashtag.createdAt": new Date(),
            tags: {
              [hashtag]: true,
            },
          })
          .then(() => {
            dispatch(
              saveCampaign({
                ...campaign,
                hashtag: {
                  id: hashtag_id,
                  name: hashtag,
                  createdAt: new Date(),
                },
                tags: {
                  ...campaign.tags,
                  [hashtag]: true,
                },
              })
            )
            setChange(false)
            setLoading(false)
          })
      })
      .catch(err => {
        if (err.response.data.error.error_subcode === 2207034) {
          setMsg(
            "You can query a maximum of 30 unique hashtags on behalf of your Instagram Business Account within a rolling, 7 day period. Once you query a hashtag, it will count against this limit for 7 days. Subsequent queries on the same hashtag within this time frame will not count against your limit, and will not reset its initial query 7 day timer."
          )
        } else if (err.response.data.error.error_subcode === 2207024) {
          setMsg(
            "The hashtag has to already exist on Instagram. If you want to use a new hashtag, please begin by posting the hashtag anywhere on Instagram – then please try again to register it in Reeler."
          )
        } else if (err.response.data.error.error_subcode === 463) {
          setMsg(
            "The connection to Instagram has expired and you need to refresh the Instagram connection. <a class='notification-link' href='/settings/integrations'>Refresh connection here</a>"
          )

          // TODO: Update the account and remove the exisiting connection
        } else if (err.response.data.error.error_user_title != undefined) {
          setMsg(err.response.data.error.error_user_title)
        } else {
          setMsg(err.response.data.error.message)
        }

        setLoading(false)
      })
  }

  const removeHashtag = () => {
    setLoading(true)

    database.campaigns
      .doc(campaignId)
      .update({
        hashtag: firebase.firestore.FieldValue.delete(),
      })
      .then(() => {
        database.campaigns.doc(campaignId).set(
          {
            tags: {
              [campaign.hashtag.name]: firebase.firestore.FieldValue.delete(),
            },
          },
          { merge: true }
        )
      })
      .then(() => {
        delete campaign.hashtag
        dispatch(
          saveCampaign({
            ...campaign,
          })
        )
        setChange(false)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }

  return loading ? (
    <SpinnerComponent size="sm" />
  ) : (
    <>
      {campaign && campaign?.hashtag ? (
        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <div className="d-flex flex-row align-items-center">
            <div className="d-flex p-1 icon-reeler mr-3">
              <FaRegCheckCircle size={25} />
            </div>
            <div>#{campaign.hashtag?.name}</div>
          </div>
          <div className="d-flex justify-content">
            <div className="mr-2">
              <ReelerButton
                text={change ? "Undo" : "Change"}
                dispatch={() => setChange(!change)}
                styleClass="btn-secondary btn-sm"
              />
            </div>

            <ReelerIconButton
              text="Remove"
              loading={loading}
              icon={<FaTrash className="reeler-icon mr-1" />}
              dispatch={() => removeHashtag()}
              styleClass="btn-secondary btn-sm"
            />
          </div>
        </div>
      ) : null}
      {msg ? (
        <div className="d-flex mt-3 flex-row justify-content-between">
          <div className="d-flex flex-row">
            <div className="d-flex align-items-center p-1 icon-reeler">
              <FaExclamationCircle style={{ color: "var(--reeler-danger)" }} />
            </div>
            <div>{parse(msg)}</div>
          </div>
        </div>
      ) : null}

      {change || !campaign.hashtag ? (
        <div>
          <InputGroup
            style={{
              borderRadius: ".25rem",
              background: "#fff",
              border: "1px solid lightgrey",
            }}
          >
            <InputGroup.Text
              className="pr-0"
              style={{ border: "none", background: "none" }}
            >
              #
            </InputGroup.Text>

            <FormControl
              style={{ border: "none" }}
              type="text"
              placeholder="Add a hashtag to track"
              value={query ? query : ""}
              onChange={e => setQuery(e.target.value.toLowerCase())}
              required
            />
            <ReelerButton
              text="Verify"
              id="button-addon2"
              loading={loading}
              styleClass="btn-secondary"
              disabled={query?.trim().length > 1 ? false : true}
              dispatch={() => handleGetHashtagId()}
            />
          </InputGroup>

          <Form.Text muted>
            Select a hashtag to track. The hashtag must already exist somewhere
            on Instagram.
          </Form.Text>
        </div>
      ) : null}
    </>
  )
}
