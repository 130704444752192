import styled from "styled-components"

const iconSizes = {
  sm: "10px",
  md: "30px",
  lg: "40px",
}

export const Icon = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  color: ${({ active }) => (active ? "var(--reeler)" : "var(--reeler-icon)")};
  width: ${({ size }) =>
    size === "sm"
      ? iconSizes.sm
      : size === "md"
      ? iconSizes.md
      : size === "lg"
      ? iconSizes.lg
      : "30px"};
  height: ${({ size }) =>
    size === "sm"
      ? iconSizes.sm
      : size === "md"
      ? iconSizes.md
      : size === "lg"
      ? iconSizes.lg
      : "30px"};

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

  &:hover {
    background: var(--reeler-grey);
  }
`
export const IconNotification = styled.span`
  aspect-ratio: 1/1;

  width: ${props => (props.$width ? `${props.$width}px` : "15px")};
  height: ${props => (props.$height ? `${props.$height}px` : "15px")};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: var(--reeler-light);
  color: var(--reeler-icon);
  border-radius: 50%;
  font-size: 10px;

  position: absolute;
  top: ${props => (props.$top ? `${props.$top}px` : "0")};
  right: ${props => (props.$right ? `${props.$right}px` : "0")};
`
