import React, { useEffect, useState } from "react"

// 3rd-party
import { v4 as uuidV4 } from "uuid"
import { Col, Form } from "react-bootstrap"
import styled from "styled-components"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  addFormField,
  addNewFormField,
  saveFormField,
  setShowEditModal,
} from "../../../../redux/campaign/campaign.actions"
import {
  selectedField,
  selectFormSchema,
} from "../../../../redux/campaign/campaign.selectors"

// Reeler components
import ReelerButton from "../../../commons/ReelerButton"
import ReelerSwitch from "../../../commons/Switch/ReelerSwitch"
import UnitInputGroup from "../../../commons/UnitInputGroup/UnitInputGroup"
import ColorPicker from "../../../commons/ColorPicker"
import { addNewFormFieldAsync } from "../../../../redux/campaign/campaign.sagas"

const DisabledLabel = styled.label`
  color: #d9d9d9;
  font-weight: 500;
`

const DividerField = ({ previousFieldId, closeModal }) => {
  const dispatch = useDispatch()
  const formSchema = useSelector(selectFormSchema)
  const fieldId = useSelector(selectedField)

  const [settings, setSettings] = useState({
    widget: "divider",
    height: 24,
    line: true,
    lineColor: "#252525",
    lineThickness: 1,
    width: 100,
    export: false,
  })

  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (fieldId && formSchema) {
      const defaultValues = formSchema.properties[fieldId]
      setSettings(defaultValues)
    }
  }, [fieldId])

  const handleSubmit = async () => {
    setSaving(true)
    const field = settings
    //fieldId is existing if we are updating the field, then we should only update the field and not the fieldId and order.

    if (fieldId) {
      dispatch(saveFormField(fieldId, field))
      setSaving(false)

      //dispatch(setShowEditModal(false))
    } else {
      dispatch(addNewFormField(field, previousFieldId))

      setSaving(false)
      closeModal()
    }
  }

  return (
    <Form>
      <Form.Group controlId="height">
        <Col md={6} className="mx-n3">
          <UnitInputGroup
            value={settings?.height}
            dispatch={e =>
              setSettings({ ...settings, height: Number(e.target.value) })
            }
            unit="px"
          />
          <Form.Text muted>Sets margins on top and below the element</Form.Text>
        </Col>
      </Form.Group>
      <Form.Group controlId="line">
        <ReelerSwitch
          label="Do you want a line in the divider?"
          name="line"
          action={() => setSettings({ ...settings, line: !settings?.line })}
          checkedBoolean={settings?.line}
        />
      </Form.Group>

      {settings.line ? (
        <Form.Group controlId="lineColor">
          <Form.Label>Line color</Form.Label>
          <ColorPicker
            state={settings?.lineColor}
            dispatch={hex => setSettings({ ...settings, lineColor: hex })}
          />
        </Form.Group>
      ) : (
        <Form.Group controlId="lineColor">
          <DisabledLabel>Line color</DisabledLabel>
          <ColorPicker state={"#d9d9d9"} disabled />
        </Form.Group>
      )}

      <Form.Group controlId="lineThickness">
        <Col md={6} className="mx-n3">
          {settings.line ? (
            <Form.Label>Line thickness</Form.Label>
          ) : (
            <DisabledLabel>Line thickness</DisabledLabel>
          )}

          <UnitInputGroup
            value={settings?.lineThickness}
            dispatch={e =>
              setSettings({
                ...settings,
                lineThickness: Number(e.target.value),
              })
            }
            disabled={!settings?.line}
            unit="px"
          />
          <Form.Text muted>How thick should the divider line be?</Form.Text>
          {settings.line ? (
            <Form.Label>Line width</Form.Label>
          ) : (
            <DisabledLabel>Line width</DisabledLabel>
          )}

          <UnitInputGroup
            value={settings?.width}
            dispatch={e =>
              setSettings({
                ...settings,
                width: Number(e.target.value),
              })
            }
            max={100}
            disabled={!settings?.line}
            unit="%"
          />
          <Form.Text muted>How wide should the divider line be?</Form.Text>
        </Col>
      </Form.Group>

      <Form.Group>
        <ReelerButton
          loading={saving}
          disabled={saving}
          dispatch={e => handleSubmit()}
          text="Save"
          className="mr-3"
          styleClass="btn-main"
          spinnerClass="ml-1"
        />
      </Form.Group>
    </Form>
  )
}

export default DividerField
