import React, { useEffect } from "react"

// 3rd-party
import { Modal, Row, Col, ModalFooter, Button } from "react-bootstrap"
import { IoIosCloseCircleOutline } from "react-icons/io"
// Redux
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  setCloseAssetInfoModal,
  setActiveAsset,
} from "../../../../../redux/asset/asset.actions"
import {
  selectActiveAsset,
  selectActiveSidebarTab,
  selectShowAssetInfoModal,
} from "../../../../../redux/asset/asset.selectors"
import { selectFiltredAssets } from "../../../../../redux/filter/filter.selectors"
import { selectCurrentUser } from "../../../../../redux/user/user.selectors"

// Reeler components
import AssetDownload from "./components/AssetDownload"
import ReelerButton from "../../../../commons/ReelerButton"
import AssetSidebar from "./components/AssetSidebar"
import AssetNotes from "./components/AssetNotes"
import AssetRate from "./components/AssetRate"
import AssetRights from "./components/AssetRights"
import AssetTagsRedux from "./components/AssetTagsRedux"
import AssetPublish from "./components/AssetPublish"
import AssetVersions from "./components/AssetVersions"
import AssetDetails from "./components/AssetDetails"
import DeleteUGC from "../DeleteUGC"
import AssetStatusRedux from "./components/AssetStatusRedux"
import * as S from "../styles"
import AssetNotesComments from "./components/AssetNotesComments"
import MediaTypeRedux from "../../../../commons/MediaTypeRedux"
import { useKeyPress } from "../../../../../hooks/useKeyPress"
import AssetApprove from "./components/asset-approve/AssetApprove"
import ReelerTooltip from "../../../../commons/ReelerTooltip/ReelerTooltip"
import AssetProducts from "./components/AssetProducts/AssetProducts"
import CloseButton from "../../../../commons/CloseButton"
import { ACCOUNT_PLANS } from "../../../../../constants/routes"
import AuthBasedComponent from "../../../../authentication/AuthBasedComponent"
import { MEDIA_TYPE } from "../../../../../constants"

const AssetInfoModal = () => {
  const dispatch = useDispatch()
  const showAssetInfoModal = useSelector(selectShowAssetInfoModal)
  const asset = useSelector(selectActiveAsset)
  const assets = useSelector(selectFiltredAssets)
  const activeSidebarTab = useSelector(selectActiveSidebarTab)
  const currentUser = useSelector(selectCurrentUser)
  // const ArrowRight = useKeyPress("ArrowRight")
  // const ArrowLeft = useKeyPress("ArrowLeft")

  const closeModal = () => {
    dispatch(setCloseAssetInfoModal())
  }

  const loadNextAsset = () => {
    let index = assets.indexOf(asset)
    if (index < assets.length - 1) {
      dispatch(setActiveAsset(assets[index + 1]))
    }
  }
  const loadPrevAsset = () => {
    let index = assets.indexOf(asset)
    if (index !== 0) {
      dispatch(setActiveAsset(assets[index - 1]))
    }
  }

  // useEffect(() => {
  //   if (ArrowRight) {
  //     loadNextAsset()
  //   }
  // }, [ArrowRight])

  // useEffect(() => {
  //   if (ArrowLeft) {
  //     loadPrevAsset()
  //   }
  // }, [ArrowLeft])

  const renderTab = () => {
    switch (activeSidebarTab) {
      case "info":
        return (
          <>
            <S.ModalTabHeading>Info</S.ModalTabHeading>
            <AssetDetails />
          </>
        )

      case "rating":
        return (
          <>
            <S.ModalTabHeading>Rating, Notes & Comments</S.ModalTabHeading>
            <AssetNotesComments />
          </>
        )

      case "products":
        return (
          <>
            <S.ModalTabHeading>Products</S.ModalTabHeading>
            <AssetProducts />
          </>
        )

      case "rights":
        return (
          <>
            <S.ModalTabHeading>Rights</S.ModalTabHeading>
            <AssetRights />
          </>
        )

      case "labels":
        return (
          <>
            <S.ModalTabHeading>Labels</S.ModalTabHeading>
            <AssetTagsRedux />
          </>
        )

      case "publish":
        return (
          <>
            <S.ModalTabHeading>Publish</S.ModalTabHeading>
            <AssetPublish />
          </>
        )

      case "versions":
        return (
          <>
            <S.ModalTabHeading>Versions</S.ModalTabHeading>
            <AssetVersions />
          </>
        )

      default:
        return "Could not load component"
    }
  }
  return asset ? (
    <Modal
      id="asset-info"
      show={showAssetInfoModal}
      onHide={closeModal}
      size="lg"
      style={{ marginTop: "3rem" }}
    >
      <Modal.Body>
        <div className="d-flex flex-row">
          <div className="d-flex flex-column pr-0" style={{ flex: "1" }}>
            {/* Image box */}
            <div
              style={{
                height: "500px",
                width: "100%",
                position: "relative",
                padding: "1rem",
              }}
            >
              <div style={{ position: "absolute", top: 0, right: 0 }}>
                <AssetStatusRedux />
              </div>
              <MediaTypeRedux asset={asset} />
            </div>

            <S.ModalIconBar className="mb-md-0">
              <AssetApprove asset={asset} />
            </S.ModalIconBar>
          </div>
          <div
            className="d-flex flex-column justify-content-between"
            style={{ flex: "1" }}
          >
            <div
              className="d-flex flex-row flex-nowrap ml-0"
              style={{ height: "540px", width: "100%" }}
            >
              <div className="p-0">
                <AssetSidebar />
              </div>
              <div className="pl-2 justify-space-between w-100 h-100 position-relative">
                <S.CloseIconAssetInfoModal onClick={() => closeModal()}>
                  <ReelerTooltip text="Close window">
                    <CloseButton />
                  </ReelerTooltip>
                </S.CloseIconAssetInfoModal>

                {renderTab()}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <ModalFooter>
        <AuthBasedComponent
          plans={[
            ACCOUNT_PLANS.basic,
            ACCOUNT_PLANS.pro,
            ACCOUNT_PLANS.premium,
          ]}
        >
          <DeleteUGC asset={asset} buttonText={"Delete"} />
        </AuthBasedComponent>
        {asset?.media_type !== MEDIA_TYPE.TEXT ? (
          <AssetDownload asset={asset} />
        ) : null}

        <ReelerButton
          text="Close"
          dispatch={closeModal}
          styleClass="btn-secondary"
        />
        <Button variant="secondary" size="sm" onClick={() => loadPrevAsset()}>
          {"<< Prev"}
        </Button>
        <Button variant="secondary" size="sm" onClick={() => loadNextAsset()}>
          {"Next >>"}
        </Button>
      </ModalFooter>
    </Modal>
  ) : null
}

export default AssetInfoModal
