import React, { useState } from "react"

// 3rd-party libraries
import { useHistory, Link } from "react-router-dom"

// Redux
import { useSelector, useDispatch } from "react-redux"
import firebase from "firebase/app"
import { database } from "../../../../../firebase"
import { selectAccountId } from "../../../../../redux/account/account.selectors"
import {
  resetAllChanges,
  initiateFeed,
} from "../../../../../redux/feed/feed.actions"
import {
  selectFeed,
  selectFeedType,
  selectFeedTags,
  selectIsFeedDirty,
  selectAssets,
  selectIsAssetsDirty,
  selectIsValidToSave,
  selectValidationMsg,
  selectGridSettings,
  selectCarouselSettings,
  selectSocialWallSettings,
  selectFeedLayoutSettings,
} from "../../../../../redux/feed/feed.selectors"

// 3rd-party components
import { Row, Modal, ModalBody } from "react-bootstrap"
import { FaTrashAlt } from "react-icons/fa"

// Reeler components
import ReelerButton from "../../../../commons/ReelerButton"
import ReelerIconButton from "../../../../commons/reelerIconButton/ReelerIconButton"
import FadeAlert from "../../../../commons/FadeAlert/FadeAlert"
import StaticAlert from "../../../../commons/StaticAlert/StaticAlert"
import { SubmitSectionContainer } from "../../../styles"
import SaveButton from "./SaveButton"
import { MAIN_ROUTES } from "../../../../../constants/routes"

const SubmitSection = () => {
  // Modal controls
  const [msg, setMsg] = useState(null)
  const [loading, setLoading] = useState(null)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // states

  const history = useHistory()
  const accountId = useSelector(selectAccountId)
  const isValidToSave = useSelector(selectIsValidToSave)
  const isDirty = useSelector(selectIsFeedDirty)
  const validationMessage = useSelector(selectValidationMsg)
  const feed = useSelector(selectFeed)

  const dispatch = useDispatch()

  const handleDeleteFeed = feedId => {
    setLoading(true)
    database.feeds
      .doc(feedId)
      .delete()
      .then(() => {
        dispatch(initiateFeed())
        history.push(MAIN_ROUTES.feeds.path)
        setLoading(false)
      })
      .catch(err => console.error(err))
  }

  const handleReset = () => {
    dispatch(resetAllChanges())
  }

  return (
    <SubmitSectionContainer>
      <Row className="d-flex justify-content-center mx-1">
        {msg && <FadeAlert msg={msg} />}

        {!isValidToSave && (
          <StaticAlert
            msg={{
              success: false,
              lead: "Check again!",
              text: validationMessage,
            }}
          />
        )}
      </Row>
      <Row className="justify-content-end mr-1">
        <ReelerButton
          text="All feeds"
          styleClass="btn-secondary mr-2"
          dispatch={() => history.push(MAIN_ROUTES.feeds.path)}
        />

        <ReelerButton
          text="Reset"
          styleClass="btn-secondary mr-2"
          dispatch={() => handleReset()}
          disabled={!isDirty}
        />
        {/* MODAL */}
        <Modal centered show={show} onHide={handleClose}>
          <ModalBody>
            <Modal.Title>
              Are you sure you want to delete this feed?
            </Modal.Title>
            <Modal.Body>
              If you delete this feed you cannot recreate it.
            </Modal.Body>
            <Modal.Footer>
              <ReelerButton
                text="Close"
                styleClass="btn-secondary"
                dispatch={handleClose}
              />
              <ReelerIconButton
                icon={<FaTrashAlt className="mr-1" />}
                dispatch={e => handleDeleteFeed(feed?.id)}
                text="Delete feed"
                styleClass="btn-delete"
              />
            </Modal.Footer>
          </ModalBody>
        </Modal>

        <ReelerIconButton
          icon={<FaTrashAlt className="mr-1" />}
          loading={loading}
          dispatch={e => handleShow(e)}
          text="Delete"
          styleClass="btn-delete mr-2"
          spinnerClass="ml-1"
        />

        <SaveButton />
      </Row>
    </SubmitSectionContainer>
  )
}

export default SubmitSection
