import React, { useState } from "react"
import { Row, Col, Tabs, Tab, Badge } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import { selectAccounts } from "../../../redux/account/account.selectors"
import Section from "../../../layouts/core/Section"
import { Header } from "../../../layouts/core/Header"
import Title from "../../../layouts/core/Title"
import Body from "../../../layouts/core/Body"
import ManageConsentHashtags from "./ManageConsentHashtags"
import ConsentTextMessageTemplates from "./ConsentTextMessageTemplates"
import BackButton from "../../commons/BackButton"

export default function CollectConsentHashtagView() {
  const [key, setKey] = useState("manage-hashtags")
  const account = useSelector(selectAccounts)

  return (
    <Section>
      <Header>
        <Title>Consent hashtags</Title>
        <div className="ml-auto">
          <BackButton />
        </div>
      </Header>
      <Body>
        <Tabs activeKey={key} onSelect={k => setKey(k)}>
          <Tab
            tabClassName="link"
            eventKey="manage-hashtags"
            title={
              <div className="d-flex flex-row">
                <span>Add consent hashtags</span>
                {account?.integrations?.instagram?.rightsRequests
                  ?.consentHashtags ? (
                  <Badge className="d-flex align-items-center ml-1 badge-main">
                    {
                      account?.integrations?.instagram?.rightsRequests
                        ?.consentHashtags.length
                    }
                  </Badge>
                ) : (
                  <Badge className="badge-main">0</Badge>
                )}
              </div>
            }
          >
            <div
              style={{
                backgroundColor: "#fff",
                border: "1px solid rgba(0, 0, 0, 0.125)",
                borderTop: "none",
                borderRadius: "0 0 0.25rem 0.25rem",
                padding: "0.75rem 0.5rem",
              }}
            >
              <ManageConsentHashtags />
            </div>
          </Tab>

          {/* <Tab
            tabClassName="link"
            eventKey="manage-text-messages"
            title={
              <div className="d-flex flex-row">
                <span>Consent text messages</span>
                {account?.right_request_templates?.consentTextMessages ? (
                  <Badge className="d-flex align-items-center ml-1 badge-main">
                    {
                      account?.right_request_templates?.consentTextMessages
                        ?.length
                    }
                  </Badge>
                ) : (
                  <Badge className="badge-main">0</Badge>
                )}
              </div>
            }
          >
            <div
              style={{
                backgroundColor: "#fff",
                border: "1px solid rgba(0, 0, 0, 0.125)",
                borderTop: "none",
                borderRadius: "0 0 0.25rem 0.25rem",
                padding: "0.75rem 0.5rem",
              }}
            >
              <ConsentTextMessageTemplates />
            </div>
          </Tab> */}
        </Tabs>
      </Body>
    </Section>
  )
}
