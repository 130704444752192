import React, { useState } from "react"
import { Modal, ModalBody } from "react-bootstrap"
import { database } from "../../../../firebase"
import { FaTrashAlt } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"
import ReelerButton from "../../../commons/ReelerButton"
import ReelerIconButton from "../../../commons/reelerIconButton/ReelerIconButton"
import {
  clearActiveAsset,
  updateAsset,
} from "../../../../redux/asset/asset.actions"
import { selectActiveAsset } from "../../../../redux/asset/asset.selectors"
import { ASSET_STATE } from "../../../../constants"

export default function DeleteUGC({ size = 14, buttonText }) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const asset = useSelector(selectActiveAsset)

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  const handleDeleteUGC = async () => {
    let deletedAsset = { ...asset, deletedAt: database.getCurrentTimestamp() }
    database.deletedAssets
      .doc(asset.id)
      .set(deletedAsset)
      .then(() => {
        database.assets
          .doc(asset.id)
          .delete()
          .then(() => {
            dispatch(clearActiveAsset())

            setLoading(false)
            closeModal()
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err)
      })
  }

  // const handleDeleteUGC = async () => {
  //   let promises = []
  //   if (asset.url) {
  //     try {
  //       let promise = await storage.refFromURL(`${asset.url}`).delete()
  //       promises.push(promise)
  //     } catch (error) {}
  //   }

  //   if (asset.assetVersions) {
  //     asset.assetVersions.forEach(async version => {
  //       try {
  //         if (version.url !== asset.url) {
  //           let promise = await storage.refFromURL(version.url).delete()
  //           promises.push(promise)
  //         }
  //       } catch (error) {}
  //     })
  //   }

  //   Promise.all(promises)
  //     .then(() => {
  //       database.assets
  //         .doc(asset.id)
  //         .delete()
  //         .then(() => {
  //           dispatch(clearActiveAsset())

  //       setLoading(false)
  //       closeModal()

  //         })

  //         .catch(error => {})
  //     })
  //     .catch(error => console.log("Something went wrong", error))
  // }

  return (
    <>
      {buttonText ? (
        <div
          className="d-flex flex-row align-items-center mr-3 link"
          style={{ cursor: "pointer" }}
          onClick={openModal}
        >
          <ReelerTooltip text="Delete asset from Reeler">
            <>
              <FaTrashAlt className="mr-2" /> {buttonText}
            </>
          </ReelerTooltip>
        </div>
      ) : (
        <ReelerTooltip text="Delete asset from Reeler">
          <FaTrashAlt
            onClick={openModal}
            size={size}
            className="icon-color"
            style={{ cursor: "pointer" }}
          />
        </ReelerTooltip>
      )}
      <Modal centered show={open} onHide={closeModal}>
        <ModalBody>
          <Modal.Title>Are you sure you want to delete this asset?</Modal.Title>
          <Modal.Body>Deleted content cannot be recreated</Modal.Body>
          <Modal.Footer>
            <ReelerButton
              text="Close"
              dispatch={closeModal}
              styleClass="btn-secondary"
            />
            <ReelerIconButton
              text="Delete content"
              icon={<FaTrashAlt className="mr-1" />}
              styleClass="btn-delete"
              dispatch={() => handleDeleteUGC()}
            />
          </Modal.Footer>
        </ModalBody>
      </Modal>
    </>
  )
}
