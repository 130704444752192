import React, { useRef, useState } from "react"
import { CloseButton, Dropdown, InputGroup } from "react-bootstrap"
import CreatableSelect from "react-select/creatable"
import { FaTags } from "react-icons/fa"
import ReelerTooltip from "../commons/ReelerTooltip/ReelerTooltip"
import { useSelector, useDispatch } from "react-redux"
import firebase from "firebase/app"
import { database, firestore } from "../../firebase"
import _ from "lodash"
import {
  BulkEditActionSuccess,
  BulkEditMessageReset,
} from "../../redux/bulk-edit/bulkEdit.actions"
import { updateAccounts } from "../../redux/account/account.actions"

import {
  selectAccountId,
  selectAccounts,
} from "../../redux/account/account.selectors"
import SpinnerComponent from "../commons/SpinnerComponent"
import Tag from "../commons/Tag"
const customStyles = {
  container: () => ({
    // none of react-select's styles are passed to <Control />
    width: `100%`,
  }),
}
export const BulkAddTagsToAsset = ({ assets }) => {
  const [show, setShow] = useState(false)

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [removeTag, setRemoveTag] = useState(false)
  const [tag, setTag] = useState("")
  const [msg, setMsg] = useState("")
  const account = useSelector(selectAccounts)
  const accountId = useSelector(selectAccountId)

  const bulkAddNewTak = async str => {
    setLoading(true)
    setMsg(`Updating ${assets.length} asset/assets. Please wait.`)
    let accountTags = []
    let tags = {}

    // remove #, separate and transform string into tags
    str
      .toLowerCase()
      .replace(/#+/g, "")
      .split(" ")
      .forEach(tag => {
        tags[tag] = true
        // add to account tags
        accountTags.push(tag)
      })

    const batches = _.chunk(assets, 500).map(assetDocs => {
      const batch = firestore.batch()
      assetDocs.forEach(asset => {
        let assetRef = database.assets.doc(asset.id)

        batch.set(assetRef, { tags }, { merge: true })
      })
      return batch.commit()
    })

    const recentTags = account?.recent_tags
      ? [
          ...account?.recent_tags.filter(tag => !accountTags.includes(tag)),
          ...accountTags,
        ].slice(-20)
      : [...accountTags].slice(-20)

    await Promise.all(batches)
      .then(() => {
        database.accounts
          .doc(accountId)
          .update({
            recent_tags: recentTags,
            tags: firebase.firestore.FieldValue.arrayUnion(...accountTags),
          })
          .then(() => {
            // Update redux
            dispatch(
              updateAccounts({
                recent_tags: recentTags,
                tags: account?.tags
                  ? [...account?.tags, ...accountTags]
                  : accountTags,
              })
            )
          })
          .catch(err => {
            setLoading(false)
          })
        dispatch(
          BulkEditActionSuccess({
            type: "success",
            duration: 3000,
            lead: "Tags",
            text: "added successfully",
            position: "bulkEdit",
          })
        )

        setLoading(false)
        setShow(false)
      })
      .catch(err => {
        console.log(err)
        dispatch(
          BulkEditActionSuccess({
            type: "danger",
            duration: 3000,
            lead: "Error",
            text: "Could not update assets with new tags",
            position: "bulkEdit",
          })
        )
      })
  }

  const handleRemoveTag = async tag => {
    setLoading(true)
    setMsg(`Updating ${assets.length} asset/assets. Please wait.`)

    const batches = _.chunk(assets, 500).map(assetDocs => {
      const batch = firestore.batch()
      assetDocs.forEach(asset => {
        let assetRef = database.assets.doc(asset.id)

        batch.set(
          assetRef,
          {
            tags: {
              [tag]: firebase.firestore.FieldValue.delete(),
            },
          },
          { merge: true }
        )
      })
      return batch.commit()
    })

    await Promise.all(batches).then(() => {
      dispatch(
        BulkEditActionSuccess({
          type: "success",
          duration: 3000,
          lead: "Tags",
          text: "removed",
          position: "bulkEdit",
        })
      )

      setLoading(false)
      setShow(false)
    })
  }

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="flex-row pt-1 align-middle d-flex"
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </div>
  ))

  const handleTag = tag => {
    if (removeTag) {
      handleRemoveTag(tag.trim().toLowerCase())
    } else {
      bulkAddNewTak(tag.trim().toLowerCase())
    }
  }
  return (
    <Dropdown onToggle={() => setShow(!show)} show={show}>
      <Dropdown.Toggle as={CustomToggle} id="manage-tags" drop="start">
        <ReelerTooltip placement="bottom" text="Add/Remove labels">
          <FaTags className="icon-color icon-btn" />
        </ReelerTooltip>
      </Dropdown.Toggle>

      <Dropdown.Menu className="reeler-dropdown">
        <CloseButton onClick={() => setShow(false)} />
        <div className="p-2 mb-3" style={{ width: "300px" }}>
          <div className="mb-2">
            <strong>Manage content labels:</strong>
          </div>

          <div className="d-flex flex-column">
            {loading ? (
              <div>
                <SpinnerComponent />
                <p>{msg}</p>
              </div>
            ) : (
              <>
                <div className="custom-control custom-switch mb-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="infinite"
                    checked={removeTag}
                    name="remove"
                    onChange={() => setRemoveTag(!removeTag)}
                  />
                  <label className="custom-control-label" htmlFor="infinite">
                    <small>Remove label from assets</small>
                  </label>
                </div>
                <div className="d-flex flex-column mb-1">
                  <small>Recently used labels:</small>
                  <div>
                    {account?.recent_tags
                      ?.slice(-4)
                      .reverse()
                      .map((tag, index) => (
                        <ReelerTooltip
                          text={
                            removeTag
                              ? "Remove label from selected assets"
                              : "Add label to selected assets"
                          }
                        >
                          <Tag
                            key={index}
                            onClick={() => assets.length > 0 && handleTag(tag)}
                          >
                            {tag}
                          </Tag>
                        </ReelerTooltip>
                      ))}
                  </div>
                </div>
                <small>Search labels:</small>
                <InputGroup className="mb-3">
                  <CreatableSelect
                    styles={customStyles}
                    isDisabled={assets.length > 0 ? false : true}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--reeler-o-25)",
                        primary: "var(--reeler-light)",
                      },
                    })}
                    placeholder={
                      removeTag ? "Remove a label..." : "Add a label ..."
                    }
                    isClearable
                    value={tag}
                    onChange={(selectedOption, action) => {
                      if (action.action === "clear") {
                        setTag("")
                      } else if (action.action === "create-option") {
                        handleTag(action.option.value)
                      } else {
                        handleTag(selectedOption.value)
                      }
                    }}
                    options={account?.tags
                      ?.sort((a, b) => {
                        if (a.toLowerCase() < b.toLowerCase()) {
                          return -1
                        }
                        if (a.toLowerCase() > b.toLowerCase()) {
                          return 1
                        }
                        return 0
                      })
                      ?.map(t => ({ value: t, label: t }))}
                  />
                </InputGroup>
              </>
            )}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
