import React, { useState } from "react"
import { Form, Modal, ModalBody } from "react-bootstrap"
import { database, firestore } from "../../../firebase"
import _ from "lodash"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { selectCampaign } from "../../../redux/campaign/campaign.selectors"
import { deleteCampaign } from "../../../redux/campaign/campaign.actions"
import ReelerTooltip from "../../commons/ReelerTooltip/ReelerTooltip"
import ReelerButton from "../../commons/ReelerButton"
import { ASSET_STATE } from "../../../constants"
import { MAIN_ROUTES } from "../../../constants/routes"

const DELETE_OPTIONS = {
  keepAll: "keep-all",
  keepApproved: "keep-approved",
  deleteAll: "delete-all",
}

export default function DeleteCampaign() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [deleteOption, setDeleteOption] = useState(DELETE_OPTIONS.keepAll)
  const [open, setOpen] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const campaign = useSelector(selectCampaign)

  const [loading, setLoading] = useState(false)

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  const handleDeleteContentSource = async () => {
    setLoading(true)
    /**
     * Load assets associated with content source
     * Check delete options
     * Delete assets and then campaign
     */

    if (confirmed) {
      let assetsToDelete = []

      if (deleteOption !== DELETE_OPTIONS.keepAll) {
        const querySnapshot = await database.assets
          .where("accountId", "==", campaign.accountId)
          .where("campaignId", "==", campaign.id)
          .get()

        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          let asset = database.formatDoc(doc)
          if (deleteOption === DELETE_OPTIONS.keepApproved) {
            if (asset?.state !== ASSET_STATE.approved) {
              assetsToDelete.push(asset)
            }
          } else {
            assetsToDelete.push(asset)
          }
        })
      }

      console.log("Assets to delete: " + assetsToDelete.length)
      // Since we are doing two write operations we have to divide the batches into 250
      const batches = _.chunk(assetsToDelete, 250).map(assetDocs => {
        const batch = firestore.batch()
        assetDocs.forEach(asset => {
          console.log("Deleting asset: " + asset.id)
          let originalAssetRef = database.assets.doc(asset.id)
          let newDeletedAssetRef = database.deletedAssets.doc(asset.id)

          let deletedAsset = {
            ...asset,
            deletedAt: database.getCurrentTimestamp(),
          }

          batch.set(newDeletedAssetRef, deletedAsset)
          batch.delete(originalAssetRef)
        })
        return batch.commit()
      })

      await Promise.all(batches).then(async () => {
        await database.campaigns.doc(campaign.id).delete()
        dispatch(deleteCampaign(campaign.id))
        setLoading(false)
        history.push(MAIN_ROUTES.campaigns.path)
      })
    }
  }

  return (
    <>
      <ReelerTooltip text={"Delete content source"}>
        <ReelerButton
          text="Delete"
          styleClass="btn-delete"
          dispatch={() => openModal()}
        />
      </ReelerTooltip>

      <Modal centered show={open} onHide={closeModal}>
        <ModalBody>
          <Modal.Title>
            Are you sure you want to delete this content source?
          </Modal.Title>
          <Modal.Body>
            <p>Choose how you would like to treat the associated assets:</p>
            <Form.Group>
              <Form.Check
                type="radio"
                id={`keep-all`}
                label="Keep all associated content assets"
                onChange={() => setDeleteOption(DELETE_OPTIONS.keepAll)}
                checked={deleteOption === DELETE_OPTIONS.keepAll}
              />
              <Form.Check
                type="radio"
                id={`keep-approved`}
                label="Keep only approved content assets, delete all other assets"
                onChange={() => setDeleteOption(DELETE_OPTIONS.keepApproved)}
                checked={deleteOption === DELETE_OPTIONS.keepApproved}
              />
              <Form.Check
                type="radio"
                id={`delete-all`}
                label="Delete all associated content assets, including approved content assets"
                onChange={() => setDeleteOption(DELETE_OPTIONS.deleteAll)}
                checked={deleteOption === DELETE_OPTIONS.deleteAll}
              />
            </Form.Group>

            <p>
              Confirm you want to delete this content source by typing yes in
              the field below.
            </p>
            <Form.Group controlId="url-link">
              <Form.Control
                type="text"
                required
                placeholder="Type yes"
                onChange={e =>
                  e.target.value === "yes"
                    ? setConfirmed(true)
                    : setConfirmed(false)
                }
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <ReelerButton
              text="Close"
              dispatch={closeModal}
              styleClass="btn-secondary"
            />
            <ReelerButton
              text="Delete assets"
              styleClass="btn-delete"
              dispatch={() => handleDeleteContentSource()}
              loading={loading}
              disabled={!confirmed}
            />
          </Modal.Footer>
        </ModalBody>
      </Modal>
    </>
  )
}
