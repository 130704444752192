import React, { useState, useEffect } from "react"
// 3 party
import { v4 as uuidV4 } from "uuid"
import { Row, Col, Dropdown, Form } from "react-bootstrap"
import {
  MdDateRange,
  MdNotes,
  MdOutlineAlternateEmail,
  MdPhone,
  MdShortText,
} from "react-icons/md"
import { IoMdGlobe } from "react-icons/io"
import { FaChevronUp } from "react-icons/fa"

import { ReactComponent as RlNumbers } from "./RlNumbers.svg"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  addFormField,
  addNewFormField,
  saveFormField,
  setShowEditModal,
} from "../../../../redux/campaign/campaign.actions"
import {
  selectFormSchema,
  selectedField,
} from "../../../../redux/campaign/campaign.selectors"
import ReelerButton from "../../../commons/ReelerButton"
import * as S from "../styles"
import ReelerSwitch from "../../../commons/Switch/ReelerSwitch"

export default function InputField({ previousFieldId, closeModal }) {
  const dispatch = useDispatch()
  const formSchema = useSelector(selectFormSchema)
  const fieldId = useSelector(selectedField)
  const [settings, setSettings] = useState({
    widget: "input",
    required: false,
    tag: false,
    format: null,
    rows: 3,
    export: true,
  })

  const [saving, setSaving] = useState(false)

  const [advancedSettings, setAdvancedSettings] = useState(false)

  // Update controlled elements if there is a selected field for edit

  useEffect(() => {
    if (fieldId && formSchema) {
      const defaultValues = formSchema.properties[fieldId]
      setSettings(defaultValues)
    }
  }, [fieldId])

  const handleSubmit = () => {
    setSaving(true)
    const field = settings
    //fieldId is existing if we are updating the field, then we should only update the field and not the fieldId and order.
    if (fieldId) {
      dispatch(saveFormField(fieldId, field))
      setSaving(false)

      // dispatch(setShowEditModal(false))
    } else {
      dispatch(addNewFormField(field, previousFieldId))
      setSaving(false)

      closeModal()
    }
  }

  const order = [
    "string",
    "textarea",
    "tel",
    "email",
    "float",
    "integer",
    "date",
    "country",
    "state",
  ]
  const fields = {
    string: {
      icon: <MdShortText />,
      label: "Short answer",
      value: "string",
      maxChars: true,
    },
    textarea: {
      icon: <MdNotes />,
      label: "Paragraph",
      value: "textarea",
      maxChars: true,
    },
    tel: {
      icon: <MdPhone />,
      label: "Phone number",
      value: "tel",
    },
    email: {
      icon: <MdOutlineAlternateEmail />,
      // icon: <MdOutlineEmail/>,
      label: "Email",
      value: "email",
    },
    float: {
      icon: <RlNumbers />,
      label: "Decimal number",
      value: "float",
    },
    integer: {
      icon: <RlNumbers />,
      label: "Whole number",
      value: "integer",
    },
    date: {
      icon: <MdDateRange />,
      label: "Date",
      value: "date",
    },
    country: {
      icon: <IoMdGlobe />,
      label: "Country",
      value: "country",
    },
    state: {
      icon: <IoMdGlobe />,
      label: "U.S. state",
      value: "state",
    },
  }

  return (
    <Form>
      <Row className="mx-n3 mb-3">
        <Col md={7}>
          <Form.Label>Give this field an internal name</Form.Label>
          <Form.Control
            type="text"
            value={settings.dataFieldName}
            onChange={e =>
              setSettings({ ...settings, dataFieldName: e.target.value })
            }
          />
          <Form.Text muted>
            Choose an internal name for this field which will be used inside
            Reeler when viewing or exporting data.
          </Form.Text>
        </Col>
      </Row>
      <S.FormFieldSectionContainer>
        <Row className="mx-n3 mb-3">
          <Col md={7}>
            <Form.Group>
              <Form.Label>Text label above the input field</Form.Label>
              <Form.Control
                type="text"
                value={settings?.title}
                onChange={e =>
                  setSettings({ ...settings, title: e.target.value })
                }
              />
              <Form.Text muted>
                (Optional) Add a text label above the form field
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        {/* <Form.Group>
          <Form.Label>Format</Form.Label>
          <Dropdown>
            <Dropdown.Toggle variant="light">
              {settings?.format ? (
                <div className="d-inline">
                  <S.StyledItemIcon>
                    {fields[settings.format]?.icon}
                  </S.StyledItemIcon>
                  {fields[settings.format]?.label}
                </div>
              ) : (
                "Select field type"
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {order.map((value, key) => {
                return (
                  <S.StyledItem
                    value={value}
                    key={key}
                    onClick={() => setSettings({ ...settings, format: value })}
                  >
                    <S.StyledItemIcon>{fields[value].icon}</S.StyledItemIcon>
                    {fields[value].label}
                  </S.StyledItem>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group> */}
        {settings.format === "textarea" ? (
          <Form.Group className="mb-3" controlId="cols">
            <Col md={7} className="mx-n3">
              <Form.Label>Number of rows</Form.Label>
              <br />
              <Form.Control
                type="integer"
                value={settings?.rows}
                onChange={e =>
                  setSettings({ ...settings, rows: e.target.value })
                }
              />
              <Form.Text muted>
                Choose how many rows the text field should initially have.
              </Form.Text>
            </Col>
          </Form.Group>
        ) : null}

        <Form.Group>
          <Col md={7} className="mx-n3">
            <Form.Label>Placeholder</Form.Label>
            <Form.Control
              type="text"
              value={settings?.placeholder}
              onChange={e =>
                setSettings({ ...settings, placeholder: e.target.value })
              }
            />
            <Form.Text muted>
              (Optional) Add a placeholder to the input
            </Form.Text>
          </Col>
        </Form.Group>
        {settings?.format && fields[settings?.format]?.maxChars ? (
          <Form.Group>
            <Col md={7} className="mx-n3">
              <Form.Label>Maximum number of characters</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter maximum number of characters"
                value={settings?.maxChars}
                size="sm"
                onChange={e =>
                  setSettings({ ...settings, maxChars: e.target.value })
                }
              />
              <Form.Text muted>
                (Optional) Add a maximum number of characters that is allowed
                for the input field
              </Form.Text>
            </Col>
          </Form.Group>
        ) : null}

        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={settings?.description}
            onChange={e =>
              setSettings({ ...settings, description: e.target.value })
            }
          />
          <Form.Text muted>
            (Optional) Add a short text below the field to explain why you want
            the user to input information
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <ReelerSwitch
            label="Required field"
            name="required"
            action={() =>
              setSettings({ ...settings, required: !settings?.required })
            }
            checkedBoolean={settings?.required}
          />
        </Form.Group>
        {settings?.format !== "email" ? (
          <Form.Group className="mb-3 mt-3">
            <ReelerSwitch
              label="Label uploaded assets with answer"
              name="tag"
              action={() => setSettings({ ...settings, tag: !settings?.tag })}
              checkedBoolean={settings?.tag}
            />
          </Form.Group>
        ) : null}
        <Form.Group className="mb-3 mt-3">
          <ReelerSwitch
            label="Add answer to filename when exporting"
            name="filename"
            action={() =>
              setSettings({
                ...settings,
                filename: settings?.filename ? !settings?.filename : true,
              })
            }
            checkedBoolean={settings?.filename}
          />
        </Form.Group>
        <Form.Group className="mb-3 mt-3">
          <ReelerSwitch
            label="Make this field exportable"
            name="export"
            action={() =>
              setSettings({ ...settings, export: !settings?.export })
            }
            checkedBoolean={settings?.export}
          />
        </Form.Group>
      </S.FormFieldSectionContainer>

      <Form.Group>
        <ReelerButton
          loading={saving}
          disabled={saving}
          dispatch={e => handleSubmit()}
          text="Save"
          className="mr-3"
          styleClass="btn-main"
          spinnerClass="ml-1"
        />
      </Form.Group>
    </Form>
  )
}
