import React, { useState, useEffect } from "react"
import {
  selectFeed,
  selectPreviewAssets,
} from "../../../../../../../redux/feed/feed.selectors"
import { useSelector } from "react-redux"
import { Form, Row, Col } from "react-bootstrap"
import { FaRegCopy } from "react-icons/fa"
import ReelerTooltip from "../../../../../../commons/ReelerTooltip/ReelerTooltip"
import {
  FEED_EMAIL_LAYOUTS,
  FEED_PUBLISH_OPTIONS,
  IMG_SIZES,
} from "../../../../../../../constants"

// https://webdesign.tutsplus.com/creating-a-future-proof-responsive-email-without-media-queries--cms-23919t
const EmbedEmailFeed = () => {
  const feed = useSelector(selectFeed)
  const assets = useSelector(selectPreviewAssets)
  const [previewAssets, setPreviewAssets] = useState([])
  const [loading, setLoading] = useState(true)

  const formatAsset = (assetId, asset) => {
    let mainVersion = asset?.assetVersions.filter(
      version => version.main === true
    )
    if (mainVersion.length > 0) {
      let type = mainVersion[0].type
      let filePath = mainVersion[0].file_path
      let fileName = mainVersion[0].file_name

      let item = {
        id: assetId,
        caption: asset?.caption ? asset?.caption : asset?.media?.caption,
        pubDate: asset?.createdAt,
        link: asset?.media?.permalink,
        type: type,
        campaign_type: asset.campaign_type,
        products: asset?.products,
      }
      if (type.split("/")[0] === "image") {
        item["images"] = {
          original: mainVersion[0].url,
          h_500:
            `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/` +
            encodeURIComponent(
              filePath + "image@" + IMG_SIZES.preView + "_" + fileName
            ) +
            "?alt=media",
          h_200:
            `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/` +
            encodeURIComponent(
              filePath + "image@" + IMG_SIZES.thumb + "_" + fileName
            ) +
            "?alt=media",
        }
      } else {
        item["url"] = mainVersion[0].url
      }
      return item
    }
  }

  useEffect(() => {
    if (assets) {
      setLoading(true)
      if (feed?.publish === FEED_PUBLISH_OPTIONS.manually) {
        const manuallyAddedAssets = assets?.reduce((result, asset) => {
          if (feed?.manuallyAddedAssets?.includes(asset.id)) {
            result.push(formatAsset(asset.id, asset))
          }

          return result
        }, [])
        setPreviewAssets(manuallyAddedAssets)
        setLoading(false)
      } else {
        const withoutExckludedAssets = assets?.reduce((result, asset) => {
          if (!feed?.excludedAssets?.includes(asset.id)) {
            result.push(formatAsset(asset.id, asset))
          }
          return result
        }, [])
        setPreviewAssets(withoutExckludedAssets)
        setLoading(false)
      }
    }
  }, [assets])

  const renderEmailCode = embedAssets => {
    let renderEmailCode

    if (feed?.feedLayout === FEED_EMAIL_LAYOUTS.single) {
      renderEmailCode = `<div style="width: 100%"><img
          width="100%"
          height="100%"
          style="object-fit: contain;
            display: block;
            max-width: 100%;
            max-height: 500px;
            margin: 0 auto;
            border-radius: ${
              feed?.feedSettings?.borderRadius
                ? feed?.feedSettings?.borderRadius
                : 0
            }px;
          "
          src="${embedAssets[0]?.images?.h_500}"
          alt="User generated content"
        />
      </div>`
    } else {
      renderEmailCode = renderRowLayout(embedAssets)
    }

    return renderEmailCode
  }

  const renderRowLayout = embedAssets => {
    let embedCode = `<div style="font-size:0;text-align:center;">
    <!--[if mso]>
    <table role="presentation" width="100%" style="text-align:center;">
    <tr>
    <![endif]-->`

    let columns
    if (feed?.feedSettings?.columns) {
      columns = feed?.feedSettings?.columns
    } else {
      columns = 3
    }
    for (let i = 0; i < columns; i++) {
      if (embedAssets[i]) {
        let asset = embedAssets[i]
        embedCode += `<!--[if mso]>
          <td style="width:220px;padding-right: ${
            feed?.feedSettings?.cellspacing
          }px;" valign="top">
          <![endif]-->
          <div class="column" style="width:100%;max-width: ${Math.round(
            100 / columns
          ).toFixed(2)}% !important;display:inline-block;vertical-align:top;">
              <div style="padding-right: ${
                feed?.feedSettings?.cellspacing
              }px;font-size:14px;line-height:18px;">
                  <img style="object-fit: cover;
                  display: block;
                  max-width: 100%;
                  max-height: 200px;
                  aspect-ratio: 1/1;
                  border-radius: ${
                    feed?.feedSettings?.borderRadius
                      ? feed?.feedSettings?.borderRadius
                      : 0
                  }px;
                  margin: 0 auto;
                " src="${asset?.images?.h_200}" alt="User generated content">
              </div>
          </div>
          <!--[if mso]>
          </td>
          <![endif]-->`
      }
    }

    embedCode += `
      <!--[if mso]>
      </td>
      </tr>
      </table>
      <![endif]-->
    </div>
    `

    return embedCode
  }

  const emailEmbedCodeRow = `<div class="three-col" style="font-size:0;text-align:center;">
  <!--[if mso]>
  <table role="presentation" width="100%" style="text-align:center;">
  <tr>
  <td style="width:220px;padding:10px;" valign="top">
  <![endif]-->
  <div class="column" style="width:100%;max-width:220px;display:inline-block;vertical-align:top;">
      <div style="padding:10px;font-size:14px;line-height:18px;">
          <img src="https://us-central1-videoh-mvp-dev.cloudfunctions.net/api/media/200/XL4LJi5rasMH0UyPARtP" alt="User generated content">
      </div>
  </div>
  <!--[if mso]>
  </td>
  <td style="width:220px;padding:10px;" valign="top">
  <![endif]-->
  <div class="column" style="width:100%;max-width:220px;display:inline-block;vertical-align:top;">
      <div style="padding:10px;font-size:14px;line-height:18px;">
      <img src="https://us-central1-videoh-mvp-dev.cloudfunctions.net/api/media/200/XL4LJi5rasMH0UyPARtP" alt="User generated content">
      </div>
  </div>
  <!--[if mso]>
  </td>
  <td style="width:220px;padding:10px;" valign="top">
  <![endif]-->
  <div class="column" style="width:100%;max-width:220px;display:inline-block;vertical-align:top;">
      <div style="padding:10px;font-size:14px;line-height:18px;">
      <img src="https://us-central1-videoh-mvp-dev.cloudfunctions.net/api/media/200/XL4LJi5rasMH0UyPARtP" alt="User generated content">
      </div>
  </div>
  <!--[if mso]>
  </td>
  </tr>
  </table>
  <![endif]-->
</div>
<style type="text/css">
    @media screen and (max-width: 350px) {
        .three-col .column {
            max-width: 100% !important;
        }
    }
    @media screen and (min-width: 351px) and (max-width: 460px) {
        .three-col .column {
            max-width: 50% !important;
        }
    }
    @media screen and (min-width: 461px) {
        .three-col .column {
            max-width: 33.3% !important;
        }
        .two-col .column {
            max-width: 50% !important;
        }
        .sidebar .small {
            max-width: 16% !important;
        }
        .sidebar .large {
            max-width: 84% !important;
        }
    }
</style>`

  const copyUrl = divId => {
    var r = document.createRange()
    r.selectNode(document.getElementById(divId))
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(r)
    document.execCommand("copy")
    window.getSelection().removeAllRanges()
  }

  return (
    <>
      <Row>
        <Col>
          <p>
            <small>
              Please note that for email feeds, the embed code will display the
              email feed as it appeared at the time you copied the code. The
              embed code provided here will update each time the email feed is
              modified. This differs from web feeds, where the embed code
              remains the same even when assets are updated.
            </small>
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group id="embed">
            <div className="d-flex flex-row align-items-center mt-3">
              <div
                id="email-script-link"
                className="mt-2 p-2"
                style={{
                  width: "100%",
                  height: "100%",
                  background: "#fff",
                  fontSize: "10px",
                  border: "1px solid lightgrey",
                  borderRadius: ".25rem",
                }}
              >
                {!loading &&
                  previewAssets?.length > 0 &&
                  renderEmailCode(previewAssets)}
              </div>
              <ReelerTooltip text="Copy the code">
                <FaRegCopy
                  style={{ strokeWidth: "3" }}
                  className="icon-color ml-3 icon-btn"
                  onClick={() => copyUrl("email-script-link")}
                />
              </ReelerTooltip>
            </div>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default EmbedEmailFeed
