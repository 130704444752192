import React, { useState, useEffect } from "react"
import { Container, Col, Row, Form } from "react-bootstrap"
import { functions } from "../../../../firebase"

import ReelerButton from "../../../commons/ReelerButton"
import CloseButton from "../../../commons/CloseButton"
import { useDispatch, useSelector } from "react-redux"
import AuthBasedComponent from "../../../authentication/AuthBasedComponent"
import {
  setActiveFilter,
  forceClearFilter,
  toggleShowFilter,
  clearFilter,
  setShowSaveFilter,
  clearViewerFilterParameters,
  clearActiveAccountFilter,
} from "../../../../redux/filter/filter.actions"
import { BulkEditReset } from "../../../../redux/bulk-edit/bulkEdit.actions"
import { clearCampaign } from "../../../../redux/campaign/campaign.actions"
import { setFeed, setFeedType } from "../../../../redux/feed/feed.actions"
import {
  selectActiveFilter,
  selectViewerFilterParameters,
} from "../../../../redux/filter/filter.selectors"

import CreateFilterCollection from "../CreateFilterCollection/CreateFilterCollection"
import FilterParameters from "./FilterParameters"
import ReelerTooltip from "../../../commons/ReelerTooltip/ReelerTooltip"
import { FaPlus, FaPlusCircle } from "react-icons/fa"
import { MdFilterAltOff } from "react-icons/md"
import { ACCOUNT_PLANS, USER_ROLES } from "../../../../constants/routes"
import ViewerFilterParameters from "./ViewerFilterParameters"

const Filter = () => {
  //FROM MEDIAFILTER
  const dispatch = useDispatch()
  const activeFilter = useSelector(selectActiveFilter)
  const viewerFilterParameters = useSelector(selectViewerFilterParameters)
  const [loading, setLoading] = useState(false)

  const handleClearFilter = () => {
    dispatch(clearFilter())
    dispatch(clearCampaign())
    dispatch(setFeed(null))
    dispatch(setFeedType(null))
    dispatch(BulkEditReset())
  }

  const handleCreateNewFilter = () => {
    dispatch(clearActiveAccountFilter())
    dispatch(setShowSaveFilter(true))
  }

  const handleCloseFilter = () => {
    dispatch(toggleShowFilter())
  }

  const ClearViewerFilterParameters = () => {
    const handleClearViewerFilter = () => {
      dispatch(clearViewerFilterParameters())
    }
    return (
      <ReelerButton
        text="Clear"
        dispatch={handleClearViewerFilter}
        styleClass="btn-secondary block"
        spinnerClass="ml-1"
        spinnerColor="icon-color"
      />
    )
  }

  return (
    <>
      <div className="position-relative">
        <div style={{ position: "absolute", top: "-10px", right: "-10px" }}>
          <ReelerTooltip text="Hide filter">
            <span onClick={() => handleCloseFilter()}>
              <CloseButton />
            </span>
          </ReelerTooltip>
        </div>

        <div className="d-flex flex-row align-items-center pb-2">
          <h2 className="sidebar-title m-0">Filter</h2>
          <AuthBasedComponent
            plans={[
              ACCOUNT_PLANS.basic,
              ACCOUNT_PLANS.pro,
              ACCOUNT_PLANS.premium,
            ]}
          >
            <div className="d-flex flex-row align-items-center ml-3">
              <div className="mr-2">
                <ReelerTooltip text="Save new filter based on active filter settings">
                  <FaPlus
                    className="icon-color icon-btn"
                    onClick={() => handleCreateNewFilter()}
                  />
                </ReelerTooltip>
              </div>
              <ReelerTooltip text="Clear filter">
                <MdFilterAltOff
                  className="icon-color icon-btn"
                  onClick={() => handleClearFilter()}
                />
              </ReelerTooltip>
            </div>
          </AuthBasedComponent>
        </div>
      </div>
      <AuthBasedComponent
        plans={[ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium]}
      >
        <FilterParameters />
        <Row className="px-3">
          <Col className="p-0">
            <ReelerButton
              text="Clear"
              loading={loading}
              dispatch={handleClearFilter}
              styleClass="btn-secondary block"
              spinnerClass="ml-1"
              spinnerColor="icon-color"
            />
          </Col>
          <Col className="p-0 ml-2">
            {false && activeFilter ? (
              <ReelerButton
                text="Save filter"
                loading={loading}
                dispatch={() => dispatch(setShowSaveFilter(true))}
                styleClass="btn-secondary block"
                spinnerClass="ml-1"
                spinnerColor="icon-color"
              />
            ) : (
              <ReelerButton
                text={"Save new filter"}
                loading={loading}
                dispatch={() => handleCreateNewFilter()}
                styleClass="btn-secondary block"
                spinnerClass="ml-1"
                spinnerColor="icon-color"
              />
            )}
          </Col>
        </Row>
      </AuthBasedComponent>

      <AuthBasedComponent
        plans={[ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium]}
        userRoles={[USER_ROLES.viewer]}
      >
        <ViewerFilterParameters />
        <Row className="px-3">
          <Col className="p-0">
            <ClearViewerFilterParameters />
          </Col>
        </Row>
      </AuthBasedComponent>
      {/* SUBMIT SECTION */}
    </>
  )
}

export default Filter
