import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { FaPlusCircle } from "react-icons/fa"
import CreateCampaign from "./components/CreateCampaign"
import CampaignsTable from "./components/CampaignsTable"
import SpinnerComponent from "../commons/SpinnerComponent"
import { selectAccountId } from "../../redux/account/account.selectors"
import { database } from "../../firebase"
import ReelerButton from "../commons/ReelerButton"
import { useHistory } from "react-router-dom"

import { useDispatch } from "react-redux"
import { resetImport } from "../../redux/import-assets/import.actions"
import { CAMPAIGN_TYPE } from "../../constants"
import ReelerTooltip from "../commons/ReelerTooltip/ReelerTooltip"
import Section from "../../layouts/core/Section"
import { Header } from "../../layouts/core/Header"
import Body from "../../layouts/core/Body"
import Title from "../../layouts/core/Title"
import { MAIN_ROUTES } from "../../constants/routes"

const Campaigns = () => {
  const history = useHistory()
  const accountId = useSelector(selectAccountId)
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [campaigns, setCampaigns] = useState([])

  const renderCollections = () => {
    if (campaigns.length === 0)
      return (
        <div className="pt-5 d-flex flex-column justify-content-center align-items-center">
          <img width="200px" src="./no_content_women_dog.svg" alt="" />
          <p>You do not have any content sources. </p>
          <CreateCampaign
            btn={
              <ReelerButton
                text="Add new content source"
                styleClass="btn-main"
              />
            }
          />
        </div>
      )

    return (
      <>
        <CampaignsTable campaigns={campaigns} />
        <div className="flex-row mt-3 d-flex justify-content-center">
          <CreateCampaign
            btn={
              <div className="flex-row d-flex align-items-center link">
                <FaPlusCircle className="mr-2 icon-color" size={20} />{" "}
                <div
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  Add new content source
                </div>
              </div>
            }
          />
        </div>
      </>
    )
  }

  useEffect(() => {
    setIsLoading(true)
    if (accountId) {
      const unsubscribe = database.campaigns
        .where("accountId", "==", accountId)
        .orderBy("createdAt", "desc")
        .onSnapshot(snap => {
          const data = snap.docs.map(doc => database.formatDoc(doc))
          setCampaigns(data)
          setIsLoading(false)
        })

      //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
      return () => unsubscribe()
    }
  }, [accountId])

  const handleImport = () => {
    dispatch(resetImport())
    history.push(MAIN_ROUTES.importAssets.path)
  }

  return (
    <Section>
      <Header>
        <Title>Sources</Title>
        {campaigns.length > 0 ? (
          <small className="d-flex align-items-center">
            Total sources: {campaigns.length} (
            <ReelerTooltip text="Instagram hashtag sources">
              <span className="mr-1 d-flex align-items-center">
                {CAMPAIGN_TYPE.igHashtag.icon}:
              </span>
            </ReelerTooltip>
            <span className="mr-1">
              {
                campaigns.filter(
                  campaign =>
                    campaign.campaignType === CAMPAIGN_TYPE.igHashtag.type
                ).length
              }
            </span>
            <ReelerTooltip text="Instagram Mentions sources">
              <span className="mr-1  d-flex align-items-center">
                {CAMPAIGN_TYPE.igMentions.icon}:
              </span>
            </ReelerTooltip>
            <span className="mr-1">
              {
                campaigns.filter(
                  campaign =>
                    campaign.campaignType === CAMPAIGN_TYPE.igMentions.type
                ).length
              }
            </span>
            <ReelerTooltip text="Web form sources">
              <span className="mr-1 d-flex align-items-center">
                {CAMPAIGN_TYPE.upload.icon}:
              </span>
            </ReelerTooltip>
            <span className="mr-1">
              {
                campaigns.filter(
                  campaign =>
                    campaign.campaignType === CAMPAIGN_TYPE.upload.type
                ).length
              }
            </span>
            <ReelerTooltip text="Email sources">
              <span className="mr-1 d-flex align-items-center">
                {CAMPAIGN_TYPE.email.icon}:
              </span>
            </ReelerTooltip>
            <span className="mr-1">
              {
                campaigns.filter(
                  campaign => campaign.campaignType === CAMPAIGN_TYPE.email.type
                ).length
              }
            </span>
            )
          </small>
        ) : null}
        <div className="d-flex flex-row ml-auto">
          <ReelerButton
            dispatch={() => handleImport()}
            text="Import assets"
            styleClass="btn-secondary mr-3"
          />
          <CreateCampaign
            btn={
              <ReelerButton
                text="Add new content source"
                styleClass="btn-main"
              />
            }
          />
        </div>
      </Header>
      <Body>
        {isLoading ? <SpinnerComponent size="lg" /> : renderCollections()}
      </Body>
    </Section>
  )
}

export default Campaigns
