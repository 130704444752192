import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectAccounts } from "../../../../../../../redux/account/account.selectors"
import {
  selectActiveAsset,
  selectAssetRightsRequest,
} from "../../../../../../../redux/asset/asset.selectors"
import {
  updateAsset,
  setAssetRightsRequest,
} from "../../../../../../../redux/asset/asset.actions"
import { database } from "../../../../../../../firebase"
import firebase from "firebase/app"

// Reeler components
import ReelerButton from "../../../../../../commons/ReelerButton"
import CopyToClipboard from "../../../../../../commons/CopyToClipboard"
import * as S from "../../../styles"
import {
  ASSET_RIGHT_STATUS,
  RIGHTS_REQUEST_TYPE,
} from "../../../../../../../constants"
import { Form } from "react-bootstrap"
import useUserFeedbackMessage from "../../../../../../../hooks/useUserFeedbackMessage"

export default function RightsRequestLink() {
  const dispatch = useDispatch()
  const account = useSelector(selectAccounts)
  const asset = useSelector(selectActiveAsset)
  const rightsRequest = useSelector(selectAssetRightsRequest)
  const [msg, setMsg] = useState()
  const [saving, setSaving] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useUserFeedbackMessage()

  const handleMarkAsSent = () => {
    setSaving(true)

    const approvalStatusLog = {
      approvalStatus: ASSET_RIGHT_STATUS.pending,
      approvalStatusLog: [
        ...rightsRequest?.approvalStatusLog,
        {
          status: ASSET_RIGHT_STATUS.pending,
          note: "Sent request manually (outside of Reeler)",
          createdAt: Date.now(),
        },
      ],
    }

    database.rightRequests
      .doc(rightsRequest.id)
      .update(approvalStatusLog)
      .then(() => {
        dispatch(
          setAssetRightsRequest({
            ...rightsRequest,
            ...approvalStatusLog,
          })
        )
        const assetRef = database.assets.doc(asset.id)
        if (rightsRequest.requestType === RIGHTS_REQUEST_TYPE.thirdParty) {
          assetRef
            .update({
              thirdPartyApprovalStatus: ASSET_RIGHT_STATUS.pending,
            })
            .then(() => {
              dispatch(
                updateAsset({
                  ...asset,
                  thirdPartyApprovalStatus: ASSET_RIGHT_STATUS.pending,
                })
              )
              setSuccessMessage(
                "Rights request marked as sent manually (pending)"
              )
            })
        } else {
          assetRef
            .update({
              status: ASSET_RIGHT_STATUS.pending,
            })
            .then(() => {
              dispatch(
                updateAsset({
                  ...asset,
                  status: ASSET_RIGHT_STATUS.pending,
                })
              )
              setSuccessMessage(
                "Rights request marked as sent manually (pending)"
              )
            })
        }
        setSaving(false)
      })
      .catch(err => {
        console.log(err)
        setErrorMessage("Could mark rights request as sent")
        setSaving(false)
      })
  }

  return rightsRequest && account && asset ? (
    <Form.Group>
      <Form.Label>Copy link so you can send it manually</Form.Label>

      <div className="d-flex flex-row align-items-center mb-3">
        <a className="link" href={rightsRequest.link} target="_blank">
          {rightsRequest.link}
        </a>
        <div className="d-flex justify-content-center align-items-center ml-2">
          <CopyToClipboard
            link={rightsRequest.link}
            tooltipText="Copy link to send it manually"
          />
        </div>
      </div>

      <ReelerButton
        text="Mark as sent"
        styleClass="btn-main"
        size="sm"
        dispatch={handleMarkAsSent}
        loading={saving}
      />
    </Form.Group>
  ) : null
}
