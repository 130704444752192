import { combineReducers } from "redux"
import userReducer from "./user/user.reducer"
import campaignReducer from "./campaign/campaign.reducer"
import accountReducer from "./account/account.reducer"
import assetReducer from "./asset/asset.reducer"
import inviteReducer from "./invite/invite.reducer"
import filterReducer from "./filter/filter.reducer"
import bulkEditReducer from "./bulk-edit/bulkEdit.reducer"
import feedReducer from "./feed/feed.reducer"
import thirdPartyApprovalReducer from "./third-party-approval/third-party-approval.reducer"
import importReducer from "./import-assets/import.reducer"
import navbarReducer from "./navbar/navbar.reducer"
import platformReducer from "./platform/platform.reducer"

export default combineReducers({
  user: userReducer,
  campaign: campaignReducer,
  account: accountReducer,
  asset: assetReducer,
  invite: inviteReducer,
  filter: filterReducer,
  bulkEdit: bulkEditReducer,
  feed: feedReducer,
  thirdPartyApproval: thirdPartyApprovalReducer,
  importAssets: importReducer,
  navbar: navbarReducer,
  platform: platformReducer,
})
