import React from "react"

import "./styles.css"

const ReelerSwitch = ({ label, name, action, checkedBoolean }) => {
  return (
    <div className="custom-control custom-switch">
      <input
        type="checkbox"
        className="custom-control-input"
        id={name}
        checked={Boolean(checkedBoolean)}
        name={name}
        onChange={action}
      />
      <label className="custom-control-label" htmlFor={name}>
        {label}
      </label>
    </div>
  )
}

export default ReelerSwitch
