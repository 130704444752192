import styled, { css, keyframes } from "styled-components"

const slideIn = keyframes`
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0%);
    }
  
`

const fixedStyles = css`
  animation: ${slideIn} 500ms;
  box-shadow: var(--reeler-shadow);
  position: fixed;
  top: 56px;
  background: white;
  padding: 10px;
`

export const BulkEditContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({ $active }) => ($active ? "var(--reeler-grey)" : null)};
  border-radius: ${({ $active }) => ($active ? "3px" : null)};
  width: 550px;
  z-index: 5;
  padding: 10px;
  cursor: default;

  div:not(:first-child) {
    :hover {
      cursor: pointer;
    }
  }

  span {
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  ${({ $positionFixed }) => ($positionFixed ? fixedStyles : null)}
`

export const BulkEditItem = styled.span`
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
`
