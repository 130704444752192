import React, { useState, useRef } from "react"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"
// Firebase
import { database } from "../../../../../../firebase"

import { InputGroup, Form } from "react-bootstrap"
import { updateAsset } from "../../../../../../redux/asset/asset.actions"
import { FaEdit } from "react-icons/fa"
import ReelerButton from "../../../../../commons/ReelerButton"
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip"
import FieldValue from "./FieldValue"
import AuthBasedComponent from "../../../../../authentication/AuthBasedComponent"
import { ACCOUNT_PLANS } from "../../../../../../constants/routes"
import AssetTextField from "./AssetTextField"
import { MdOutlineEditNote } from "react-icons/md"
import Icon from "../../../../../commons/Icon"

export default function EditedCaption() {
  const [showEdit, setShowEdit] = useState(false)
  const [saving, setSaving] = useState(false)
  const dispatch = useDispatch()
  const asset = useSelector(selectActiveAsset)
  const captionRef = useRef()

  const handleSave = () => {
    setSaving(true)

    const updateData = {
      edited_caption: captionRef.current.value,
    }

    database.assets
      .doc(asset.id)
      .update(updateData)
      .then(() => {
        dispatch(
          updateAsset({
            ...asset,
            ...updateData,
          })
        )

        setSaving(false)
        setShowEdit(false)
      })
      .catch(err => {
        setSaving(false)
      })
  }
  const Caption = ({ caption }) => (
    <div className="d-flex flex-row justify-content-between align-items-center">
      {caption && (
        <span className="asset-info-text mr-3">
          <FieldValue value={caption} />{" "}
        </span>
      )}
      <AuthBasedComponent
        plans={[ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium]}
      >
        <div>
          <Icon onClick={() => setShowEdit(true)} tooltip="Add/edit caption">
            <MdOutlineEditNote className="icon-color" />
          </Icon>
        </div>
      </AuthBasedComponent>
    </div>
  )

  return (
    asset && (
      <AssetTextField
        icon={
          <div className="d-flex justify-content-start h-100 pt-1">
            <ReelerTooltip text="Edited caption">
              <MdOutlineEditNote className="icon-color" />
            </ReelerTooltip>
          </div>
        }
        text={
          <>
            <InputGroup className="mb-1">
              <Form.Control
                as="textarea"
                rows={3}
                disabled={saving}
                style={{ fontSize: "12px" }}
                placeholder="Add an edited caption"
                defaultValue={
                  asset?.edited_caption ? asset?.edited_caption : ""
                }
                ref={captionRef}
              />
            </InputGroup>
            <div className="d-flex flex-row justify-content-end">
              <ReelerButton
                text="Save"
                styleClass="btn-main"
                size="sm"
                dispatch={() => handleSave()}
                disabled={saving}
                loading={saving}
              />
            </div>
          </>
        }
      />
    )
  )
}
