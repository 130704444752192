import React, { useState } from "react"
import { Modal, ModalBody } from "react-bootstrap"

import { FaTrashAlt } from "react-icons/fa"

export default function DeleteAccount() {
  const [open, setOpen] = useState(false)

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setOpen(false)
  }

  return (
    <>
      <span
        className="d-flex flex-row align-items-center mr-3 link icon-btn"
        onClick={openModal}
      >
        <FaTrashAlt className="mr-2" /> Delete account
      </span>

      <Modal centered show={open} onHide={closeModal}>
        <ModalBody>
          <Modal.Title>
            Oh no, why do you want to delete your account?
          </Modal.Title>
          <Modal.Body>
            Please contact Reeler{" "}
            <a href="mailto:support@reelertech.com">support</a> to close down
            your account.
          </Modal.Body>
          <Modal.Footer>
            <div
              className="mr-4"
              variant="secondary"
              onClick={closeModal}
              style={{ cursor: "pointer" }}
            >
              Close
            </div>
          </Modal.Footer>
        </ModalBody>
      </Modal>
    </>
  )
}
