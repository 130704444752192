import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { database } from "../../firebase"

import "../App.css"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../redux/user/user.selectors"
import {
  selectAccountId,
  selectAccounts,
} from "../../redux/account/account.selectors"
import DeleteAccount from "./DeleteAccount"
import ReelerButton from "../commons/ReelerButton"
import StaticAlert from "../commons/StaticAlert/StaticAlert"
import Section from "../../layouts/core/Section"
import { Header } from "../../layouts/core/Header"
import Body from "../../layouts/core/Body"
import Title from "../../layouts/core/Title"
import BackButton from "../commons/BackButton"

const Account = () => {
  const currentUser = useSelector(selectCurrentUser)
  const accountId = useSelector(selectAccountId)
  const accounts = useSelector(selectAccounts)

  const [name, setName] = useState(accounts?.name)
  const [msg, setMsg] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (accounts) setName(accounts?.name)
  }, [accounts])

  function handleSubmit(e) {
    e.preventDefault()
    setLoading(true)

    if (accounts) {
      // Update account
      database.accounts
        .doc(accountId)
        .update({
          name: name,
        })
        .then(() => {
          setMsg({ success: true, text: "Your changes were saved" })
          setLoading(false)
        })
        .catch(err => {
          setMsg({ success: false, text: "Error" })
          setLoading(false)
        })
    } else {
      // Create a new account
      const newAccount = {
        name: name,
        userId: currentUser.uid,
        createdAt: database.getCurrentTimestamp(),
      }

      database.accounts
        .add(newAccount)
        .then(account => {
          const newAccountId = account.id
          // Create private details for the account
          const newAccountPrivate = {
            roles: { [currentUser.uid]: "owner" },
            createdAt: database.getCurrentTimestamp(),
          }

          database.accounts
            .doc(newAccountId)
            .collection("private")
            .doc("private")
            .set(newAccountPrivate)
            .then(doc => {
              // Create a user and connect to the account
              const newUser = {
                email: currentUser.email,
                accounts: [newAccountId],
                createdAt: database.getCurrentTimestamp(),
              }

              database.users
                .doc(currentUser.uid)
                .set(newUser)
                .then(user => {
                  setLoading(false)
                  setMsg({
                    text: "Account name updated succesfully",
                    variant: "success",
                  })
                  // load user details to AuthContext
                  // loadUserDetails(currentUser);
                  // push to dashboard
                })
                .catch(err => {
                  setMsg({ text: "Error", variant: "danger" })
                  setLoading(false)
                })
            })
            .catch(err => {
              setMsg({ text: "Error", variant: "danger" })
              setLoading(false)
            })
        })
        .catch(err => {
          setMsg({ text: "Error", variant: "danger" })
          setLoading(false)
        })
    }
  }

  return (
    <Section>
      <Header>
        <Title>Account</Title>
        <div className="ml-auto">
          <BackButton />
        </div>
      </Header>
      <Body>
        <p>These defaults will be applied to the entire account.</p>

        {msg && <StaticAlert msg={msg} />}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="account-name">
            <Form.Label>Account name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </Form.Group>

          <div className="flex-row d-flex justify-content-end">
            <DeleteAccount />
            <ReelerButton
              text="Save"
              disabled={loading}
              styleClass="btn-main"
              type="submit"
            />
          </div>
        </Form>
      </Body>
    </Section>
  )
}

export default Account
