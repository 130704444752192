import React, { useEffect, useState } from "react"
import { database } from "../../../../../../firebase"
import { useHistory, Link } from "react-router-dom"
import Skeleton from "../../../../../commons/skeleton/Skeleton"
import { Card } from "react-bootstrap"
import * as S from "./GuideTopicVideoList.styles"

export default function GuideTopicVideoList({
  guideTopicId,
  layout = "horizontal",
}) {
  const [fetchingVideos, setFetchingVideos] = useState(true)
  const [fetchingGuideTopic, setFetchingGuideTopic] = useState(true)
  const [guideTopic, setGuideTopic] = useState(null)
  const [guideTopicVideos, setGuideTopicVideos] = useState(null)

  const history = useHistory()

  useEffect(() => {
    setFetchingVideos(true)
    database.guides
      .doc(guideTopicId)
      .collection("videos")
      .where("published", "==", true)
      .orderBy("order")
      .get()
      .then(querySnapshot => {
        let videos = []
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          videos.push(database.formatDoc(doc))
        })
        setGuideTopicVideos(videos)
        setFetchingVideos(false)
      })
      .catch(error => {
        console.log("Error getting documents: ", error)
      })
  }, [])

  useEffect(() => {
    setFetchingGuideTopic(true)
    database.guides
      .doc(guideTopicId)
      .get()
      .then(doc => {
        if (doc.exists) {
          setGuideTopic(database.formatDoc(doc))
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!")
        }
        setFetchingGuideTopic(false)
      })
      .catch(error => {
        console.log("Error getting document:", error)
      })
  }, [])

  const handlePlayVideo = id => {
    history.push(`/help/${guideTopic?.id}/${id}`)
  }
  const handlePlayAll = () => {
    history.push(`/help/${guideTopic?.id}`)
  }

  return (
    <>
      {!fetchingGuideTopic && guideTopic ? (
        <div
          onClick={() => handlePlayAll()}
          className="d-flex flex-row align-items-center pl-3 icon-btn"
        >
          <strong className="mr-3">{guideTopic.title}</strong>{" "}
          <small>(Play all)</small>
        </div>
      ) : (
        <div style={{ width: "150px", height: "20px", marginTop: "10px" }}>
          <Skeleton />
        </div>
      )}

      {fetchingVideos ? (
        <S.VideoListContainer layout={layout}>
          <div style={{ width: "200px", height: "130px", marginRight: "15px" }}>
            <div style={{ width: "200px", height: "100px" }}>
              <Skeleton />
            </div>
            <div style={{ width: "150px", height: "20px", marginTop: "10px" }}>
              <Skeleton />
            </div>
          </div>
          <div style={{ width: "200px", height: "130px", marginRight: "15px" }}>
            <div style={{ width: "200px", height: "100px" }}>
              <Skeleton />
            </div>
            <div style={{ width: "150px", height: "20px", marginTop: "10px" }}>
              <Skeleton />
            </div>
          </div>
          <div style={{ width: "200px", height: "130px", marginRight: "15px" }}>
            <div style={{ width: "200px", height: "100px" }}>
              <Skeleton />
            </div>
            <div style={{ width: "150px", height: "20px", marginTop: "10px" }}>
              <Skeleton />
            </div>
          </div>
        </S.VideoListContainer>
      ) : (
        <S.VideoListContainer layout={layout}>
          {guideTopicVideos?.map(video => {
            const { id, thumbnail, title, description } = video
            return (
              <S.VideoCard key={id} onClick={() => handlePlayVideo(id)}>
                {thumbnail ? (
                  <S.Thumbnail
                    src={
                      process.env.REACT_APP_FIREBASE_STORAGE_BASE_URL +
                      encodeURIComponent(
                        "reeler-guide-videos/thumbnails/image@200_"
                      ) +
                      thumbnail?.filename +
                      "?alt=media"
                    }
                  />
                ) : (
                  <video width="100%">
                    <source src={video.video?.url} />
                  </video>
                )}

                <S.VideoCardLabel>{title}</S.VideoCardLabel>

                <S.VideoCardOverlay></S.VideoCardOverlay>
              </S.VideoCard>
            )
          })}
        </S.VideoListContainer>
      )}
    </>
  )
}
