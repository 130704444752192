import AssetActionTypes from "./asset.types"

const INITIAL_STATE = {
  asset: {},
  activeSidebarTab: "info",
  selectedVersion: null,
  assets: [],
  campaignAssets: {},
  lastVisible: null,
  isLoading: false,
  errorMessage: undefined,
  showAssetInfoModal: false,
  versionToView: null,
}

const assetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AssetActionTypes.FETCH_ASSETS_START:
    case AssetActionTypes.FETCH_CAMPAIGN_ASSETS_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: undefined,
      }
    case AssetActionTypes.FETCH_ASSETS_SUCCESS:
      return {
        ...state,
        assets: action.payload.assets,
        lastVisible: action.payload.lastVisible,
        isLoading: false,
        errorMessage: undefined,
      }
    case AssetActionTypes.FETCH_CAMPAIGN_ASSETS_SUCCESS:
      return {
        ...state,
        campaignAssets: {
          ...state.campaignAssets,
          ...action.payload,
        },
        isLoading: false,
        errorMessage: undefined,
      }
    case AssetActionTypes.FETCH_ASSETS_FAILURE:
    case AssetActionTypes.FETCH_CAMPAIGN_ASSETS_FAILURE:
      return {
        ...state,
        isLoading: false,
        lastVisible: null,
        errorMessage: action.payload,
      }
    case AssetActionTypes.SET_ACTIVE_ASSET:
      return {
        ...state,
        asset: action.payload,
      }
    case AssetActionTypes.UPDATE_ASSET:
      return {
        ...state,
        asset: action.payload,
      }
    case AssetActionTypes.SHOW_ASSET_INFO_MODAL:
      return {
        ...state,
        showAssetInfoModal: true,
      }
    case AssetActionTypes.CLOSE_ASSET_INFO_MODAL:
      return {
        ...state,
        showAssetInfoModal: false,
        asset: null,
        versionToView: null,
        activeSidebarTab: "info",
      }
    case AssetActionTypes.CLEAR_ACTIVE_ASSET:
      return {
        ...state,
        asset: null,
      }
    case AssetActionTypes.SET_ACTIVE_SIDEBAR_TAB:
      return {
        ...state,
        activeSidebarTab: action.payload,
      }
    case AssetActionTypes.SET_SELECTED_VERSION:
      return {
        ...state,
        selectedVersion: action.payload,
      }
    case AssetActionTypes.CLEAR_SELECTED_VERSION:
      return {
        ...state,
        selectedVersion: null,
      }
    case AssetActionTypes.SET_VERSION_TO_VIEW:
      return {
        ...state,
        versionToView: action.payload,
      }
    case AssetActionTypes.SHOW_ASSET_RIGTHS_REQUEST_MODAL:
      return {
        ...state,
        showAssetRightsRequestModal: true,
      }
    case AssetActionTypes.CLOSE_ASSET_RIGTHS_REQUEST_MODAL:
      return {
        ...state,
        showAssetRightsRequestModal: false,
        assetRightsRequest: null,
      }
    case AssetActionTypes.SET_ASSET_RIGTHS_REQUEST:
      return {
        ...state,
        assetRightsRequest: action.payload,
      }
    case AssetActionTypes.CLEAR_ASSET_RIGTHS_REQUEST:
      return {
        ...state,
        assetRightsRequest: null,
      }
    case AssetActionTypes.RESET_TO_INITIAL_STATE:
      return {
        ...INITIAL_STATE,
      }
    default:
      return state
  }
}

export default assetReducer
