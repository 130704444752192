import React, { useState, useEffect } from "react"

// 3rd-party libraries
import { BiCameraOff } from "react-icons/bi"
import { useSelector } from "react-redux"
import { MEDIA_TYPE, IMG_SIZES } from "../../constants"
import moment from "moment"

import {
  selectVersionToView,
  selectActiveAsset,
} from "../../redux/asset/asset.selectors"
import { capitalize } from "lodash"

import { checkImage } from "../../utils/checkImage"
import Skeleton from "../commons/skeleton/Skeleton"
import { Modal, Row, Col, ModalFooter, Button } from "react-bootstrap"

const MediaTypeRedux = ({ background = "var(--reeler-white)" }) => {
  const [loading, setLoading] = useState(true)
  const [url, setUrl] = useState()
  const [originalUrl, setOriginalUrl] = useState()
  const [type, setType] = useState(null)

  const versionToView = useSelector(selectVersionToView)
  const asset = useSelector(selectActiveAsset)
  const [showFullSizeModal, setShowFullSizeModal] = useState(false)

  const handleShowFullSizeModal = () => {
    setShowFullSizeModal(true)
  }

  const handleCloseFullSizeModal = () => {
    setShowFullSizeModal(false)
  }

  useEffect(() => {
    // if we find a main version, then set its values, otherwise use old values in asset.
    if (asset) {
      setType(asset.type)

      if (versionToView) {
        setUrl(versionToView.url)
      } else {
        if (asset?.assetVersions?.length > 0) {
          let mainVersion = asset?.assetVersions.filter(
            version => version.main === true
          )
          if (mainVersion.length > 0) {
            setType(mainVersion[0].type)
            if (
              mainVersion[0].access_token &&
              mainVersion[0].file_path &&
              mainVersion[0].file_name &&
              asset.media_type === MEDIA_TYPE.IMAGE
            ) {
              let tempUrl =
                process.env.REACT_APP_FIREBASE_STORAGE_BASE_URL +
                encodeURIComponent(
                  mainVersion[0].file_path + "image@" + IMG_SIZES.preView + "_"
                ) +
                mainVersion[0].file_name +
                "?alt=media&token=" +
                mainVersion[0].access_token

              // Check if imgage exists and is done with its transformation in the backend
              checkImage(tempUrl).then(result => {
                if (result.status === "ok") {
                  setUrl(tempUrl)
                  setLoading(false)
                } else {
                  setUrl(mainVersion[0].url)
                  setLoading(false)
                }
                setOriginalUrl(mainVersion[0].url)
              })
            } else {
              setUrl(mainVersion[0].url)
              setOriginalUrl(mainVersion[0].url)
              setLoading(false)
            }
          }
        } else {
          setUrl(asset.url)
          setOriginalUrl(asset.url)
          setLoading(false)
        }
      }
    }
  }, [asset, versionToView])

  const Unsupported = () => (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <BiCameraOff size={25} />
      <span style={{ margin: "0 0.5rem", textAlign: "center" }}>
        {asset.media_type === MEDIA_TYPE.TEXT
          ? "Text only"
          : "Unsupported Format"}
      </span>
    </div>
  )

  const renderMedia = type => {
    switch (type) {
      case "video/mp4":
        return (
          <video
            width="100%"
            height="100%"
            src={url ? url : url}
            type="video/mp4"
            controls
            style={{
              background: background,
            }}
          ></video>
        )
        break
      case "video/quicktime":
        return (
          <video
            width="100%"
            height="100%"
            src={asset.cloudinary ? asset.cloudinary.eager[0].secure_url : url}
            type="video/mp4"
            controls
            style={{
              background: background,
            }}
          ></video>
        )
        break
      case "image/jpeg":
      case "image/jpg":
      case "image/png":
        return (
          <span onClick={handleShowFullSizeModal} style={{ cursor: "zoom-in" }}>
            <img
              width="100%"
              height="100%"
              src={url}
              style={{
                objectFit: "contain",
                background: background,
                display: "block",
              }}
              alt=""
            />
          </span>
        )

        break
      case "audio/mpeg":
      case "audio/ogg":
      case "audio/wav":
      case "audio/x-m4a":
        return (
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <audio controls>
              <source src={url} type={type} />
              <p>Your browser doesn't support HTML5 audio. </p>
            </audio>
          </div>
        )

        break
      default:
        return <Unsupported />
    }
  }

  const renderAssetHeaderInfo = () => (
    <span>
      {moment(asset.createdAt.toDate()).format("YYYY-MM-DD") + " - "}
      {asset?.metadata
        ? `${asset?.metadata?.type} (${capitalize(
            asset?.metadata?.orientation
          )}, ${asset?.metadata?.dimensions?.width}x${
            asset?.metadata?.dimensions?.height
          }, ${asset?.metadata?.aspect_ratio?.aspectRatio})`
        : asset?.meta_data
        ? `${asset?.meta_data?.type} (${asset?.meta_data?.dimensions?.width}x${asset?.meta_data?.dimensions?.height}, ${asset?.meta_data?.aspect_ratio?.aspectRatio})`
        : asset.type}
    </span>
  )

  return !asset && loading ? (
    <Skeleton />
  ) : type ? (
    <>
      {renderMedia(type)}
      <Modal
        id="asset-modal-fullscreen"
        show={showFullSizeModal}
        onHide={handleCloseFullSizeModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{renderAssetHeaderInfo()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span
            onClick={handleCloseFullSizeModal}
            style={{ cursor: "zoom-out" }}
          >
            <img
              width="100%"
              height="100%"
              src={originalUrl}
              style={{
                objectFit: "contain",
                background: background,
                display: "block",
              }}
              alt=""
            />
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseFullSizeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <Unsupported />
  )
}

export default MediaTypeRedux
