import React from "react"
import SourceOwner from "../form-generator/components/campaignInfo/SourceOwner"
import CampaignTags from "../../../campaigns/edit-campaign/components/CampaignTagsRedux"

export default function FormOptions() {
  return (
    <>
      <CampaignTags />

      <SourceOwner />
    </>
  )
}
