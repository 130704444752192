import React, { useState } from "react"
import { functions, database } from "../../../../../../firebase"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectActiveAsset } from "../../../../../../redux/asset/asset.selectors"
import ReelerButton from "../../../../../commons/ReelerButton"
import { MEDIA_TYPE } from "../../../../../../constants"
import { updateAsset } from "../../../../../../redux/asset/asset.actions"
import { Form } from "react-bootstrap"

export default function AssetObjectAnnotateImage() {
  const [loading, setLoading] = useState(false)
  const asset = useSelector(selectActiveAsset)
  const dispatch = useDispatch()

  const annotateImage = async url => {
    setLoading(true)
    var aiObjectAnnotation = functions.httpsCallable("aiobjectannotation")

    try {
      let mainVersion = asset?.assetVersions.filter(
        version => version.main === true
      )
      if (mainVersion.length > 0) {
        const imageUrl = mainVersion[0].url

        const promise = await aiObjectAnnotation({
          url: imageUrl,
        })

        console.log(promise.data)

        const { labels, landmarks, faces } = promise.data

        const updateData = {}
        const objectLabels = {}

        // label detection
        labels.forEach(label => {
          if (label.score >= 0.9) {
            const words = label.description.toLowerCase().split(" ")
            words.forEach(word => {
              objectLabels[word] = true
            })
          }
        })
        // Landmark detection
        if (landmarks.length > 0) {
          const landmark = {
            description: landmarks[0].description,
            latlng: landmarks[0]?.locations[0]?.latLng,
          }
          updateData["landmark"] = landmark
          // add landmark to object labels
          const landmarkLabel = landmark.description
            .replace(/\s+/g, "")
            .toLowerCase()
          objectLabels[landmarkLabel] = true
        }

        // Face detection
        if (faces.length > 0) {
          if (faces.length > 1) {
            //  faces / people
            objectLabels["faces"] = true
            objectLabels["people"] = true
          } else {
            // face / person
            objectLabels["face"] = true
            objectLabels["person"] = true
          }
        }

        updateData["objectLabels"] = objectLabels

        // Update firestore
        var docRef = database.assets.doc(asset.id)
        await docRef.update(updateData)

        // update redux state
        dispatch(
          updateAsset({
            ...asset,
            ...updateData,
          })
        )

        setLoading(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return asset?.media_type === MEDIA_TYPE.IMAGE ? (
    <div className="mt-5">
      <h5>Annotate object and landmark labels</h5>
      <Form.Text muted>
        Use Reeler AI to label objects (e.g., food, vehicles) and landmarks
        (e.g., famous buildings) in the image.
      </Form.Text>
      <ReelerButton
        text="Annotate image"
        styleClass="btn-main"
        dispatch={() => annotateImage()}
        loading={loading}
      />
    </div>
  ) : null
}
